import React, { useEffect, useState } from "react";
import { Button, Descriptions, Image, Skeleton, Table, Tooltip } from "antd";
import { useHistory, useParams } from "react-router-dom";
// import productSvc from "../../services/productSvc";
// import SpinnerLoading from "../../components/Spinner";
// import Helper from "../../utils/helper";
import moment from "moment";
// import TitleComponent from "../../components/TitleComponent";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faPen, faPenAlt } from "@fortawesome/free-solid-svg-icons";
import { getLoginData } from "../../services/authSvc";
// import userSvc from "../../services/userSvc";
// import buyerSvc from "../../services/buyerSvc";
import reportTransactionSvc from "../../services/reportTransactionSvc";
import SpinnerLoading from "../../components/loading/SpinnerLoading";
import { Card } from "@themesberg/react-bootstrap";
import { formatToRupiah } from "../../utils/Helper";

const { user } = getLoginData();

const ViewTransaction = () => {
  const params = useParams();
  const history = useHistory();
  const id = Number(params.id);
  const [loading, setLoading] = useState(true);
  const [reportTransaction, setReportTransaction] = useState([]);

  const fetchTransaction = async () => {
    try {
      setLoading(true);
      const resp = await reportTransactionSvc.getReportTransactionById(id, {});

      if (resp.status === 200) {
        setReportTransaction(resp.data.data);
      }
      setLoading(false);
    } catch (err) {}
  };
  useEffect(() => {
    fetchTransaction();
  }, []);

  const items = [
    {
      key: "1",
      label: "Nama Laporan",
      children: reportTransaction?.name,
    },
    {
      key: "2",
      label: "Deskripsi",
      span: 2,
      children: reportTransaction.description,
    },
    {
      key: "3",
      label: "Harga",
      children: formatToRupiah(reportTransaction.price),
    },
    {
      key: "4",
      label: "Tanggal Operasi",
      children: moment(reportTransaction?.operation_date).format(
        "YYYY-MM-DD HH:mm:ss"
      ),
    },
    {
      key: "5",
      label: "Tanggal Dibuat",
      children: moment(reportTransaction?.createdAt).format(
        "YYYY-MM-DD HH:mm:ss"
      ),
    },
    {
      key: "6",
      label: "Tanggal Diperbarui",
      children: moment(reportTransaction?.updatedAt).format(
        "YYYY-MM-DD HH:mm:ss"
      ),
    },
    {
      key: "15",
      label: "Foto Sebelum",
      children:
        reportTransaction?.report_transaction_images?.filter(
          (image) => image.image_type === "before"
        ).length > 0 ? (
          reportTransaction?.report_transaction_images
            ?.filter((image) => image.image_type === "before")
            ?.map((image, index) => (
              <Image
                key={index}
                src={image.image_url}
                alt="image before"
                style={{ width: "100%", height: "auto", objectFit: "contain" }}
              />
            ))
        ) : (
          <span>-</span>
        ),
    },
    {
      key: "16",
      label: "Foto Setelah",
      children:
        reportTransaction?.report_transaction_images?.filter(
          (image) => image.image_type === "after"
        ).length > 0 ? (
          reportTransaction?.report_transaction_images
            ?.filter((image) => image.image_type === "after")
            ?.map((image, index) => (
              <Image
                key={index}
                src={image.image_url}
                alt="image after"
                width={200}
              />
            ))
        ) : (
          <span>-</span>
        ),
    },
    {
      key: "7",
      label: "Nama Cabang",
      children: reportTransaction?.pelanggan?.name,
    },
    {
      key: "8",
      label: "Alamat Cabang",
      children: (
        <span>
          {reportTransaction?.pelanggan?.alamat}
          <br />
        </span>
      ),
      span: 2,
    },
    {
      key: "9",
      label: "Nama ATM",
      children: (
        <Button
          type="link"
          onClick={() => {
            history.push(`/asset/detail/${reportTransaction?.asset?.id_asset}`);
          }}
          style={{ padding: 0, color: "#f76617" }}
        >
          {reportTransaction.asset?.name}
        </Button>
      ),
    },
    {
      key: "10",
      label: "Kode ATM",
      children: reportTransaction?.asset?.kode,
    },
    {
      key: "11",
      label: "Lokasi ATM",
      children: reportTransaction?.asset?.alamat,
    },
    // {
    //   key: "12",
    //   label: "Latitude",
    //   children: reportTransaction?.asset?.latitude,
    // },
    // {
    //   key: "13",
    //   label: "Longitude",
    //   children: reportTransaction?.asset?.longitude,
    // },
    // {
    //   key: "14",
    //   label: "Ring",
    //   children: reportTransaction?.asset?.ring,
    // },

    {
      key: "17",
      label: "Status Aktif",
      children: reportTransaction?.asset?.active ? "Aktif" : "Tidak Aktif",
    },
  ];

  return (
    <div className="align-items-center py-4 w-100">
      <Card border="light" className="shadow-sm">
        <Card.Body className="pb-0">
          <Card.Title className="">Detail Transaksi Pengerjaan</Card.Title>
          <hr />
          {loading ? (
            <SpinnerLoading height="h-40" />
          ) : (
            <>
              <Descriptions layout="vertical" items={items} />
            </>
          )}
        </Card.Body>
      </Card>
    </div>
  );
};

export default ViewTransaction;
