import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

import {
  Button,
  Card,
  Table,
  Form,
  InputGroup,
  Spinner,
  Pagination,
  Nav,
} from "@themesberg/react-bootstrap";
import { useEffect } from "react";
import Swal from "sweetalert2";

import docTypeSvc from "../services/docTypeSvc";

import { useHistory } from "react-router-dom";
import {
  showErrorAlert,
  showLoadingAlert,
  showSuccessAlert,
} from "../utils/SwalUtil";

export default () => {
  const [doctType, setDocType] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [activePage, setActivePage] = useState(1);
  const [selectedStatus, setSelectedStatus] = useState("");
  const history = useHistory();

  const fetchDocType = async () => {
    setIsLoading(true);
    const resp = await docTypeSvc.getJenisDokumentasi({
      params: {
        active:
          selectedStatus === "active"
            ? true
            : selectedStatus === "inactive"
            ? false
            : "",
      },
    });
    if (resp.status === 200) {
      setDocType(resp.data.data);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchDocType();
  }, [selectedStatus]);

  const handleActive = async (id, activeData) => {
    const willUpdate = await Swal.fire({
      title: "Apakah anda yakin?",
      text: "Anda akan mengubah status jenis dokumentasi ini!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes!",
      cancelButtonText: "Cancel",
    });

    if (willUpdate.isConfirmed) {
      try {
        showLoadingAlert("Tunggu", "Mengubah status jenis dokumentasi...");
        const data = {
          update_activate: "true",
        };
        const resp = await docTypeSvc.updateActivateJenisDokumentasi(id, data);
        if (resp.status === 200) {
          fetchDocType();
          showSuccessAlert(
            "Berhasil",
            "Status jenis dokumentasi berhasil diubah"
          );
        }
      } catch (error) {
        let message =
          error.response?.data?.message ||
          "Gagal mengubah status jenis dokumentasi";
        showErrorAlert("Gagal", message);
      }
    }
  };

  const handleDelete = async (id) => {
    const willDelete = await Swal.fire({
      title: "Apakah anda yakin?",
      text: "Anda akan menghapus data jenis dokumentasi ini!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "Cancel",
    });

    if (willDelete.isConfirmed) {
      try {
        showLoadingAlert("Tunggu", "Menghapus data jenis dokumentasi");
        const resp = await docTypeSvc.deleteJenisDokumentasi(id);
        fetchDocType();
        showSuccessAlert("Berhasil", "Data jenis dokumentasi berhasil dihapus");
      } catch (error) {
        let message =
          error.response?.data?.message ||
          "Gagal menghapus data jenis dokumentasi";
        showErrorAlert("Gagal", message);
      }
    }
  };

  const handleAddDocType = () => {
    history.push("/doctype/add");
  };

  const handleEditDocType = async (id) => {
    history.push(`/doctype/edit/${id}`);
  };

  return (
    <>
      <div className="align-items-center py-4 w-100">
        <Card border="light" className="shadow-sm">
          <Card.Body className="pb-0">
            <Card.Title className="">Jenis Dokumentasi</Card.Title>
            <hr />
            <div className="d-flex mb-3">
              <Button
                variant="success"
                size="sm"
                className=""
                style={{ width: "100px" }}
                onClick={handleAddDocType}
              >
                <FontAwesomeIcon
                  icon={faPlus}
                  className="me-2"
                  style={{ fontSize: "14px" }}
                />
                Add
              </Button>
            </div>
            <div className="d-flex justify-content-between mb-3">
              <InputGroup>
                <Form.Select
                  id="state"
                  className="me-1"
                  defaultValue=""
                  // value={role}
                  style={{ width: "30px" }}
                  onChange={(e) => {
                    setSelectedStatus(e.target.value);
                  }}
                >
                  <option value="">Semua Status</option>
                  <option value="active">Status Aktif</option>
                  <option value="inactive">Status Nonaktif</option>
                </Form.Select>
              </InputGroup>
            </div>
            <Table
              responsive
              className="table-centered table-nowrap rounded mb-0"
            >
              <thead className="thead-light">
                <tr>
                  <th className="border-0">Nama Dokumentasi</th>
                  <th className="border-0">Status</th>
                  <th className="border-0">Aksi</th>
                </tr>
              </thead>
              {isLoading ? (
                <tr>
                  <td
                    colSpan={3}
                    className="text-center"
                    style={{ height: "300px" }}
                  >
                    <Spinner animation="border" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </Spinner>
                  </td>
                </tr>
              ) : (
                <tbody>
                  {doctType.map((doc) => (
                    <tr key={doc.id_jenis_dokumentasi}>
                      <td className="border-0">{doc.nama_jenis_dokumentasi}</td>

                      <td className="fw-bold border-0">
                        {doc.active ? (
                          <div className="bg-success text-white rounded-pill p-2 text-center">
                            Aktif
                          </div>
                        ) : (
                          <div className="bg-danger text-white rounded-pill p-2 text-center">
                            Nonaktif
                          </div>
                        )}
                      </td>
                      <td className="border-0">
                        <Button
                          variant="secondary"
                          size="sm"
                          className="me-1"
                          onClick={(e) => {
                            handleEditDocType(doc.id_jenis_dokumentasi);
                          }}
                        >
                          Ubah Data
                        </Button>
                        <Button
                          variant="info"
                          size="sm"
                          className="me-1"
                          onClick={(e) => {
                            e.preventDefault();
                            handleActive(doc.id_jenis_dokumentasi, doc.active);
                          }}
                        >
                          Ubah Status
                        </Button>
                        <Button
                          variant="danger"
                          size="sm"
                          onClick={(e) => {
                            e.preventDefault();
                            handleDelete(doc.id_jenis_dokumentasi);
                          }}
                        >
                          Hapus
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              )}
            </Table>
          </Card.Body>
        </Card>
      </div>
    </>
  );
};
