import React, { useState, useEffect } from "react";
import { Route, Switch, Redirect, useHistory } from "react-router-dom";
import { Routes } from "../routes";

// pages
import DashboardOverview from "./dashboard/DashboardOverview";

import Signin from "./examples/Signin";
import Signup from "./examples/Signup";
import NotFoundPage from "./examples/NotFound";

// components
import Sidebar from "../components/Sidebar";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import Preloader from "../components/Preloader";

import Report from "./Report";
import Customer from "./Customer";
import Asset from "./Asset/Asset";
import User from "./User";
import UserHistory from "./pengguna/HistoryPengguna";
import DetailReport from "./ReportDetail";
import GenerateEMR from "./GenerateEMR";
import EmrForm from "./EmrForm";
import EmrList from "./EmrList";
import { getLoginData } from "../services/authSvc";
import DocType from "./DocType";
import FormDocType from "../components/FormDocType";
import UserAsset from "./UserAsset";
import FormUserAsset from "../components/FormUserAsset";
import FormAsset from "./Asset/FormAsset";

import ReportSummary from "./ReportSummary";
import Export from "./Export";
import ExportPDFAsset from "./ExportPDFAsset";

import FormUserAssetManageUser from "../components/FormUserAssetManageUser";
import FormUser from "../components/FormUser";
import FormCustomer from "../components/FormCustomer";
import EmrV2Form from "./EmrV2Form";
import EmrV2List from "./EmrV2List";
import Logs from "./Logs";
import DetailAsset from "./Detail/DetailAsset";
import DetailUser from "./Detail/DetailUser";
import DetailCustomer from "./Detail/DetailCustomer";
import AssetSummary from "./Infografis/AssetSummary";
import OperatorSummary from "./Infografis/OperatorSummary";
import ViewReportAsset from "./ViewReportAsset";
import ReportDailyNonAuth from "./ReportDailyNonAuth";

import OperatorLaporan from "./operator/OperatorLaporan";
import FormLaporan from "./operator/FormLaporan";
import ValidateLocation from "./operator/ValidateLocation";
import RiwayatLaporan from "./operator/RiwayatLaporan";
import OperatorToken from "./operator/OperatorToken";
import { AppContext } from "../context/appContext";
import Transactions from "./transaction/Transaction1";
import FormTransaction from "./transaction/FormTransaction";
import ViewTransaction from "./transaction/ViewTransaction";

const { user, token } = getLoginData();
const RouteWithLoader = ({ component: Component, ...rest }) => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => setLoaded(true), 1000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <Route
      {...rest}
      render={(props) => (
        <>
          <Preloader show={loaded ? false : true} /> <Component {...props} />
        </>
      )}
    />
  );
};

const RouteWithSidebar = ({
  component: Component,
  requiredRole,
  path,
  ...rest
}) => {
  const [loaded, setLoaded] = useState(false);
  const history = useHistory();

  const isAuthenticated = () => {
    const authToken = localStorage.getItem("authToken");
    if (authToken) {
      return true;
    } else {
      history.push("/login");
    }
  };
  const user_role = getLoginData().user?.role;

  const hasRequiredRole = () => {
    // Assuming you have user roles available in the user object

    const role = requiredRole.includes(user_role);
    return role;
  };

  useEffect(() => {
    const timer = setTimeout(() => setLoaded(true), 1000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated() ? (
          hasRequiredRole() ? (
            <>
              <Preloader show={loaded ? false : true} />
              <Sidebar />
              <main className="content" id="content">
                <Navbar />
                <Component {...props} />
                <Footer />
              </main>
            </>
          ) : user_role === "operator" ? (
            <Redirect to="/operator/laporan" />
          ) : (
            <Redirect to="/404" />
          )
        ) : (
          <Redirect to="/login" />
        )
      }
    />
  );
};

export default () => {
  console.log("homepage how many render");
  return (
    <Switch>
      <RouteWithLoader exact path={"/emr/:token"} component={EmrForm} />
      <RouteWithLoader path="/report-asset" component={ViewReportAsset} />
      <RouteWithLoader path="/report-cabang" component={ViewReportAsset} />
      <RouteWithLoader path="/report/daily" component={ReportDailyNonAuth} />
      <RouteWithLoader exact path={"/emrv2/:token"} component={EmrV2Form} />
      {user && token ? (
        <Redirect exact from={Routes.Signin.path} to="/" />
      ) : (
        <RouteWithLoader exact path={Routes.Signin.path} component={Signin} />
      )}
      <RouteWithLoader exact path={Routes.Signup.path} component={Signup} />

      <RouteWithSidebar
        exact
        path={Routes.Logs.path}
        component={Logs}
        requiredRole={["admin", "admin cabang"]}
      />

      <RouteWithSidebar
        exact
        path={Routes.DashboardOverview.path}
        component={DashboardOverview}
        requiredRole={["admin", "admin cabang"]}
      />
      <RouteWithSidebar
        exact
        path={Routes.OperatorLaporan.path}
        component={OperatorLaporan}
        requiredRole={["operator"]}
      />
      <RouteWithSidebar
        exact
        path={Routes.OperatorEmr.path}
        component={OperatorToken}
        requiredRole={["operator"]}
      />
      <RouteWithSidebar
        exact
        path={`${Routes.OperatorLaporan.path}/validate`}
        component={ValidateLocation}
        requiredRole={["operator"]}
      />
      <RouteWithSidebar
        exact
        path={`${Routes.OperatorLaporan.path}/add`}
        component={FormLaporan}
        requiredRole={["operator"]}
      />
      <RouteWithSidebar
        exact
        path={`${Routes.OperatorLaporan.path}/view`}
        component={RiwayatLaporan}
        requiredRole={["operator"]}
      />
      <RouteWithSidebar
        exact
        path={Routes.Report.path}
        component={Report}
        requiredRole={["admin", "admin cabang"]}
      />
      <RouteWithSidebar
        exact
        path={Routes.ReportSummary.path}
        component={ReportSummary}
        requiredRole={["admin", "admin cabang"]}
      />
      <RouteWithSidebar
        exact
        path={Routes.AssetSummary.path}
        component={AssetSummary}
        requiredRole={["admin", "admin cabang"]}
      />
      <RouteWithSidebar
        exact
        path={Routes.OperatorSummary.path}
        component={OperatorSummary}
        requiredRole={["admin", "admin cabang"]}
      />
      <RouteWithSidebar
        exact
        path={Routes.Export.path}
        component={Export}
        requiredRole={["admin", "admin cabang"]}
      />
      <RouteWithSidebar
        exact
        path={Routes.ExportPDFAsset.path}
        component={ExportPDFAsset}
        requiredRole={["admin", "admin cabang"]}
      />
      <RouteWithSidebar
        exact
        path={`/report-detail/:id`}
        component={DetailReport}
        requiredRole={["admin", "admin cabang", "operator"]}
      />
      <RouteWithSidebar
        exact
        path={Routes.Customer.path}
        component={Customer}
        requiredRole={["admin", "admin cabang"]}
      />

      <RouteWithSidebar
        exact
        path={`${Routes.Customer.path}/detail/:id`}
        component={DetailCustomer}
        requiredRole={["admin", "admin cabang"]}
      />

      <RouteWithSidebar
        exact
        path={`${Routes.Customer.path}/add`}
        component={FormCustomer}
        requiredRole={["admin", "admin cabang"]}
      />

      <RouteWithSidebar
        exact
        path={`${Routes.User.path}/history`}
        component={UserHistory}
        requiredRole={["admin", "admin cabang"]}
      />
      <RouteWithSidebar
        exact
        path={`${Routes.Customer.path}/update/:id`}
        component={FormCustomer}
        requiredRole={["admin", "admin cabang"]}
      />
      <RouteWithSidebar
        exact
        path={Routes.Asset.path}
        component={Asset}
        requiredRole={["admin", "admin cabang"]}
      />
      <RouteWithSidebar
        exact
        path={`${Routes.Asset.path}/detail/:id`}
        component={DetailAsset}
        requiredRole={["admin", "admin cabang"]}
      />
      <RouteWithSidebar
        exact
        path={`${Routes.Asset.path}/add`}
        component={FormAsset}
        requiredRole={["admin", "admin cabang"]}
      />
      <RouteWithSidebar
        exact
        path={`${Routes.Asset.path}/update/:id`}
        component={FormAsset}
        requiredRole={["admin", "admin cabang"]}
      />
      <RouteWithSidebar
        exact
        path={Routes.User.path}
        component={User}
        requiredRole={["admin", "admin cabang"]}
      />
      <RouteWithSidebar
        exact
        path={`${Routes.User.path}/detail/:id`}
        component={DetailUser}
        requiredRole={["admin", "admin cabang"]}
      />
      <RouteWithSidebar
        exact
        path={Routes.UserAsset.path}
        component={UserAsset}
        requiredRole={["admin", "admin cabang"]}
      />
      <RouteWithSidebar
        exact
        path={`${Routes.UserAsset.path}/add`}
        component={FormUserAsset}
        requiredRole={["admin", "admin cabang"]}
      />
      <RouteWithSidebar
        exact
        path={`${Routes.UserAsset.path}/:id`}
        component={FormUserAsset}
        requiredRole={["admin", "admin cabang"]}
      />
      <RouteWithSidebar
        exact
        path={`${Routes.UserAsset.path}/users/:id`}
        component={FormUserAssetManageUser}
        requiredRole={["admin", "admin cabang"]}
      />
      <RouteWithSidebar
        exact
        path={Routes.EMR.path}
        component={GenerateEMR}
        requiredRole={["admin", "admin cabang"]}
      />
      <RouteWithSidebar
        exact
        path={Routes.User.path}
        component={User}
        requiredRole={["admin", "admin cabang"]}
      />
      <RouteWithSidebar
        exact
        path={`${Routes.User.path}/add`}
        component={FormUser}
        requiredRole={["admin", "admin cabang"]}
      />
      <RouteWithSidebar
        exact
        path={`${Routes.User.path}/update/:id`}
        component={FormUser}
        requiredRole={["admin", "admin cabang"]}
      />

      <RouteWithSidebar
        exact
        path={Routes.EMRList.path}
        component={EmrList}
        requiredRole={["admin", "admin cabang"]}
      />
      <RouteWithSidebar
        exact
        path={Routes.EMRV2List.path}
        component={EmrV2List}
        requiredRole={["admin", "admin cabang"]}
      />

      <RouteWithSidebar
        exact
        path={Routes.DocType.path}
        component={DocType}
        requiredRole={["admin", "admin cabang"]}
      />
      <RouteWithSidebar
        exact
        path={Routes.DocType.path + "/add"}
        component={FormDocType}
        requiredRole={["admin", "admin cabang"]}
      />
      <RouteWithSidebar
        exact
        path={`${Routes.DocType.path}/edit/:id`}
        component={FormDocType}
        requiredRole={["admin", "admin cabang"]}
      />

      <RouteWithSidebar
        exact
        path={Routes.report.transaction.path}
        component={Transactions}
        requiredRole={["admin", "admin cabang"]}
      />
      <RouteWithSidebar
        exact
        path={Routes.report.transaction.path + "/add"}
        component={FormTransaction}
        requiredRole={["admin"]}
      />
      <RouteWithSidebar
        exact
        path={`${Routes.report.transaction.path}/edit/:id`}
        component={FormTransaction}
        requiredRole={["admin"]}
      />
      <RouteWithSidebar
        exact
        path={`${Routes.report.transaction.path}/view/:id`}
        component={ViewTransaction}
        requiredRole={["admin", "admin cabang"]}
      />

      <RouteWithLoader
        exact
        path={Routes.NotFound.path}
        component={NotFoundPage}
      />

      <Redirect to={Routes.NotFound.path} />
    </Switch>
  );
};
