import React, { useEffect, useRef, useState } from "react";
import moment from "moment-timezone";

import reportSvc from "../services/reportSvc";
import userSvc from "../services/userSvc";
import customerSvc from "../services/customerSvc";
import assetSvc from "../services/assetSvc";
import { showErrorAlert } from "../utils/SwalUtil";
import { getLoginData } from "../services/authSvc";
import {
  Card as CardAntd,
  Input,
  Table,
  Select,
  Button,
  DatePicker,
  Skeleton,
  Image,
} from "antd";
import dayjs from "dayjs";
import { useHistory } from "react-router-dom";
import { SearchOutlined } from "@ant-design/icons";
import { ColumnLaporan } from "../template/TemplateColumn";
const { Option } = Select;
const { RangePicker } = DatePicker;
moment.tz.setDefault("Asia/Bangkok");

export default () => {
  const searchInputRef = useRef(null);
  const [reports, setReports] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingFilter, setIsLoadingFilter] = useState(true);
  const [searchTerm, setSearchTerm] = useState(null);
  const [startDate, setStartDate] = useState(moment().format("YYYY-MM-DD"));
  const [endDate, setEndDate] = useState(moment().format("YYYY-MM-DD"));
  const [customers, setCustomers] = useState([]);
  const [users, setUsers] = useState([]);
  const [assets, setAssets] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedAsset, setSelectedAsset] = useState(null);
  const [selectedTemuan, setSelectedTemuan] = useState(null);
  const [totalRows, setTotalRows] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(0);
  const history = useHistory();
  const { user } = getLoginData();

  const handleChangeDate = (dates) => {
    if (dates && dates.length === 2) {
      setStartDate(dates[0].format("YYYY-MM-DD"));
      setEndDate(dates[1].format("YYYY-MM-DD"));
    }
  };

  const fetchReports = async () => {
    try {
      setIsLoading(true);
      const params = {
        page: page,
        limit: pageSize,
        query: searchTerm,
        start_date: startDate,
        end_date: endDate,
        id_pelanggan: selectedCustomer,
        id_user: selectedUser,
        id_asset: selectedAsset,
        is_temuan: selectedTemuan,
      };

      const resp = await reportSvc.getReports({
        params: { ...params },
      });
      if (resp.status === 200) {
        setReports(resp.data.data);
        setTotalRows(resp.data.totalRows);
      }
    } catch (error) {
      let message =
        error.response?.data?.message || "Gagal mengambil data report";
      showErrorAlert("Gagal", message);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchAssets = async () => {
    try {
      setIsLoadingFilter(true);
      const resp = await assetSvc.getAsset({
        params: {
          page: 0,
          limit: 1000,
          fields: `["name", "id_asset", "id_pelanggan"]`,
          includes: JSON.stringify([
            {
              model: "pelanggan",
              attributes: ["name"],
            },
          ]),
        },
      });
      if (resp.status === 200) {
        setAssets(resp.data.data);
      }
    } catch (error) {
    } finally {
      setIsLoadingFilter(false);
    }
  };

  const fetchUser = async () => {
    try {
      setIsLoadingFilter(true);
      const resp = await userSvc.getUser({
        params: {
          page: 0,
          limit: 1000,
          role: "operator",
        },
      });
      if (resp.status === 200) {
        setUsers(resp.data.data);
      }
    } catch (error) {
    } finally {
      setIsLoadingFilter(false);
    }
  };

  const fetchCustomers = async () => {
    try {
      setIsLoadingFilter(true);
      const resp = await customerSvc.getCustomer({
        params: {
          page: 0,
          limit: 10000,
        },
      });
      if (resp.status === 200) {
        setCustomers(resp.data.data);
      }
    } catch (error) {
    } finally {
      setIsLoadingFilter(false);
    }
  };

  useEffect(() => {
    if (getLoginData()?.user?.role === "admin") {
      fetchCustomers();
    }
    fetchUser();
    fetchAssets();
  }, []);

  useEffect(() => {
    fetchReports();
  }, [
    startDate,
    endDate,
    selectedAsset,
    selectedCustomer,
    selectedTemuan,
    selectedUser,
    page,
    pageSize,
  ]);

  const isFirstRender = useRef(true);
  useEffect(() => {
    if (!isFirstRender.current) {
      let timerId;

      const delayedFetch = () => {
        clearTimeout(timerId);
        timerId = setTimeout(() => {
          fetchReports();
        }, 500);
      };

      delayedFetch();

      return () => {
        clearTimeout(timerId);
      };
    } else {
      isFirstRender.current = false;
    }
  }, [searchTerm]);

  const handlePageChange = (page) => {
    setPage(page - 1);
  };

  const handleClearFilters = () => {
    setSearchTerm(null);
    setSelectedUser(null);
    setSelectedAsset(null);
    setSelectedCustomer(null);
    setPage(0);
    setEndDate(moment().format("YYYY-MM-DD"));
    setSelectedTemuan(null);
    setStartDate(moment().format("YYYY-MM-DD"));

    if (searchInputRef.current) {
      searchInputRef.current.value = "";
    }
  };

  const goToDetailLaporan = (id) => {
    history.push(`/report-detail/${id}`);
  };

  const handlePageSizeChange = (current, pageSize) => {
    setPage(0);
    setPageSize(pageSize);
  };

  const columns = ColumnLaporan(goToDetailLaporan);

  const pagination = {
    showTotal: (total, range) =>
      `Showing ${range[0]}-${range[1]} of ${total} items`,
    pageSize: pageSize,
    total: totalRows,
    onChange: handlePageChange,
    onShowSizeChange: handlePageSizeChange,
    pageSizeOptions: [5, 10, 20],
    showSizeChanger: true,
    position: ["bottomRight"],
    className: "custom-pagination",
  };

  return (
    <div className="align-items-center py-4 w-100">
      <CardAntd
        title={<span style={{ fontSize: "20px" }}>Riwayat Laporan</span>}
        extra={<div className="d-flex"></div>}
        style={{ width: "100%" }}
      >
        {isLoadingFilter ? (
          <Skeleton.Input
            active
            block
            style={{ width: "100%", height: "5rem", marginBottom: "1rem" }}
          />
        ) : (
          <div className="d-flex flex-wrap justify-content-between mb-3 gap-2">
            <Input.Group style={{ flexGrow: 1, flexBasis: "300px" }}>
              <Input
                size="middle"
                placeholder="Cari operator, ATM / kode ATM.."
                prefix={<SearchOutlined />}
                value={searchTerm}
                onChange={(e) => {
                  setSearchTerm(e.target.value);
                }}
                className="me-1"
                ref={searchInputRef}
              />
            </Input.Group>

            <Input.Group style={{ flexGrow: 1, flexBasis: "300px" }}>
              <RangePicker
                style={{ width: "100%" }}
                size="middle"
                onChange={handleChangeDate}
                allowClear={false}
                format="YYYY-MM-DD"
                value={[
                  dayjs(startDate, "YYYY-MM-DD"),
                  dayjs(endDate, "YYYY-MM-DD"),
                ]}
              />
            </Input.Group>

            <Input.Group style={{ flexGrow: 1, flexBasis: "300px" }}>
              <Select
                id="state"
                value={selectedTemuan}
                onChange={(value) => {
                  setSelectedTemuan(value);
                }}
                placeholder="Semua Temuan"
                style={{ width: "100%", height: "100%" }}
                size="middle"
              >
                <Option value="true">Ada Temuan</Option>
                <Option value="false">Tidak ada temuan</Option>
              </Select>
            </Input.Group>

            {user?.role === "admin" && (
              <Input.Group style={{ flexGrow: 1, flexBasis: "300px" }}>
                <Select
                  placeholder="Semua Kantor Cabang"
                  allowClear
                  showSearch
                  onChange={(value) => {
                    setSelectedCustomer(value);
                  }}
                  value={selectedCustomer}
                  filterOption={(input, option) =>
                    option.children.toLowerCase().includes(input.toLowerCase())
                  }
                  style={{ width: "100%", height: "100%" }}
                  size="middle"
                >
                  {customers.map((data) => (
                    <Select.Option
                      key={data.id_pelanggan}
                      value={data.id_pelanggan}
                    >
                      {data.name}
                    </Select.Option>
                  ))}
                </Select>
              </Input.Group>
            )}

            <Input.Group style={{ flexGrow: 1, flexBasis: "300px" }}>
              <Select
                placeholder="Semua Operator"
                allowClear
                showSearch
                onChange={(value) => {
                  setSelectedUser(value);
                }}
                value={selectedUser}
                style={{ width: "100%", height: "100%" }}
                size="middle"
              >
                {users.map((data) => (
                  <Select.Option key={data.id_user} value={data.id_user}>
                    {data.name}
                  </Select.Option>
                ))}
              </Select>
            </Input.Group>

            <Input.Group style={{ flexGrow: 1, flexBasis: "300px" }}>
              <Select
                placeholder="Semua ATM"
                allowClear
                showSearch
                onChange={(value) => {
                  setSelectedAsset(value);
                }}
                value={selectedAsset}
                style={{ width: "100%", height: "100%" }}
                size="middle"
              >
                {assets.map((data) => (
                  <Select.Option key={data.id_asset} value={data.id_asset}>
                    {data.name}
                  </Select.Option>
                ))}
              </Select>
            </Input.Group>

            <Button
              variant="secondary"
              size="sm"
              className="ml-2"
              style={{ height: "auto" }}
              onClick={(e) => {
                handleClearFilters();
              }}
            >
              Reset Filter
            </Button>
          </div>
        )}

        <Table
          columns={columns}
          dataSource={reports}
          pagination={pagination}
          loading={isLoading}
          size="middle"
          style={{ overflowX: "auto" }}
        ></Table>
      </CardAntd>
    </div>
  );
};
