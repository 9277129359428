import React, { useState, useEffect } from "react";
import { Card, Button, Form, Spinner } from "@themesberg/react-bootstrap";
import customerSvc from "../services/customerSvc";
import Swal from "sweetalert2";
import userSvc from "../services/userSvc";
import { getLoginData } from "../services/authSvc";
import { useHistory, useParams } from "react-router";
import { showErrorAlert, showSuccessAlert } from "../utils/SwalUtil";

export default () => {
  const [name, setName] = useState(undefined);
  const [email, setEmail] = useState(undefined);
  const [phone, setPhone] = useState(undefined);
  const [role, setRole] = useState("operator");
  const [token, setToken] = useState("");
  const [photoUrl, setPhotoUrl] = useState("");
  const [id_pelanggan, setIdPelanggan] = useState(null);
  const [id_userAsset, setIdUserAsset] = useState(null);
  const { user } = getLoginData();
  const [isLoading, setIsLoading] = useState(true);
  const [customers, setCustomers] = useState([]);
  const history = useHistory();
  const [photoFile, setPhotoFile] = useState();

  const { id } = useParams();

  useEffect(() => {
    fetchCustomers();
    if (id) {
      fetchUserById();
    }
  }, []);

  const fetchCustomers = async () => {
    try {
      setIsLoading(true);
      const respCust = await customerSvc.getCustomer({
        params: {
          page: 0,
          limit: 1000,
          active: true,
        },
      });

      if (respCust.status === 200) {
        setCustomers(respCust.data.data);
      }
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  const fetchUserById = async () => {
    try {
      setIsLoading(true);
      const resp = await userSvc.getUserById(id);

      if (resp.status === 200) {
        setName(resp.data.data.name);
        setEmail(resp.data.data.email);
        setPhone(resp.data.data.phone);
        setRole(resp.data.data.role);
        setIdPelanggan(resp.data.data.id_pelanggan);
        setIdUserAsset(resp.data.data.id_user_asset);
        setPhotoUrl(resp.data.data.photo_url);
      }
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  const handleSave = async () => {
    let data = null;
    if (!name || !email || !phone) {
      showErrorAlert("Gagal", "Kolom harus diisi lengkap");
      return;
    }
    if (role !== "operator") {
      data = {
        name: name,
        email: email,
        phone: phone,
        role: role,
        id_pelanggan: id_pelanggan,
        token_admin: token,
        id_user_asset: id_userAsset,
        file: photoFile,
      };
    } else {
      data = {
        name: name,
        email: email,
        phone: phone,
        role: role,
        id_pelanggan: id_pelanggan,
        file: photoFile,
      };
    }
    try {
      let resp;
      if (id) {
        resp = await userSvc.updateUserAll(id, data);
      } else {
        resp = await userSvc.registUser(data);
      }

      if (resp.status === 200) {
        if (id) {
          showSuccessAlert("Berhasil", "Data user berhasil diubah");
          history.goBack();
        } else {
          showSuccessAlert("Berhasil", "Data user berhasil ditambahkan");
          history.goBack();
        }
      } else {
        let message =
          resp?.response?.data?.message ||
          "Gagal memproses permintaan menambah/mengubah data user";
        showErrorAlert("Gagal", message);
      }
    } catch (error) {
      let message =
        error?.response?.data?.message ||
        "Gagal memproses permintaan menambah/mengubah data user";
      showErrorAlert("Gagal", message);
    }
  };

  return (
    <Card>
      <Card.Header>
        <Card.Title className="h3">
          {id ? "Ubah Data Pengguna" : "Tambah Pengguna"}
        </Card.Title>
      </Card.Header>
      {isLoading ? (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: "300px" }}
        >
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      ) : (
        <Card.Body>
          <Form>
            <Form.Group className="mb-3" controlId="name">
              <Form.Label>Nama Pengguna</Form.Label>
              <Form.Control
                required
                type="text"
                placeholder="Masukkan Nama Pengguna"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="email">
              <Form.Label>Email</Form.Label>
              <Form.Control
                required
                type="email"
                placeholder="Masukkan Email"
                value={email}
                onChange={(e) => setEmail(e.target.value.toLowerCase())} // Convert to lowercase
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="phone">
              <Form.Label>Nomor Handphone</Form.Label>
              <Form.Control
                required
                // type="number"
                placeholder="Masukkan Nomor Handphone"
                value={phone}
                onChange={(e) => setPhone(e.target.value.toString())}
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="role">
              <Form.Label>Pilih Role</Form.Label>
              <Form.Select
                value={role}
                onChange={(e) => setRole(e.target.value)}
              >
                <option value="operator">Operator</option>
                <option value="admin cabang">Admin Cabang</option>
                <option value="admin">Admin</option>
              </Form.Select>
            </Form.Group>

            {role !== "" && role !== "operator" && (
              <Form.Group className="mb-3" controlId="token">
                <Form.Label>Token (REQUIRED)</Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder="Enter token"
                  value={token}
                  onChange={(e) => setToken(e.target.value)}
                />
              </Form.Group>
            )}

            {user && user.role === "admin" && (
              <Form.Group className="mb-3" controlId="customer">
                <Form.Label>Cabang</Form.Label>
                <Form.Select
                  value={id_pelanggan}
                  placeholder="Pilih Cabang"
                  onChange={(e) =>
                    setIdPelanggan(
                      e.target.value === "" ? null : e.target.value
                    )
                  }
                >
                  <option key={null} value="">
                    Tidak Mempunyai Pelanggan
                  </option>
                  {customers &&
                    customers.map((customer) => (
                      <option
                        key={customer.id_pelanggan}
                        value={customer.id_pelanggan}
                      >
                        {customer.name}
                      </option>
                    ))}
                </Form.Select>
              </Form.Group>
            )}

            {/* adjustment foto profil */}
            {/* {id ? (
              <>
                <Form.Group className="mb-3" controlId="customer">
                  <Form.Label>Foto profil saat ini.</Form.Label>
                  <div>
                    <Image
                      src={photoUrl}
                      className="mr-2 mb-2 rounded"
                      width="200px"
                      height="200px"
                    />
                  </div>
                  <Form.Label>Ubah foto profil</Form.Label>
                  <Form.Control
                    type="file"
                    accept="image/*"
                    onChange={(e) => setPhotoFile(e.target.files[0])}
                  />
                </Form.Group>
              </>
            ) : (
              <Form.Group className="mb-3" controlId="phone">
                <Form.Label>Foto Profil</Form.Label>
                <Form.Control
                  type="file"
                  accept="image/*"
                  onChange={(e) => setPhotoFile(e.target.files)}
                  required
                />
              </Form.Group>
            )} */}

            {/* {user && user.role === 'admin' && (
             <Form.Group className="mb-3" controlId="userAsset">
               <Form.Label>Pilih Role User Asset</Form.Label>
               <Form.Select
                 defaultValue="admin"
                 value={id_userAsset}
                 onChange={(e) => setIdUserAsset(e.target.value)}
               >
                 <option key={"no cust"} value={null}>
                   Tidak Mempunyai Role User Asset
                 </option>
                 {setUserAsset &&
                   setUserAsset.map((uAsset) => (
                     <option
                       key={uAsset.id_user_asset}
                       value={uAsset.id_user_asset}
                     >
                       {uAsset.keterangan}
                     </option>
                   ))}
               </Form.Select>
             </Form.Group>
           )} */}
          </Form>
        </Card.Body>
      )}

      <Card.Footer>
        <Button
          className="mx-2"
          variant="secondary"
          onClick={(e) => {
            e.preventDefault();
            history.goBack();
          }}
        >
          Kembali
        </Button>
        <Button
          variant="primary"
          onClick={(e) => {
            e.preventDefault();
            handleSave();
          }}
        >
          Simpan
        </Button>
      </Card.Footer>
    </Card>
  );
};
