import React from "react";
import moment from "moment-timezone";
import { Row, Col, Card } from "@themesberg/react-bootstrap";

export default (props) => {
  const currentYear = moment().get("year");

  return (
    <div>
      <footer className="footer section py-5">
        <Row className="justify-content-center">
          <Col
            xs={12}
            lg={6}
            className="mb-4 mb-lg-0 d-flex justify-content-center"
          >
            <Card.Link
              href="https://grahamitraempatenam.com"
              target="_blank"
              className="text-black text-decoration-none fw-normal text-xl-left mb-0 text-center"
            >
              JWA Software © {`${currentYear} `}
            </Card.Link>
          </Col>
        </Row>
      </footer>
    </div>
  );
};
