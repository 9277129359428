import { faArrowUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "@themesberg/react-bootstrap";
import { useEffect, useState } from "react";

export default function ScrollToTop() {
  const [isVisible, setIsVisible] = useState(false);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    const toggleVisibility = () => {
      setIsVisible(window.pageYOffset > 500);
    };

    const handleScroll = () => {
      toggleVisibility();
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className="position-fixed bottom-3 right-3" style={{ zIndex: 200 }}>
      {isVisible && (
        <div onClick={scrollToTop} className="">
          <Button
            variant="primary"
            className="d-flex h-10 w-10 cursor-pointer align-items-center justify-content-center rounded-md shadow-md transition duration-300 ease-in-out hover-opacity-80 hover-shadow-signUp"
            onClick={scrollToTop}
            aria-label="scroll to top"
          >
            <FontAwesomeIcon icon={faArrowUp} />
          </Button>
        </div>
      )}
    </div>
  );
}
