import React, { useEffect, useState, useCallback, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBoxOpen,
  faCalendarAlt,
  faCheckSquare,
  faDatabase,
  faFlag,
  faMapLocationDot,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import Datetime from "react-datetime";
import {
  Col,
  Row,
  Spinner,
  Card,
  Table,
  // Image,
  Nav,
  Pagination,
  Form,
  InputGroup,
} from "@themesberg/react-bootstrap";
// import Select from "react-select";
import { CounterWidget } from "../../components/Widgets";
import assetSvc from "../../services/assetSvc";
import moment from "moment";
import reportSvc from "../../services/reportSvc";
import userSvc from "../../services/userSvc";

import useUserStore from "../../zustand/useUserStore";

import "../../assets/styles/leaflet.css";
import L from "leaflet";
import marker from "../../assets/img/marker-removebg-preview.png";
import { customStyles } from "../../utils/SelectUtils";
import customerSvc from "../../services/customerSvc";
import { getLoginData } from "../../services/authSvc";
import dataHoliday from "../../data/holiday_2024.json";
import { useContext } from "react";
import { AppContext } from "../../context/appContext";
import useAuthStore from "../../zustand/useUserStore";
import {
  Card as CardAntd,
  Input,
  Select,
  Spin,
  Table as TableAntd,
  Image,
  Skeleton,
} from "antd";
import MapComponent from "../../components/MapContainer";
import { useHistory } from "react-router-dom";
import { ColumnLaporanTemuan } from "../../template/TemplateColumn";

const customMarkerIcon = new L.Icon({
  iconUrl: marker,
  iconRetinaUrl: marker,
  iconSize: [32, 45],
  iconAnchor: [16, 45],
});

export default () => {
  const [assets, setAssets] = useState([]);
  const [reports, setReports] = useState([]);
  const [isLoadingSummary, setIsLoadingSummary] = useState(true);
  const [isLoadingTable, setIsLoadingTable] = useState(true);
  const [startDate, setStartDate] = useState(moment().format("YYYY-MM-DD"));
  const [endDate, setEndDate] = useState(moment().format("YYYY-MM-DD"));
  const [pageSize, setPageSize] = useState(5);
  const [page, setPage] = useState(0);
  const [totalRows, setTotalRows] = useState(0);
  const [customers, setCustomers] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [dashboardData, setDashboardData] = useState([]);
  moment.tz.setDefault("Asia/Bangkok");
  const { user } = getLoginData();
  const history = useHistory();

  const fetchAssets = async () => {
    const resp = await assetSvc.getAsset({
      params: {
        page: 0,
        limit: 1000,
        id_pelanggan: selectedCustomer,
      },
    });
    if (resp.status === 200) {
      setAssets(resp.data);
    }
  };

  const fetchCustomers = async () => {
    const resp = await customerSvc.getCustomer({
      params: {
        page: 0,
        limit: 10000,
      },
    });
    if (resp.status === 200) {
      setCustomers(resp.data.data);
    }
    return resp;
  };

  const fetchDataMemoized = useCallback(() => {
    fetchCustomers().then((result) => {
      setCustomers(result.data.data);
    });
  }, []);

  useEffect(() => {
    fetchDataMemoized();
  }, [fetchDataMemoized]);

  const fetchDashboardData = async () => {
    try {
      setIsLoadingSummary(true);
      const resp = await reportSvc.getSummaryDashboard({});
      if (resp.status === 200) {
        setDashboardData(resp.data.data);
      }
      setIsLoadingSummary(false);
    } catch (error) {}
  };

  const fetchReports = async () => {
    setIsLoadingTable(true);
    let totalTemuan = null;

    if (endDate) {
      totalTemuan = await reportSvc.getReports({
        params: {
          page: page,
          limit: pageSize,
          start_date: startDate,
          end_date: endDate,
          is_temuan: true,
        },
      });
    } else {
      totalTemuan = await reportSvc.getReports({
        params: {
          page: page,
          limit: pageSize,
          start_date: startDate,
          is_temuan: true,
        },
      });
    }

    if (totalTemuan.status === 200) {
      setReports(totalTemuan.data.data);
      setTotalRows(totalTemuan.data.totalRows);
    }
    setIsLoadingTable(false);
  };

  const handleSelectCustomer = (selectedOption) => {
    setSelectedCustomer(selectedOption);
  };

  useEffect(() => {
    fetchReports();
  }, [startDate, endDate, page, pageSize]);

  useEffect(() => {
    fetchAssets();
  }, [selectedCustomer]);

  useEffect(() => {
    fetchDashboardData();
  }, []);

  const context = useContext(AppContext);
  const userStore = useAuthStore();

  const handleDetaiLaporan = (id) => {
    history.push(`/report-detail/${id}`);
  };

  const handlePageSizeChange = (current, pageSize) => {
    setPage(0);
    setPageSize(pageSize);
  };

  const handlePageChange = (page) => {
    setPage(page - 1);
  };

  const goToAssetSummaryPage = (id) => {
    history.push(`/asset-summary`);
  };

  const goToUserPage = (id) => {
    history.push(`/user`);
  };

  const goToAssetPage = (id) => {
    history.push(`/asset`);
  };

  const goToLaporanPage = (id) => {
    history.push(`/report`);
  };

  const columns = ColumnLaporanTemuan(handleDetaiLaporan);

  const pagination = {
    showTotal: (total, range) =>
      `Showing ${range[0]}-${range[1]} of ${total} items`,
    pageSize: pageSize,
    total: totalRows,
    onChange: handlePageChange,
    onShowSizeChange: handlePageSizeChange,
    pageSizeOptions: [5, 10, 20],
    showSizeChanger: true,
    position: ["bottomRight"],
    className: "custom-pagination",
  };

  return (
    <>
      <Row className="justify-content-md-center mt-2">
        {!isLoadingSummary ? (
          <>
            <Col xs={6} sm={6} xl={3} className="mb-2  ">
              <a href="#" onClick={goToAssetPage}>
                <CounterWidget
                  category="Total ATM"
                  title={dashboardData.total_asset}
                  period={moment().format("YYYY-MM-DD HH:mm:ss")}
                  bgColor={"#c2ff85"}
                  bgIcon={"#228B22"}
                  icon={faDatabase}
                />
              </a>
            </Col>

            <Col xs={6} sm={6} xl={3} className="mb-2">
              <a href="#" onClick={goToUserPage}>
                <CounterWidget
                  category="Total Operator"
                  title={dashboardData.total_operator}
                  icon={faUser}
                  bgColor={"#e0e0ff"}
                  bgIcon={"#2020fa"}
                />
              </a>
            </Col>
            <Col xs={6} sm={6} xl={3} className="mb-2">
              <a onClick={goToLaporanPage}>
                <CounterWidget
                  category="Total Temuan"
                  title={dashboardData.total_temuan_today}
                  icon={faFlag}
                  bgColor={"#ffdbdb"}
                  bgIcon={"#fc2121"}
                />
              </a>
            </Col>
            <Col xs={6} sm={6} xl={3} className="mb-2">
              <a onClick={goToAssetSummaryPage}>
                <CounterWidget
                  category="Belum Dikerjakan"
                  title={dashboardData.total_belum_dikerjakan}
                  icon={faCheckSquare}
                  bgColor={"#ffeed4"}
                  bgIcon={"orange"}
                />
              </a>
            </Col>
          </>
        ) : (
          <div
            className="d-flex justify-content-center align-items-center"
            colSpan="5"
            style={{ height: "137px" }}
          >
            <Skeleton.Input
              active
              block
              style={{ width: "100%", height: "137px" }}
            />
          </div>
        )}
      </Row>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
          paddingTop: "1rem",
        }}
      >
        <CardAntd
          title={
            <span
              style={{
                fontSize: "20px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <div className={`icon icon-shape icon-sm`}>
                <FontAwesomeIcon icon={faFlag} style={{ color: "#f76617" }} />
              </div>

              <span>Temuan Operator</span>
            </span>
          }
          extra={<div className="d-flex"></div>}
          style={{ width: "100%" }}
        >
          <div style={{ overflowX: "auto" }}>
            <TableAntd
              columns={columns}
              dataSource={reports}
              pagination={pagination}
              loading={isLoadingTable}
              size="small"
              // scroll={{ x: 1200, y: 600 }}
            ></TableAntd>
          </div>
        </CardAntd>

        <CardAntd
          title={
            <span
              style={{
                fontSize: "20px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <div className={`icon icon-shape icon-sm`}>
                <FontAwesomeIcon
                  icon={faMapLocationDot}
                  style={{ color: "#f76617" }}
                />
              </div>

              <span>Persebaran ATM</span>
            </span>
          }
          extra={<div className="d-flex"></div>}
          style={{ width: "100%" }}
        >
          <div
            className="d-flex flex-wrap justify-content-between mb-3 gap-2 "
            style={{ position: "relative", zIndex: 10 }}
          >
            <Input.Group style={{ flexGrow: 1, flexBasis: "300px" }}>
              <Select
                placeholder="Semua Kantor Cabang"
                allowClear
                showSearch
                onChange={handleSelectCustomer}
                value={selectedCustomer}
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
                style={{ width: "100%", height: "100%" }}
                size="large"
              >
                {customers.map((data) => (
                  <Select.Option
                    key={data.id_pelanggan}
                    value={data.id_pelanggan}
                  >
                    {data.name}
                  </Select.Option>
                ))}
              </Select>
            </Input.Group>
          </div>
          <div
            style={{
              height: "30rem",
            }}
          >
            <MapComponent assets={assets.data} />
          </div>
        </CardAntd>
      </div>
    </>
  );
};
