export const Routes = {
  // pages
  DashboardOverview: { path: "/" },
  Report: { path: "/report" },
  Customer: { path: "/customer" },
  Asset: { path: "/asset" },
  User: { path: "/user" },
  UserAsset: { path: "/user-asset" },
  EMR: { path: "/generate-emr" },
  EMRList: { path: "/emr" },
  EMRV2List: { path: "/emrv2" },
  DocType: { path: "/doctype" },
  ReportSummary: { path: "/report-summary" },
  AssetSummary: { path: "/asset-summary" },
  OperatorSummary: { path: "/operator-summary" },
  Export: { path: "/export-excel" },
  ExportPDFAsset: { path: "/export-pdf-asset" },
  Logs: { path: "/logs" },

  OperatorLaporan: { path: "/operator/laporan" },
  OperatorEmr: { path: "/operator/emr" },

  Signin: { path: "/login" },
  Signup: { path: "/register" },
  ForgotPassword: { path: "/forgot-password" },
  ResetPassword: { path: "/reset-password" },

  report: {
    transaction: {
      path: "/report/transaction",
    },
  },

  NotFound: { path: "/404" },
  ServerError: { path: "/500" },
};
