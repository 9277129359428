import React, { useState, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEye,
  faHouseMedicalCircleExclamation,
  faMapMarked,
  faPenToSquare,
  faPlus,
  faWrench,
} from "@fortawesome/free-solid-svg-icons";

import {
  // Button,
  Card,
  // Table,
  Form,
  InputGroup,
  Spinner,
  Pagination,
  Nav,
  FormControl,
} from "@themesberg/react-bootstrap";
// import Select from "react-select";

import { useEffect } from "react";
import Swal from "sweetalert2";
import FormAsset from "./FormAsset";
import assetSvc from "../../services/assetSvc";
import customerSvc from "../../services/customerSvc";
import { useHistory } from "react-router-dom";
import { getLoginData } from "../../services/authSvc";
import docTypeSvc from "../../services/docTypeSvc";
import {
  showErrorAlert,
  showLoadingAlert,
  showSuccessAlert,
} from "../../utils/SwalUtil";
import { customStyles } from "../../utils/SelectUtils";
import {
  Card as CardAntd,
  Input,
  Tooltip,
  Select,
  Button,
  Tag,
  Table,
  Space,
  Menu,
  Dropdown,
} from "antd";
import { EllipsisOutlined, SearchOutlined } from "@ant-design/icons";

const { Option } = Select;
export default () => {
  const searchInputRef = useRef(null);
  const [assets, setAssets] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [totalRows, setTotalRows] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(0);
  const history = useHistory();
  const { user } = getLoginData();
  const role = user?.role;
  const dev = process.env.REACT_APP_UI_DEV;

  const fetchAssets = async () => {
    setIsLoading(true);
    const resp = await assetSvc.getAsset({
      params: {
        page: page,
        limit: pageSize,
        query: searchTerm ? searchTerm : null,
        active: selectedStatus,
        id_pelanggan: selectedCustomer ? selectedCustomer : null,
        fields: undefined,
        includes: JSON.stringify([
          {
            model: "pelanggan",
            attributes: ["name"],
          },
        ]),
      },
    });
    if (resp.status === 200) {
      setAssets(resp.data.data);
      setTotalRows(resp.data.totalRows);
    }

    setIsLoading(false);
  };

  const fetchCustomers = async () => {
    if (user?.role === "admin") {
      const resp = await customerSvc.getCustomer({
        params: {
          page: 0,
          limit: 1000,
          // active: true,
        },
      });
      if (resp.status === 200) {
        setCustomers(resp.data.data);
      }
    }
  };

  useEffect(() => {
    fetchCustomers();
  }, []);

  const isFirstRenderAssets = useRef(true);
  useEffect(() => {
    if (!isFirstRenderAssets.current) {
      fetchAssets();
    } else {
      isFirstRenderAssets.current = false;
    }
  }, []);

  useEffect(() => {
    fetchAssets();
  }, [selectedCustomer, selectedStatus, page, pageSize]);

  const isFirstRender = useRef(true);
  useEffect(() => {
    if (!isFirstRender.current) {
      let timerId;
      const fetchData = () => {
        fetchAssets();
      };
      const delayedFetch = () => {
        clearTimeout(timerId);
        timerId = setTimeout(fetchData, 500); // Adjust the delay time as per your needs
      };
      delayedFetch(); // Call the delayedFetch initially to trigger the initial API call
      return () => {
        // Clean up by clearing the timer when the component unmounts
        clearTimeout(timerId);
      };
    } else {
      isFirstRender.current = false;
    }
  }, [searchTerm]);

  const handlePageChange = (page) => {
    setPage(page - 1);
  };

  const handleClearFilters = () => {
    setSearchTerm("");
    setSelectedStatus(null);
    setSelectedCustomer(null);
    if (searchInputRef.current) {
      searchInputRef.current.value = "";
    }
  };

  const handleActive = async (id) => {
    const willUpdate = await Swal.fire({
      title: "Konfirmasi Perubahan?",
      text: "Status asset akan berubah setelah update!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Lanjutkan!",
      cancelButtonText: "Batalkan",
    });

    if (willUpdate.isConfirmed) {
      try {
        showLoadingAlert("Tunggu", "Mengubah status aset...");
        const resp = await assetSvc.updateActivateAsset(id);
        if (resp.status === 200) {
          fetchAssets();
          showSuccessAlert("Berhasil", "Data status aset berhasil");
        }
      } catch (error) {
        let message =
          error.response.data.message || "Gagal mengubah status aset";
        showErrorAlert("Gagal", message);
      }
    }
  };

  const handleActiveGPS = async (id) => {
    const willUpdate = await Swal.fire({
      title: "Konfirmasi Perubahan?",
      text: "Status GPS ATM akan berubah setelah update!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Lanjutkan!",
      cancelButtonText: "Batalkan",
    });

    if (willUpdate.isConfirmed) {
      try {
        showLoadingAlert("Tunggu", "Mengubah status GPS...");
        const resp = await assetSvc.updateActivateAssetGPS(id);
        if (resp.status === 200) {
          fetchAssets();
          showSuccessAlert("Berhasil", "Data status GPS berhasil");
        }
      } catch (error) {
        let message =
          error.response.data.message || "Gagal mengubah status GPS";
        showErrorAlert("Gagal", message);
      }
    }
  };

  const handleAddAsset = () => {
    history.push("/asset/add");
  };

  const handleEditAsset = async (id) => {
    history.push(`/asset/update/${id}`);
  };

  const truncateAddress = (address) => {
    const maxLength = 50; // Set the maximum length for the address
    if (address.length <= maxLength) {
      return address;
    }
    return address.substring(0, maxLength) + "...";
  };

  const handleSelectCustomer = (selectedOption) => {
    setSelectedCustomer(selectedOption);
  };

  const columns = [
    {
      title: "Nama ATM",
      fixed: "left",
      dataIndex: "name",
      key: "name",
      render: (text, asset) => (
        <div
          style={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          <span className="fw-bold">{asset?.name}</span>
          <br />
          {asset?.kode}
        </div>
      ),
    },
    {
      title: "Lokasi",
      dataIndex: "lokasi",
      key: "lokasi",
      render: (text, asset) => (
        <div
          style={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          <span className="fw-bold">{asset.lokasi}</span>
          <br />
          {truncateAddress(asset?.alamat)}
        </div>
      ),
    },
    {
      title: "Kepemilikan",
      dataIndex: "pelanggan",
      key: "pelanggan",
      render: (text, asset) => (
        <div
          style={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          <span className="fw-bold">
            {asset?.pelanggan?.name.split(" ").slice(0, -1).join(" ")}
          </span>
          <br />
          <span className="fw-bold">
            {asset?.pelanggan?.name?.split(" ").slice(-1)}
          </span>
        </div>
      ),
    },
    {
      title: "Ring",
      dataIndex: "ring",
      key: "ring",
      render: (text) => <span className="fw-bold">{text}</span>,
    },
    {
      title: "Status",
      dataIndex: "active",
      key: "active",
      render: (active) => (
        <Tag
          style={{
            backgroundColor: active ? "#05a677" : "rgb(251 83 83)",
            borderColor: "transparent",
            color: "#fff",
          }}
          className="rounded-pill p-2"
        >
          {active ? "Aktif" : "Nonaktif"}
        </Tag>
      ),
    },
    {
      title: "GPS",
      dataIndex: "gps_active",
      key: "gps_active",
      render: (gps_active) => (
        <Tag
          style={{
            backgroundColor: gps_active ? "#05a677" : "rgb(251 83 83)",
            borderColor: "transparent",
            color: "#fff",
          }}
          className="rounded-pill p-2"
        >
          {gps_active ? "Aktif" : "Nonaktif"}
        </Tag>
      ),
    },
    {
      title: "Aksi",
      key: "action",
      fixed: "right",
      render: (text, asset) => {
        const items = [
          {
            key: "0",
            label: "Lihat Detail",
            icon: <FontAwesomeIcon icon={faEye} />,
            onClick: () => {
              history.push(`/asset/detail/${asset.id_asset}`);
            },
          },
        ];

        if (role === "admin" && dev === "developer") {
          items.push(
            {
              key: "1",
              label: "Ubah Data Asset",
              icon: <FontAwesomeIcon icon={faPenToSquare} />,
              onClick: () => {
                handleEditAsset(asset.id_asset);
              },
            },
            {
              key: "2",
              label: asset.active ? "Nonaktifkan" : "Aktifkan",
              icon: <FontAwesomeIcon icon={faWrench} />,
              onClick: () => {
                handleActive(asset.id_asset);
              },
            },
            {
              key: "3",
              label: asset.gps_active ? "Nonaktifkan GPS" : "Aktifkan GPS",
              icon: <FontAwesomeIcon icon={faMapMarked} />,
              onClick: () => {
                handleActiveGPS(asset.id_asset);
              },
            }
          );
        }

        return (
          <Space size="middle">
            <Dropdown overlay={<Menu items={items} />}>
              <Button icon={<EllipsisOutlined />} />
            </Dropdown>
          </Space>
        );
      },
    },
  ].filter(Boolean);

  const handlePageSizeChange = (current, pageSize) => {
    setPage(0);
    setPageSize(pageSize);
  };

  const pagination = {
    showTotal: (total, range) =>
      `Showing ${range[0]}-${range[1]} of ${total} items`,
    pageSize: pageSize,
    total: totalRows,
    onChange: handlePageChange,
    onShowSizeChange: handlePageSizeChange,
    pageSizeOptions: [5, 10, 20],
    showSizeChanger: true,
    position: ["bottomRight"],
    className: "custom-pagination",
  };

  return (
    <div className="align-items-center py-4 w-100">
      <CardAntd
        title={<span style={{ fontSize: "20px" }}>ATM</span>}
        extra={
          <div className="d-flex">
            {role === "admin" && dev === "developer" && (
              <div className="d-flex gap-2 ">
                <Tooltip title={"Tambah ATM"}>
                  <Button variant="success" size="sm" onClick={handleAddAsset}>
                    <FontAwesomeIcon
                      icon={faPlus}
                      style={{ fontSize: "14px" }}
                    />
                  </Button>
                </Tooltip>
              </div>
            )}
          </div>
        }
        style={{ width: "100%" }}
      >
        <div className="d-flex flex-wrap justify-content-between mb-3 gap-2">
          <Input.Group style={{ flexGrow: 1, flexBasis: "300px" }}>
            <Input
              size="middle"
              placeholder="Cari nama ATM, Lokasi, Kode, dll..."
              prefix={<SearchOutlined />}
              value={searchTerm}
              onChange={(e) => {
                setSearchTerm(e.target.value);
              }}
              className="me-1"
              ref={searchInputRef}
            />
          </Input.Group>

          <Input.Group style={{ flexGrow: 1, flexBasis: "300px" }}>
            <Select
              id="state"
              value={selectedStatus}
              onChange={(value) => setSelectedStatus(value)}
              placeholder="Semua Status"
              style={{ width: "100%", height: "100%" }}
              size="middle"
            >
              <Option value="">Semua Status</Option>
              <Option value="true">Status Aktif</Option>
              <Option value="false">Status Nonaktif</Option>
            </Select>
          </Input.Group>

          {user?.role === "admin" && (
            <Input.Group style={{ flexGrow: 1, flexBasis: "300px" }}>
              <Select
                placeholder="Semua Kantor Cabang"
                allowClear
                showSearch
                onChange={handleSelectCustomer}
                value={selectedCustomer}
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
                style={{ width: "100%", height: "100%" }}
                size="middle"
              >
                {customers.map((data) => (
                  <Select.Option
                    key={data.id_pelanggan}
                    value={data.id_pelanggan}
                  >
                    {data.name}
                  </Select.Option>
                ))}
              </Select>
            </Input.Group>
          )}

          <Button
            variant="secondary"
            size="sm"
            className="ml-2"
            style={{ height: "auto" }}
            onClick={(e) => {
              handleClearFilters();
            }}
          >
            Reset Filter
          </Button>
        </div>

        <div style={{ overflowX: "auto" }}>
          <Table
            columns={columns}
            dataSource={assets}
            pagination={pagination}
            loading={isLoading}
            size="middle"
            // scroll={{ x: 1200, y: 600 }}
          ></Table>
        </div>
      </CardAntd>
    </div>
  );
};
