import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import Datetime from "react-datetime";
import assetSvc from "../../services/assetSvc";
import moment from "moment";
import reportSvc from "../../services/reportSvc";
import userSvc from "../../services/userSvc";

import {
  Col,
  Row,
  Button,
  Card,
  Table,
  Form,
  InputGroup,
  Spinner,
} from "@themesberg/react-bootstrap";

import { getLoginData } from "../../services/authSvc";
import customerSvc from "../../services/customerSvc";
import { customStyles } from "../../utils/SelectUtils";
import Select from "react-select";
import NavTabs from "../../components/NavTabs";
import { useHistory } from "react-router-dom";
import dataHoliday from "../../data/holiday_2024.json";
moment.tz.setDefault("Asia/Bangkok");

export default () => {
  const [isLoading, setIsLoading] = useState(false);
  const [startDate, setStartDate] = useState(moment().format("YYYY-MM-DD"));
  const [endDate, setEndDate] = useState(moment().format("YYYY-MM-DD"));
  const [customers, setCustomers] = useState([]);
  const [customersWithReports, setCustomersWithReports] = useState([]);
  const { user } = getLoginData();
  const history = useHistory();

  const handleTabChange = (path) => {
    history.push(path);
  };

  const fetchCustomers = async () => {
    try {
      setIsLoading(true);
      const resp = await reportSvc.getAssetSummaryReport({
        params: {
          start_date: startDate,
          end_date: endDate,
        },
      });

      if (resp.status === 200) {
        setCustomers(resp.data.data);
      }

      setIsLoading(false);
    } catch (error) {
      // Handle errors
    }
  };

  useEffect(() => {
    fetchCustomers();
  }, []);

  return (
    <>
      <div className="align-items-center py-4 w-100">
        <Card border="light" className="shadow-sm">
          <Card.Header>
            <NavTabs
              activeTab={"/asset-summary"}
              onTabChange={handleTabChange}
              sideTab={"infografis"}
            />
            <div className="mt-3 " style={{ paddingLeft: "1rem" }}>
              <h5>Total Laporan Belum Dikerjakan</h5>
            </div>
          </Card.Header>
          <Card.Body>
            {isLoading ? (
              <div
                className="d-flex justify-content-center align-items-center"
                style={{ height: "300px" }}
              >
                <Spinner animation="border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              </div>
            ) : (
              <Table responsive>
                <thead>
                  <tr>
                    <th>Kantor Cabang</th>
                    <th>Belum Dikerjakan</th>
                    <th>Total ATM Aktif</th>
                  </tr>
                </thead>
                <tbody>
                  {customers.map((customer) => (
                    <tr key={customer.id_pelanggan}>
                      <td>{customer.name}</td>
                      <td>{customer.belum_dikerjakan}</td>
                      <td>{customer.total_atm_active}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            )}
          </Card.Body>
        </Card>
      </div>
    </>
  );
};
