import mem from "mem";
import { api } from "./api";
import userSvc from "./userSvc";
import useUserStore from "../zustand/useUserStore";

export default function saveLoginData(user, token) {
  // const userStore = useUserStore();
  // console.log({ userStore });
  localStorage.setItem("user", JSON.stringify(user));
  localStorage.setItem("authToken", token);
  // userStore.setUser(user);

  // sessionStorage.setItem("user", JSON.stringify(user));
  // sessionStorage.setItem("authToken", token);
  // document.cookie = `authToken=${token}; path=/`;
  // document.cookie = `user=${JSON.stringify(user)}; path=/`;
}

export function restoreToken(token) {
  localStorage.setItem("authToken", token);
  document.cookie = `authToken=${token};`;
}

export function getLoginData() {
  const user = JSON.parse(localStorage.getItem("user"));
  const token = localStorage.getItem("authToken");

  return { user, token };
}

export function removeLoginData() {
  localStorage.removeItem("user");
  localStorage.removeItem("authToken");
}

const refreshTokenFn = async () => {
  try {
    const response = await userSvc.refreshToken();
    // console.log({ response });
    const { access_token, refresh_token } = response.data;

    localStorage.setItem("authToken", access_token);

    return access_token;
  } catch (error) {
    // console.log("eror on mem", { error });
    localStorage.removeItem("authToken");
    localStorage.removeItem("user");

    return null;
  }
};

const maxAge = 1000;

export const memoizedRefreshToken = mem(refreshTokenFn, {
  maxAge,
});
