import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faKey,
  faPenToSquare,
  faPlus,
  faWrench,
} from "@fortawesome/free-solid-svg-icons";

import {
  Button,
  Card,
  Table,
  Form,
  InputGroup,
  Spinner,
  Pagination,
  Nav,
  FormControl,
  Image,
} from "@themesberg/react-bootstrap";
import { useEffect, useRef } from "react";
import Swal from "sweetalert2";
import userSvc from "../services/userSvc";
import customerSvc from "../services/customerSvc";
import { useHistory } from "react-router-dom";
import {
  showErrorAlert,
  showLoadingAlert,
  showSuccessAlert,
} from "../utils/SwalUtil";
import { getLoginData } from "../services/authSvc";
import Select from "react-select";
import { customStyles } from "../utils/SelectUtils";
import NavTabs from "../components/NavTabs";

export default () => {
  const searchInputRef = useRef(null);
  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [activePage, setActivePage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [startIndex, setStartIndex] = useState(null);
  const [endIndex, setEndIndex] = useState(null);
  const [totalPages, setTotalPages] = useState(null);
  const { user } = getLoginData();
  const role = user?.role;
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");
  const [selectedRole, setSelectedRole] = useState(
    role === "admin cabang" ? "operator" : ""
  );
  const [customers, setCustomers] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState("");
  const [totalRows, setTotalRows] = useState("");

  const history = useHistory();
  const dev = process.env.REACT_APP_UI_DEV;

  const renderPaginationItems = () => {
    const paginationItems = [];

    paginationItems.push(
      <Pagination.First
        key="first"
        onClick={() => handlePageChange(1)}
        disabled={activePage === 1}
      />
    );

    paginationItems.push(
      <Pagination.Prev
        key="prev"
        onClick={() => activePage > 1 && handlePageChange(activePage - 1)}
        disabled={activePage === 1}
      />
    );

    for (let i = 1; i <= totalPages; i++) {
      if (
        i === 1 ||
        i === totalPages ||
        (i >= activePage - 2 && i <= activePage + 2)
      ) {
        paginationItems.push(
          <Pagination.Item
            key={i}
            onClick={() => handlePageChange(i)}
            active={i === activePage}
          >
            {i}
          </Pagination.Item>
        );
      } else if (
        (i === activePage - 3 && i > 2) ||
        (i === activePage + 3 && i < totalPages - 1)
      ) {
        paginationItems.push(<Pagination.Ellipsis key={i} />);
      }
    }

    paginationItems.push(
      <Pagination.Next
        key="next"
        onClick={() =>
          activePage < totalPages && handlePageChange(activePage + 1)
        }
        disabled={activePage === totalPages}
      />
    );

    paginationItems.push(
      <Pagination.Last
        key="last"
        onClick={() => handlePageChange(totalPages)}
        disabled={activePage === totalPages}
      />
    );

    return paginationItems;
  };

  const handleItemsPerPageChange = (event) => {
    const newItemsPerPage = parseInt(event.target.value);
    setItemsPerPage(newItemsPerPage);
    setActivePage(1); // Reset halaman aktif ke halaman pertama
  };

  const getCustomers = async () => {
    if (role === "admin") {
      const respCust = await customerSvc.getCustomer({
        params: {
          page: 0,
          limit: 1000,
        },
      });

      if (respCust.status === 200) {
        setCustomers(respCust.data.data);
      } else {
        setCustomers([]);
      }
    }
  };

  const fetchUsers = async (page, limit) => {
    setIsLoading(true);
    if (role === "admin cabang") {
      setSelectedRole("operator");
    }
    const resp = await userSvc.getUser({
      params: {
        page: page - 1,
        limit: limit,
        query: searchTerm,
        active:
          selectedStatus === "active"
            ? true
            : selectedStatus === "inactive"
            ? false
            : "",
        role: selectedRole,
        id_pelanggan: selectedCustomer ? selectedCustomer.value : "",
      },
    });
    if (resp.status === 200) {
      setUsers(resp.data.data);
      setStartIndex((activePage - 1) * itemsPerPage);
      setEndIndex(startIndex + itemsPerPage);
      setTotalPages(resp.data.totalPage);
      setTotalRows(resp.data.totalRows);
    }
    setIsLoading(false);
  };

  const fetchUsersRemoveFilter = async (page, limit) => {
    setIsLoading(true);
    const resp = await userSvc.getUser({
      params: {
        page: page - 1,
        limit: limit,
      },
    });
    if (resp.status === 200) {
      setUsers(resp.data.data);
      setStartIndex((activePage - 1) * itemsPerPage);
      setEndIndex(startIndex + itemsPerPage);
      setTotalPages(resp.data.totalPage);
      setTotalRows(resp.data.totalRows);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getCustomers();
  }, []);

  const isFirstRenderUsers = useRef(true);
  useEffect(() => {
    if (!isFirstRenderUsers.current) {
      fetchUsers(activePage, itemsPerPage);
    } else {
      isFirstRenderUsers.current = false;
    }
  }, [activePage, itemsPerPage]);

  useEffect(() => {
    fetchUsers();
  }, [selectedCustomer, selectedRole, selectedStatus]);

  const isFirstRender = useRef(true);
  useEffect(() => {
    if (!isFirstRender.current) {
      let timerId;
      const fetchData = () => {
        fetchUsers();
      };
      const delayedFetch = () => {
        clearTimeout(timerId);
        timerId = setTimeout(fetchData, 500); // Adjust the delay time as per your needs
      };
      delayedFetch(); // Call the delayedFetch initially to trigger the initial API call
      return () => {
        // Clean up by clearing the timer when the component unmounts
        clearTimeout(timerId);
      };
    } else {
      isFirstRender.current = false;
    }
  }, [searchTerm]);

  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
  };

  const handleClearFilters = () => {
    setSearchTerm("");
    setSelectedRole("");
    setSelectedCustomer("");
    setSelectedStatus("");
    setActivePage(1);

    if (searchInputRef.current) {
      searchInputRef.current.value = "";
    }

    fetchUsersRemoveFilter();
  };

  const handleActive = async (id) => {
    const willUpdate = await Swal.fire({
      title: "Konfirmasi Perubahan?",
      text: "Status Pengguna akan berubah setelah update!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Lanjutkan!",
      cancelButtonText: "Batalkan",
    });

    if (willUpdate.isConfirmed) {
      try {
        showLoadingAlert("Tunggu", "Mengubah status user...");
        const resp = await userSvc.updateActivate(id);
        if (resp.status === 200) {
          fetchUsers(activePage, itemsPerPage); // Fetch users using the current active page
          showSuccessAlert("Berhasil", "Status user berhasil diubah");
        }
      } catch (error) {
        let message = error.response?.data?.message || "Gagal mengubah status";
        showErrorAlert("Gagal", message);
      }
    }
  };

  const handleResetPasswordEmail = async (id) => {
    const willResetPassword = await Swal.fire({
      title: "Reset Password",
      text: "Apakah anda yakin untuk mengatur ulang kata sandi pengguna ini? kata sandi baru akan dikirimkan melalui email pengguna",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Lanjutkan",
      cancelButtonText: "Batalkan",
    });

    if (willResetPassword.isConfirmed) {
      try {
        showLoadingOverlay();

        const response = await userSvc.updatePasswordByEmail(id);

        if (response.status === 200) {
          showSuccessAlert(
            "Berhasil",
            "Password berhasil diubah, silakan cek email!"
          );
        }
      } catch (error) {
        let message =
          error.response?.data?.message || "Gagal mengubah password ";
        showErrorAlert("Gagal", message);
      } finally {
        hideLoadingOverlay();
      }
    }
  };

  const showLoadingOverlay = () => {
    const loadingOverlay = document.getElementById("content");

    if (loadingOverlay) {
      loadingOverlay.style.display = "none";
    }
    const load = document.getElementById("loading-overlay");

    if (load) {
      load.style.display = "block";
    }
  };

  const hideLoadingOverlay = () => {
    const loadingOverlay = document.getElementById("content");
    if (loadingOverlay) {
      loadingOverlay.style.display = "block";
    }
    const load = document.getElementById("loading-overlay");

    if (load) {
      load.style.display = "none";
    }
  };

  const handleAddUser = () => {
    history.push("/user/add");
  };

  const handleEditUser = async (id) => {
    history.push(`/user/update/${id}`);
  };

  const handleSelectCustomer = (selectedOption) => {
    setSelectedCustomer(selectedOption);
  };

  const handleTabChange = () => {
    useHistory.push("/");
  };

  return (
    <>
      <div className="align-items-center py-4 w-100">
        <Card border="light" className="shadow-sm">
          <Card.Header style={{ paddingBottom: "0px" }}>
            <NavTabs
              activeTab={"/user"}
              onTabChange={handleTabChange}
              sideTab={"user"}
            />
            <hr />
            {role === "admin" && dev === "developer" ? (
              <div className="d-flex mb-3">
                <Button
                  variant="success"
                  size="sm"
                  className=""
                  style={{ width: "100px" }}
                  onClick={handleAddUser}
                >
                  <FontAwesomeIcon
                    icon={faPlus}
                    className="me-2"
                    style={{ fontSize: "14px" }}
                  />
                  Tambah
                </Button>
              </div>
            ) : (
              ""
            )}
            <div className="d-flex justify-content-between mb-3">
              <FormControl
                type="text"
                placeholder="Cari nama, no hp, email pengguna..."
                className="me-2"
                ref={searchInputRef}
                onChange={(e) => {
                  setSearchTerm(e.target.value);
                }}
              />
              <InputGroup>
                <Form.Select
                  id="state"
                  className="me-1"
                  defaultValue=""
                  value={selectedStatus}
                  style={{ width: "30px" }}
                  onChange={(e) => {
                    setSelectedStatus(e.target.value);
                  }}
                >
                  <option value="">Semua Status</option>
                  <option value="active">Status Aktif</option>
                  <option value="inactive">Status Nonaktif</option>
                </Form.Select>
              </InputGroup>
              <InputGroup>
                <Form.Select
                  id="state"
                  className="me-1"
                  defaultValue=""
                  value={selectedRole}
                  style={{ width: "30px" }}
                  onChange={(e) => {
                    setSelectedRole(e.target.value);
                  }}
                >
                  {role === "admin cabang" ? (
                    <>
                      <option value="operator">Operator</option>
                    </>
                  ) : (
                    <>
                      <option value="">Semua Role</option>
                      <option value="admin">Admin</option>
                      <option value="admin cabang">Admin Cabang</option>
                      <option value="operator">Operator</option>
                    </>
                  )}
                </Form.Select>
              </InputGroup>
              {user.role === "admin" && (
                <InputGroup>
                  <Select
                    className="me-1"
                    id="state"
                    placeholder="Semua Kantor Cabang"
                    styles={customStyles}
                    value={selectedCustomer}
                    onChange={handleSelectCustomer}
                    options={customers.map((user) => ({
                      value: user.id_pelanggan,
                      label: user.name,
                    }))}
                  />
                </InputGroup>
              )}

              <Button
                variant="secondary"
                size="sm"
                className="ms-2"
                onClick={handleClearFilters}
                style={{ marginRight: "10px" }}
              >
                Reset Filter
              </Button>
            </div>
          </Card.Header>

          <Card.Body className="pb-0">
            <Table
              responsive
              className="table-centered table-nowrap rounded mb-0"
            >
              <thead className="thead-light">
                <tr>
                  <th className="border-0">Nama Pengguna</th>
                  <th className="border-0">Nomor Telepon</th>
                  <th className="border-0">Role</th>
                  <th className="border-0">Status</th>
                  {role === "admin" && dev === "developer" && (
                    <th className="border-0">Aksi</th>
                  )}
                  <th className="border-0">Detail</th>
                </tr>
              </thead>

              {isLoading ? (
                <tr>
                  <td
                    colSpan={5}
                    className="text-center"
                    style={{ height: "300px" }}
                  >
                    <Spinner animation="border" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </Spinner>
                  </td>
                </tr>
              ) : totalRows > 0 ? (
                <tbody>
                  {users.map((user) => (
                    <tr key={user.id_user}>
                      <td className="border-0">
                        <div
                          style={{ display: "flex", alignItems: "center" }}
                          className="mb-2"
                        >
                          {user.photo_url ? (
                            <Image
                              src={user.photo_url}
                              alt="profile-pict"
                              width="40px"
                              height="40px"
                              style={{
                                borderRadius: "50%",
                                marginRight: "10px",
                              }}
                            />
                          ) : (
                            <div
                              className="bg-primary"
                              style={{
                                width: "40px",
                                height: "40px",
                                borderRadius: "50%",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                marginRight: "10px",
                              }}
                            >
                              {user.name !== "" && (
                                <span
                                  style={{
                                    color: "#fff",
                                    fontSize: "16px",
                                    fontWeight: "bold",
                                  }}
                                >
                                  {user?.name?.charAt(0).toUpperCase()}
                                </span>
                              )}
                            </div>
                          )}
                          <div>
                            <span className="fw-bold">{user.name}</span>
                            <br />
                            {user.email}
                          </div>
                        </div>
                      </td>

                      <td className="border-0">
                        <span className="fw-bolder">{user.phone}</span>
                      </td>
                      <td className="fw-bold border-0">
                        <span className="fw-bolder">
                          {user.role.toUpperCase()}
                        </span>
                        {user.pelanggan && (
                          <>
                            <br />
                            {user.pelanggan.name}
                          </>
                        )}
                      </td>
                      <td className="border-0">
                        <div>
                          {user.active ? (
                            <div className="bg-success text-white rounded-pill p-2 text-center">
                              Aktif
                            </div>
                          ) : (
                            <div className="bg-danger text-white rounded-pill p-2 text-center">
                              Nonaktif
                            </div>
                          )}
                        </div>
                      </td>
                      {role === "admin" && dev === "developer" && (
                        <td className="border-0">
                          <div className="d-flex align-items-center">
                            <div
                              className="inline-block"
                              style={{
                                border: "12px solid transparent",
                                margin: "-12px",
                                "--c81fc0a4": "9999",
                                marginRight: "2px",
                              }}
                            >
                              <button className="bg-white hover:bg-bs-bg block border border-bs-line rounded p-1.5">
                                <div
                                  className="logo-button"
                                  onClick={(e) => {
                                    handleResetPasswordEmail(user.id_user);
                                  }}
                                  title="Reset Password Melalui Email"
                                  style={{ cursor: "pointer" }}
                                >
                                  <FontAwesomeIcon icon={faKey} />
                                </div>
                              </button>
                            </div>

                            <div
                              className="inline-block"
                              style={{
                                border: "12px solid transparent",
                                margin: "-12px",
                                "--c81fc0a4": "9999",
                                marginRight: "2px",
                              }}
                            >
                              <button className="bg-white hover:bg-bs-bg block border border-bs-line rounded p-1.5">
                                <div
                                  className="logo-button"
                                  onClick={(e) => handleEditUser(user.id_user)}
                                  title="Ubah Data Pengguna"
                                  style={{ cursor: "pointer" }}
                                >
                                  <FontAwesomeIcon icon={faPenToSquare} />
                                </div>
                              </button>
                            </div>

                            <div
                              className="inline-block"
                              style={{
                                border: "12px solid transparent",
                                margin: "-12px",
                                "--c81fc0a4": "9999",
                              }}
                            >
                              <button className="bg-white hover:bg-bs-bg block border border-bs-line rounded p-1.5">
                                <div
                                  className="logo-button"
                                  onClick={(e) => handleActive(user.id_user)}
                                  title={
                                    user.active ? "Nonaktifkan" : "Aktifkan"
                                  }
                                  style={{ cursor: "pointer" }}
                                >
                                  <FontAwesomeIcon icon={faWrench} />
                                </div>
                              </button>
                            </div>
                          </div>
                        </td>
                      )}
                      <td className="fw-bold border-0">
                        <a
                          href={`/user/detail/${user.id_user}`}
                          rel="noopener noreferrer"
                          className="fw-bolder"
                        >
                          Lihat
                        </a>
                      </td>
                    </tr>
                  ))}
                </tbody>
              ) : (
                <tr>
                  <td
                    colSpan={5}
                    className="text-center"
                    style={{ height: "200px" }}
                  >
                    <span className="text-muted">Tidak ada Pengguna</span>
                  </td>
                </tr>
              )}
            </Table>
          </Card.Body>
          <Card.Footer className="px-3 border-0 d-lg-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center">
              <small className="me-2">Show:</small>
              <Form.Select
                className="me-3"
                style={{ width: "70px" }}
                value={itemsPerPage}
                onChange={handleItemsPerPageChange}
              >
                <option value="5">5</option>
                <option value="10">10</option>
                <option value="15">15</option>
              </Form.Select>
              <small className="fw-bold">
                Showing {startIndex + 1} -{" "}
                {Math.min(startIndex + itemsPerPage, totalRows)} of {totalRows}{" "}
                users
              </small>
            </div>

            <div className="d-flex align-items-center">
              <Nav className="mx-2">
                <Pagination className="mb-2 mb-lg-0">
                  {renderPaginationItems()}
                </Pagination>
              </Nav>
            </div>
          </Card.Footer>
        </Card>
        {/* <div id="loading-overlay" style="display: none;">
          <div class="loader"></div>
            <p>Loading...</p>
        </div> */}
      </div>
    </>
  );
};
