import Swal from "sweetalert2";

export const showSuccessAlert = (title, text) => {
  Swal.fire({
    icon: "success",
    title: title,
    text: text,
  });
};

export const showErrorAlert = (title, text) => {
  Swal.fire({
    icon: "error",
    title: title,
    text: text,
  });
};

export const showLoadingAlert = (title, text) => {
  Swal.fire({
    title: title,
    text: text,
    allowOutsideClick: false,
    showConfirmButton: false,
    didOpen: () => {
      Swal.showLoading();
    },
  });
};

export const showWarningAlert = (title, text) => {
  Swal.fire({
    icon: "warning",
    title: title,
    text: text,
  });
};

export const showConfirmButton = async (
  title,
  text,
  icon,
  showCancelButton,
  confirmButtonColor
) => {
  return await Swal.fire({
    title: title,
    text: text,
    icon: icon || "warning",
    // showCancelButton: showCancelButton || true,
    // confirmButtonColor: "#d33",
    // cancelButtonColor: "#3085d6",
    // confirmButtonText: "Lanjutkan!",
    // cancelButtonText: "Batalkan",
  });
};

export const hideLoadingAlert = () => {
  // Close the loading alert modal
  Swal.close();
};
