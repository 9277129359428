import { useEffect, useRef } from "react";
import L from "leaflet";
import marker from "../assets/img/marker-removebg-preview.png";
import { useHistory } from "react-router-dom";

const MAPBOX_URL = `https://api.mapbox.com/styles/v1/mapbox/streets-v12/tiles/{z}/{x}/{y}?access_token=${process.env.REACT_APP_MAPBOX_TOKEN}`;

const MapComponent = ({ assets }) => {
  const mapRef = useRef(null);
  const mapContainerRef = useRef(null); // Reference to the map div container
  const history = useHistory();
  const popupLinkHandler = useRef(null); // Ref to hold the event listener function

  const handleDetailAsset = (id) => {
    history.push(`/asset/detail/${id}`);
  };

  useEffect(() => {
    if (mapContainerRef.current && mapRef.current === null) {
      mapRef.current = L.map(mapContainerRef.current).setView(
        [-6.1259391, 106.5262299],
        12
      );

      L.tileLayer(MAPBOX_URL, {
        attribution: "© Mapbox",
      }).addTo(mapRef.current);

      const group = L.featureGroup().addTo(mapRef.current);

      const defaultIcon = L.divIcon({
        className: "custom-marker-icon",
        html: `<div style="background-color: #f76617; width: 10px; height: 10px; border-radius: 50%; border: 1px solid black;"></div>`,
        iconSize: [16, 16],
        iconAnchor: [8, 8],
      });

      const nonActiveIcon = L.divIcon({
        className: "custom-marker-icon",
        html: `<div style="background-color: #ffea00; width: 10px; height: 10px; border-radius: 50%; border: 1px solid black;"></div>`,
        iconSize: [16, 16],
        iconAnchor: [8, 8],
      });

      if (assets) {
        assets.forEach((element) => {
          if (element.latitude && element.longitude) {
            const marker = L.marker([element.latitude, element.longitude], {
              icon: element.active ? defaultIcon : nonActiveIcon,
            }).addTo(group);

            const popupContent = `
              <div>
                ${element.name} - ${element.kode} <br />
                ${element?.pelanggan?.name} <br />
                <a href="#" class="popup-link" data-id="${element.id_asset}">
                  Lihat ATM
                </a>
              </div>
            `;

            const hoverContent = `
              <span>
                ${element.name} - ${element?.pelanggan?.name} <br />
              </span>
            `;

            marker.bindTooltip(hoverContent);

            const popup = L.popup({
              closeButton: true,
              autoClose: true,
            }).setContent(popupContent);

            marker.on("click", (e) => {
              popup.setLatLng(e.latlng);
              mapRef.current.openPopup(popup);
            });
          }
        });

        popupLinkHandler.current = (event) => {
          if (event.target && event.target.classList.contains("popup-link")) {
            event.preventDefault();
            const assetId = event.target.getAttribute("data-id");
            handleDetailAsset(assetId);
          }
        };

        document.addEventListener("click", popupLinkHandler.current);
      }

      // Adding a legend to the map
      const legend = L.control({ position: "bottomright" });

      legend.onAdd = function (map) {
        const div = L.DomUtil.create("div", "info legend");
        const grades = ["Aktif", "Nonaktif"];
        const colors = ["#f76617", "#ffea00"];

        for (let i = 0; i < grades.length; i++) {
          div.innerHTML +=
            `<div style="display: flex; align-items: center; margin-bottom: 5px;">` +
            `<div style="background:${colors[i]}; width: 20px; height: 20px; border-radius: 50%; margin-right: 5px;"></div>` +
            `<div style='font-weight:bold'>${grades[i]}</div>`;
        }

        return div;
      };

      legend.addTo(mapRef.current);

      if (group.getBounds().isValid()) {
        mapRef.current.fitBounds(group.getBounds());
      }
    }

    return () => {
      if (mapRef.current) {
        mapRef.current.remove();
        mapRef.current = null;
      }

      if (popupLinkHandler.current) {
        document.removeEventListener("click", popupLinkHandler.current);
        popupLinkHandler.current = null; // Clean up the reference
      }
    };
  }, [assets]);

  return (
    <div
      id="map"
      ref={mapContainerRef} // Assign the reference to the div
      style={{
        height: "100%",
        width: "100%",
        zIndex: 1,
      }}
    ></div>
  );
};

export default MapComponent;
