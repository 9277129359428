import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowDown,
  faBell,
  faChevronDown,
  faCog,
  faDroplet,
  faEnvelopeOpen,
  faEye,
  faEyeSlash,
  faKey,
  faSearch,
  faSignOutAlt,
  faUserShield,
} from "@fortawesome/free-solid-svg-icons";
import { faUserCircle } from "@fortawesome/free-regular-svg-icons";
import {
  Row,
  Col,
  Nav,
  Form,
  Image,
  Navbar,
  Dropdown,
  Container,
  ListGroup,
  InputGroup,
  Modal,
  Button,
} from "@themesberg/react-bootstrap";

import NOTIFICATIONS_DATA from "../data/notifications";
import Profile3 from "../assets/img/team/profile-picture-3.jpg";
import { useEffect } from "react";
import { getLoginData, removeLoginData } from "../services/authSvc";
import userSvc from "../services/userSvc";
import Swal from "sweetalert2";
import { showErrorAlert, showSuccessAlert } from "../utils/SwalUtil";

export default (props) => {
  const { user } = getLoginData();
  const [notifications, setNotifications] = useState(NOTIFICATIONS_DATA);
  const areNotificationsRead = notifications.reduce(
    (acc, notif) => acc && notif.read,
    true
  );
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [showChangePasswordModal, setShowChangePasswordModal] = useState(false);
  const [isOldPasswordVisible, setIsOldPasswordVisible] = useState(false);
  const [isNewPasswordVisible, setIsNewPasswordVisible] = useState(false);
  const [isConfirmPasswordVisible, setIsConfirmPasswordVisible] =
    useState(false);

  const handleChangePassword = async () => {
    // Perform change password action
    try {
      if (confirmNewPassword !== newPassword) {
        throw new Error("Password dan konfirmasi password tidak sesuai!");
      }

      const data = {
        oldpassword: oldPassword,
        newpassword: newPassword,
      };
      const resp = await userSvc.updateUserPasswordById(user.id_user, data);
      if (resp.status === 200) {
        showSuccessAlert("Berhasil", "Sukses mengubah password");
        setOldPassword("");
        setNewPassword("");
        setConfirmNewPassword("");
        setShowChangePasswordModal(false);
      }
    } catch (error) {
      let message = error.response?.data?.message || "Gagal mengubah password";
      showErrorAlert("Gagal", message);
    }
  };

  function handleLogout() {
    removeLoginData();
    window.location.href = "/login";
  }
  const toggleOldPasswordVisibility = () => {
    setIsOldPasswordVisible(!isOldPasswordVisible);
  };
  const toggleNewPasswordVisibility = () => {
    setIsNewPasswordVisible(!isNewPasswordVisible);
  };
  const toggleConfirmPasswordVisibility = () => {
    setIsConfirmPasswordVisible(!isConfirmPasswordVisible);
  };

  return (
    <>
      <Navbar
        // variant="dark"
        expanded
        className="d-none d-md-block px-0 py-1 position-sticky top-0 "
        style={{
          zIndex: 1000,
          backgroundColor: "#ffffff",
          boxShadow: "0 4px 2px -2px rgba(0, 0, 0, 0.1)",
        }}
      >
        <Container fluid className="px-0">
          <div className="d-flex justify-content-between w-100">
            <div className="d-flex align-items-center"></div>
            <Nav className="align-items-center">
              <Dropdown as={Nav.Item}>
                <Dropdown.Toggle as={Nav.Link} className="">
                  <div className="media d-flex align-items-center ">
                    {user.photo_url ? (
                      <Image
                        src={user.photo_url}
                        className="user-avatar md-avatar rounded-circle d-none d-md-block"
                        style={{
                          height: "2rem",
                          width: "2rem",
                          minWidth: "2rem",
                          minHeight: "2rem",
                        }}
                      />
                    ) : (
                      <div
                        className="user-avatar md-avatar rounded-circle bg-primary"
                        style={{
                          height: "2rem",
                          width: "2rem",
                          minWidth: "2rem",
                          minHeight: "2rem",
                        }}
                      >
                        <span
                          style={{
                            fontSize: "12px",
                            fontWeight: "bold",
                          }}
                        >
                          {user.name.charAt(0).toUpperCase()}
                        </span>
                      </div>
                    )}
                    <div className="media-body mx-4 text-dark align-items-center d-none d-md-block">
                      <div className="font-small fw-bold">
                        {user.name} - {user.role}
                      </div>
                      {/* <div className="font-small text-muted">{user.role}</div> */}
                    </div>
                    <div>
                      <FontAwesomeIcon className="me-3" icon={faChevronDown} />
                    </div>
                  </div>
                </Dropdown.Toggle>
                <Dropdown.Menu className="user-dropdown dropdown-menu-right mt-2">
                  <Dropdown.Item
                    className="fw-bold"
                    onClick={() => setShowChangePasswordModal(true)}
                  >
                    <FontAwesomeIcon icon={faKey} className="me-2" /> Change
                    Password
                  </Dropdown.Item>
                  <Dropdown.Divider />
                  <Dropdown.Item className="fw-bold" onClick={handleLogout}>
                    <FontAwesomeIcon
                      icon={faSignOutAlt}
                      className="text-danger me-2"
                    />{" "}
                    Logout
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Nav>
          </div>
        </Container>
      </Navbar>

      {/* Change Password Modal */}
      <Modal
        show={showChangePasswordModal}
        onHide={() => setShowChangePasswordModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Ganti Password</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="oldPassword">
              <Form.Label>Password lama</Form.Label>
              <InputGroup>
                <Form.Control
                  type={isOldPasswordVisible ? "text" : "password"}
                  placeholder="masukkan password lama"
                  value={oldPassword}
                  onChange={(e) => setOldPassword(e.target.value)}
                />
                <Button
                  variant="outline-primary"
                  onClick={toggleOldPasswordVisibility}
                >
                  <FontAwesomeIcon
                    icon={isOldPasswordVisible ? faEyeSlash : faEye}
                  />
                </Button>
              </InputGroup>
            </Form.Group>
            <Form.Group className="mb-3" controlId="newPassword">
              <Form.Label>Password Baru</Form.Label>
              <InputGroup>
                <Form.Control
                  type={isNewPasswordVisible ? "text" : "password"}
                  placeholder="Masukkan password baru "
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                />
                <Button
                  variant="outline-primary"
                  onClick={toggleNewPasswordVisibility}
                >
                  <FontAwesomeIcon
                    icon={isNewPasswordVisible ? faEyeSlash : faEye}
                  />
                </Button>
              </InputGroup>
            </Form.Group>
            <Form.Group className="mb-3" controlId="confirmNewPassword">
              <Form.Label>Konfirmasi Password Baru</Form.Label>
              <InputGroup>
                <Form.Control
                  type={isConfirmPasswordVisible ? "text" : "password"}
                  placeholder="Masukkan konfirmasi password baru"
                  value={confirmNewPassword}
                  onChange={(e) => setConfirmNewPassword(e.target.value)}
                />
                <Button
                  variant="outline-primary"
                  onClick={toggleConfirmPasswordVisibility}
                >
                  <FontAwesomeIcon
                    icon={isConfirmPasswordVisible ? faEyeSlash : faEye}
                  />
                </Button>
              </InputGroup>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setShowChangePasswordModal(false)}
          >
            Batalkan
          </Button>
          <Button variant="primary" onClick={handleChangePassword}>
            Simpan Perubahan
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
