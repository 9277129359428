import React, { useEffect, useState, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import Datetime from "react-datetime";
import moment from "moment";

import {
  Col,
  Row,
  Card,
  Table,
  Form,
  InputGroup,
  Spinner,
  FormControl,
} from "@themesberg/react-bootstrap";
import reportSvc from "../services/reportSvc";

import { getLoginData } from "../services/authSvc";
import customerSvc from "../services/customerSvc";
import userSvc from "../services/userSvc";
import { customStyles } from "../utils/SelectUtils";
import Select from "react-select";
import NavTabs from "../components/NavTabs";
import { useHistory, useLocation } from "react-router-dom";
import LaporanInformationCard from "../components/LaporanInformationCard";
moment.tz.setDefault("Asia/Bangkok");

// this is just for production purpose
const pelanggan = {
  cilegon: 1,
  serang: 2,
  tangerang: 3,
  bekasi: 4,
  jatinegara: 5,
  rawamangun: 6,
  karawang: 7,
  testing: 8,
  daanmogot: 9,
  malang: 10,
  jakartapusat: 11,
  harmonipecenongan: 12,
  kramat: 13,
};
const { user } = getLoginData();
export default () => {
  const [isLoading, setIsLoading] = useState(false);
  const [startDate, setStartDate] = useState(
    moment().subtract(0, "days").format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState(moment().format("YYYY-MM-DD"));
  const [reportExports, setReportExport] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState("");
  const [selectedOperator, setSelectedOperator] = useState("");
  const [daysInRange, setDaysInRange] = useState();

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const query_pel = queryParams.get("cabang");
  const id_pelanggan = pelanggan[query_pel] ? pelanggan[query_pel] : null;
  // console.log({ id_pelanggan });
  const history = useHistory();

  const updateDaysInRange = () => {
    const start = moment(startDate);
    const end = moment(endDate);
    const days = end.diff(start, "days") + 1;
    setDaysInRange(days);
  };

  const fetchReportsExport = async () => {
    try {
      setIsLoading(true);
      const respdata = await reportSvc.getReportExportDailyNoAuth({
        params: {
          start_date: startDate,
          end_date: endDate,
          id_pelanggan: id_pelanggan,
        },
      });
      // console.log({ respdata });
      if (respdata.status === 200) {
        setReportExport(respdata.data.data);
      }

      setIsLoading(false);
    } catch (err) {}
  };

  useEffect(() => {
    updateDaysInRange();
  }, [startDate, endDate]);

  useEffect(() => {
    fetchReportsExport();
  }, []);

  const generateDateLabels = (startDate, daysInRange) => {
    const labels = [];
    for (let i = 0; i < daysInRange; i++) {
      const date = moment(startDate).add(i, "days");
      labels.push(date.format("D MMM"));
    }
    return labels;
  };

  return (
    <div className="align-items-center py-4 w-100">
      <Card border="light" className="shadow-sm " style={{ minWidth: "100%" }}>
        <Card.Header>
          <div>Laporan Cabang {queryParams.get("cabang")}</div>
        </Card.Header>
        <Card.Body style={{ overflowX: "auto", display: "visible" }}>
          {isLoading ? (
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ height: "300px" }}
            >
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </div>
          ) : id_pelanggan === null ? (
            <div>Tidak ditemukan</div>
          ) : (
            <>
              <LaporanInformationCard />
              <Table
                id="export-table"
                style={{
                  minWidth: "100%",
                  borderCollapse: "collapse",
                  border: "1px solid #ccc",
                }}
              >
                <colgroup>
                  <col style={{ borderRight: "1px solid #ccc" }} />
                  <col style={{ borderRight: "1px solid #ccc" }} />
                  {Array.from({ length: daysInRange * 3 }, (_, index) => (
                    <col
                      key={index}
                      style={{ borderRight: "1px solid #ccc" }}
                    />
                  ))}
                </colgroup>
                <thead>
                  <tr>
                    <th
                      style={{
                        padding: "8px",
                        textAlign: "center",
                        fontWeight: "bold",
                        borderBottom: "1px solid #ccc",
                        verticalAlign: "middle",
                      }}
                      colSpan={3}
                    >
                      INFORMASI ATM
                    </th>
                    <th
                      style={{
                        padding: "6px",
                        textAlign: "center",
                        fontWeight: "bold",
                        borderBottom: "1px solid #ccc",
                        borderRight: "1px solid #ccc",
                      }}
                      colSpan={daysInRange * 3}
                    >
                      TANGGAL
                    </th>
                  </tr>
                  <tr>
                    <th
                      style={{
                        padding: "8px",
                        textAlign: "center",
                        fontWeight: "bold",
                        borderBottom: "1px solid #ccc",
                        verticalAlign: "middle",
                      }}
                      rowSpan={2}
                    >
                      No
                    </th>
                    <th
                      style={{
                        padding: "8px",
                        textAlign: "center",
                        fontWeight: "bold",
                        borderBottom: "1px solid #ccc",
                        verticalAlign: "middle",
                        maxWidth: "40px",
                      }}
                      rowSpan={2}
                    >
                      ID ATM
                    </th>
                    <th
                      style={{
                        padding: "8px",
                        textAlign: "center",
                        fontWeight: "bold",
                        borderBottom: "1px solid #ccc",
                        verticalAlign: "middle",
                      }}
                      rowSpan={2}
                    >
                      NAMA ATM
                    </th>

                    {generateDateLabels(startDate, daysInRange).map(
                      (label, index) => (
                        <th
                          style={{
                            padding: "6px",
                            textAlign: "center",
                            borderBottom: "1px solid #ccc",
                            borderRight: "1px solid #ccc",
                          }}
                          key={index}
                          colSpan={3}
                        >
                          {label}
                        </th>
                      )
                    )}
                  </tr>
                  <tr>
                    {Array.from({ length: daysInRange }, (_, index) => (
                      <React.Fragment key={index}>
                        <th
                          style={{
                            padding: "6px",
                            textAlign: "center",
                            borderBottom: "1px solid #ccc",
                            borderRight: "1px solid #ccc",
                          }}
                        >
                          1
                        </th>
                        <th
                          style={{
                            padding: "6px",
                            textAlign: "center",
                            borderBottom: "1px solid #ccc",
                            borderRight: "1px solid #ccc",
                          }}
                        >
                          2
                        </th>
                        <th
                          style={{
                            padding: "6px",
                            textAlign: "center",
                            borderBottom: "1px solid #ccc",
                            borderRight: "1px solid #ccc",
                          }}
                        >
                          3
                        </th>
                      </React.Fragment>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {reportExports.map((item, index) => {
                    const { asset, laporans } = item;
                    const { kode, pelanggan, name, ring, just_on_holiday } =
                      asset;

                    return (
                      <tr key={index}>
                        {/* Asset details */}
                        <td
                          style={{
                            padding: "8px",
                            borderBottom: "1px solid #ccc",
                            borderRight: "1px solid #ccc",
                            whiteSpace: "wrap",
                          }}
                        >
                          {index + 1}
                        </td>
                        <td
                          style={{
                            padding: "8px",
                            borderBottom: "1px solid #ccc",
                            borderRight: "1px solid #ccc",
                            whiteSpace: "wrap",
                          }}
                        >
                          {kode.replaceAll(",", " ")}
                        </td>
                        <td
                          style={{
                            padding: "8px",
                            borderBottom: "1px solid #ccc",
                            borderRight: "1px solid #ccc",
                            whiteSpace: "wrap",
                          }}
                        >
                          {name}
                        </td>

                        {/* Loop through each day in the range */}
                        {Array.from({ length: daysInRange }, (_, dayIndex) => {
                          const date = moment(startDate).add(dayIndex, "days");
                          const dateString = date.format("YYYY-MM-DD");
                          const dataLaporan = laporans[dateString] || [];

                          // Render cells for each kunjungan in a day
                          const cells = Array.from(
                            { length: 3 },
                            (_, laporanIndex) => {
                              let backgroundColor = "#FF0000";
                              if (!laporans[dateString] && just_on_holiday)
                                backgroundColor = "#FFFF00";
                              if (laporanIndex >= parseInt(ring))
                                backgroundColor = "#000000";

                              const laporan = dataLaporan[laporanIndex];

                              if (laporan && laporan.is_temuan !== undefined) {
                                backgroundColor = laporan.is_temuan
                                  ? "#0070C0"
                                  : "#92D050";
                              }

                              return (
                                <td
                                  key={laporanIndex}
                                  style={{
                                    padding: "8px",
                                    backgroundColor,
                                    color: "#000000",
                                    fontSize: "10px",
                                    border: " solid #ccc",
                                  }}
                                >
                                  {laporan && laporan.id_laporan ? (
                                    <a
                                    // href={`report-detail/${laporan.id_laporan}`}
                                    // onClick={(e) => {
                                    //   if (user) {
                                    //     history.push(
                                    //       `report-detail/${laporan.id_laporan}`
                                    //     );
                                    //   }
                                    // }}
                                    // target="_blank"
                                    // rel="noopener noreferrer"
                                    // style={{
                                    //   color: "#000000",
                                    //   fontSize: "10px",
                                    //   cursor: "pointer",
                                    // }}
                                    >
                                      {laporan.date_done
                                        ? moment
                                            .utc(laporan.date_done)
                                            .format("HH:mm")
                                        : ""}
                                    </a>
                                  ) : null}
                                </td>
                              );
                            }
                          );

                          return cells;
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </>
          )}
        </Card.Body>
      </Card>
    </div>
  );
};
