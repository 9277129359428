import React, { useEffect, useState } from "react";
import { Descriptions, Image, Button, Card, Table, Modal, Tooltip } from "antd";
import { useParams, useHistory } from "react-router-dom";
import moment from "moment";
import assetSvc from "../../services/assetSvc";
import { showErrorAlert } from "../../utils/SwalUtil";
import SpinnerLoading from "../../components/loading/SpinnerLoading";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  ColumnLaporan,
  ColumnTransaction,
} from "../../template/TemplateColumn";
import reportTransactionSvc from "../../services/reportTransactionSvc";
import MapComponent from "../../components/MapContainer";
import { faPen } from "@fortawesome/free-solid-svg-icons";
import { getLoginData } from "../../services/authSvc";
import reportSvc from "../../services/reportSvc";

const { user } = getLoginData();
const role = user?.role;
const DetailAsset = () => {
  const { id } = useParams();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);
  const [asset, setAsset] = useState([]);
  const [reportTransaction, setReportTransaction] = useState([]);
  const [report, setReport] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const fetchDetailAsset = async () => {
    try {
      setIsLoading(true);
      const resp = await assetSvc.getAssetById(id);

      if (resp.status === 200) {
        setAsset(resp.data.data);
      }
    } catch (error) {
      showErrorAlert("Gagal", "Gagal mengambil data asset");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchDetailAsset();
    fetchReportTransaction();
    fetchReports();
  }, [id]);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const items = [
    {
      key: "1",
      label: "Nama Aset",
      children: asset?.name || "-",
    },
    {
      key: "2",
      label: "Kode Aset",
      children: asset?.kode || "-",
    },
    {
      key: "3",
      label: "Alamat",
      children: asset?.alamat || "-",
    },
    {
      key: "4",
      label: "Koordinat",
      children: `${asset?.longitude || "-"}, ${asset?.latitude || "-"}`,
    },
    {
      key: "map",
      label: "Lokasi Map",
      children: (
        <Button type="primary" onClick={() => handleOpenModal(asset)}>
          Lihat Map
        </Button>
      ),
    },
    {
      key: "5",
      label: "Ring",
      children: asset?.ring || "-",
    },
    {
      key: "6",
      label: "Status",
      children: asset?.active ? "Aktif" : "Nonaktif",
    },
    {
      key: "7",
      label: "Kepemilikan",
      children: asset?.pelanggan ? (
        <div style={{ display: "flex", alignItems: "center" }}>
          {asset.pelanggan.photo_url ? (
            <Image
              src={asset.pelanggan.photo_url}
              alt="profile-pict"
              width={40}
              height={40}
              style={{ borderRadius: "50%", marginRight: "10px" }}
            />
          ) : (
            <div
              style={{
                width: "40px",
                height: "40px",
                borderRadius: "50%",
                backgroundColor: "orange",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginRight: "10px",
              }}
            >
              <span
                style={{ color: "#fff", fontSize: "16px", fontWeight: "bold" }}
              >
                {asset.pelanggan.name ? asset.pelanggan.name.charAt(0) : ""}
              </span>
            </div>
          )}
          <span className="fw-bold">{asset.pelanggan.name || "-"}</span>
        </div>
      ) : (
        "-"
      ),
    },
    {
      key: "8",
      label: "Tanggal Dibuat",
      children: moment(asset?.createdAt).format("YYYY-MM-DD HH:mm:ss") || "-",
    },
    {
      key: "9",
      label: "Tanggal Diperbarui",
      children: moment(asset?.updatedAt).format("YYYY-MM-DD HH:mm:ss") || "-",
    },
  ];

  const fetchReportTransaction = async () => {
    setIsLoading(true);
    const resp = await reportTransactionSvc.getReportTransactions({
      params: {
        page: 0,
        limit: 1000,
        start_date: moment().startOf("month").format("YYYY-MM-DD"),
        end_date: moment().endOf("month").format("YYYY-MM-DD"),
        id_asset: id,
      },
    });
    if (resp.status === 200) {
      setReportTransaction(resp.data.data);
    }

    setIsLoading(false);
  };

  const fetchReports = async () => {
    try {
      setIsLoading(true);
      const params = {
        page: 0,
        limit: 100,
        start_date: moment().subtract(6, "months").format("YYYY-MM-DD"),
        end_date: moment().format("YYYY-MM-DD"),
        id_asset: id,
      };

      const resp = await reportSvc.getReports({
        params: { ...params },
      });
      if (resp.status === 200) {
        setReport(resp.data.data);
      }
    } catch (error) {
      let message =
        error.response?.data?.message || "Gagal mengambil data report";
      showErrorAlert("Gagal", message);
    } finally {
      setIsLoading(false);
    }
  };

  const columnTransaction = ColumnTransaction(history, "user", null, null);
  const paginationTransaction = {
    showTotal: (total, range) =>
      `Showing ${range[0]}-${range[1]} of ${total} items`,
    pageSize: 5,
    total: reportTransaction.length,
    pageSizeOptions: [5, 10, 20],
    showSizeChanger: true,
    position: ["bottomRight"],
    className: "custom-pagination",
  };

  const goToDetailLaporan = (id) => {
    history.push(`/report-detail/${id}`);
  };

  const columnLaporan = ColumnLaporan(goToDetailLaporan);
  const paginationLaporan = {
    showTotal: (total, range) =>
      `Showing ${range[0]}-${range[1]} of ${total} items`,
    pageSize: 5,
    total: report.length,
    pageSizeOptions: [5, 10, 20],
    showSizeChanger: true,
    position: ["bottomRight"],
    className: "custom-pagination",
  };

  const AssetModal = ({ isModalOpen, handleOk, asset }) => {
    return (
      <Modal
        title="Map ATM"
        open={isModalOpen}
        closable={false}
        footer={
          <Button type="primary" onClick={handleOk}>
            OK
          </Button>
        }
        onCancel={handleOk} // Add onCancel to close the modal
      >
        <div
          style={{
            height: "25rem",
          }}
        >
          <MapComponent assets={[asset]} />
        </div>
      </Modal>
    );
  };

  const handleEditAsset = async (id) => {
    history.push(`/asset/update/${id}`);
  };

  return (
    <div className="align-items-center py-4 w-100 d-flex flex-column gap-2">
      <Card
        title={
          <span
            style={{
              fontSize: "20px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <span>Detail ATM</span>
          </span>
        }
        extra={
          <div className="d-flex">
            {role === "admin" && (
              <Tooltip title={"Edit ATM"}>
                <Button
                  variant="danger"
                  size="sm"
                  onClick={() => {
                    handleEditAsset(id);
                  }}
                >
                  <FontAwesomeIcon
                    icon={faPen}
                    style={{ fontSize: "14px", paddingRight: "10px" }}
                  />
                  Edit
                </Button>
              </Tooltip>
            )}
          </div>
        }
        style={{ width: "100%" }}
      >
        {isLoading ? (
          <SpinnerLoading height="h-40" />
        ) : (
          <Descriptions layout="vertical" items={items} />
        )}
      </Card>

      <Card
        title={
          <span
            style={{
              fontSize: "20px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <span>100 Laporan Terakhir</span>
          </span>
        }
        extra={<div className="d-flex"></div>}
        style={{ width: "100%" }}
      >
        <Table
          columns={columnLaporan}
          dataSource={report}
          pagination={paginationLaporan}
          loading={isLoading}
          size="middle"
          style={{ overflow: "auto" }}
        ></Table>
      </Card>

      <Card
        title={
          <span
            style={{
              fontSize: "20px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <span>Transaksi ATM (bulan ini)</span>
          </span>
        }
        extra={<div className="d-flex"></div>}
        style={{ width: "100%" }}
      >
        <Table
          columns={columnTransaction}
          dataSource={reportTransaction}
          pagination={paginationTransaction}
          loading={isLoading}
          size="middle"
          style={{ overflow: "auto" }}
        ></Table>
      </Card>

      <AssetModal isModalOpen={isModalOpen} handleOk={handleOk} asset={asset} />
    </div>
  );
};

export default DetailAsset;
