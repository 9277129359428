import moment from "moment";
import { Image } from "antd";
import LazyLoad from "react-lazyload";
moment.tz.setDefault("Asia/Bangkok");

export default function HistoryReportExportByAsset({ reportExports }) {
  console.log({ reportExports });
  return (
    <>
      <div className="text-center w-full bg-white pb-4 pt-6">
        <h2>Laporan ATM {reportExports.asset.name}</h2>
        <span>
          {moment.utc(reportExports.startDate).format("D MMMM YYYY")} -{" "}
          {moment.utc(reportExports.endDate).format("D MMMM YYYY")}
        </span>
      </div>
      <div className="bg-white px-5 py-2">
        <div className="border border-black mb-4" style={{ width: "100%" }}>
          {" "}
          <div className="border px-4 py-1">
            <div className="row">
              <div className="row col-md-4">
                <span
                  style={{
                    fontWeight: "bold",
                    fontSize: "16px",
                  }}
                >
                  Nama ATM
                </span>
                <span> {reportExports.asset.name}</span>
              </div>

              <div className="row col">
                <span
                  style={{
                    fontWeight: "bold",
                    fontSize: "16px",
                  }}
                >
                  Kode ATM
                </span>
                <span>{reportExports.asset.kode}</span>
              </div>
            </div>
          </div>
          <div className="border px-4 py-1">
            <div className="row">
              <div className="row col-md-4">
                <span
                  style={{
                    fontWeight: "bold",
                    fontSize: "16px",
                  }}
                >
                  Kepemilikan
                </span>
                <span> {reportExports.asset.pelanggan.name}</span>
              </div>

              <div className="row col">
                <span
                  style={{
                    fontWeight: "bold",
                    fontSize: "16px",
                  }}
                >
                  Alamat ATM
                </span>
                <span>{reportExports.asset.alamat}</span>
              </div>
            </div>
          </div>
          <div className="border px-4 py-1">
            <div className="row">
              <div className="row col-md-4">
                <span
                  style={{
                    fontWeight: "bold",
                    fontSize: "16px",
                  }}
                >
                  Ring
                </span>
                <span> {reportExports.asset.ring}</span>
              </div>

              <div className="row col">
                <span
                  style={{
                    fontWeight: "bold",
                    fontSize: "16px",
                  }}
                >
                  Lokasi
                </span>
                <span>
                  {reportExports.asset.latitude} ,{" "}
                  {reportExports.asset.longitude}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-white px-5 py-4">
        {Object.keys(reportExports.laporan).map((lap) => (
          <>
            {reportExports.laporan[lap].map((x, index) => (
              <div
                className="border border-black mb-4"
                style={{ width: "100%" }}
                key={index}
              >
                <div className="border border-bottom px-4 py-1">
                  <div className="row">
                    <div className="row col">
                      <span
                        style={{
                          fontWeight: "bold",
                          fontSize: "16px",
                        }}
                      >
                        Waktu
                      </span>
                      <span> {moment(x.date).format("D MMMM YYYY")}</span>
                    </div>

                    <div className="row col">
                      <span
                        style={{
                          fontWeight: "bold",
                          fontSize: "16px",
                        }}
                      >
                        Status
                      </span>
                      <span>
                        {" "}
                        {x.is_temuan ? "Ada Temuan" : "Tidak Ada Temuan"}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="border border-bottom px-4 py-1">
                  <div className="row">
                    <div className="row col">
                      <span
                        className="mb-1"
                        style={{
                          fontWeight: "bold",
                          fontSize: "16px",
                        }}
                      >
                        Kunjungan {x.kunjungan}
                      </span>
                      <span>
                        Waktu Selesai:{" "}
                        {moment.utc(x.date_done).format("D MMMM YYYY")}
                        {" - "}
                        {moment.utc(x.date_done).format("HH:mm:ss")}
                      </span>
                    </div>
                    <div className="row col">
                      <span
                        style={{
                          fontWeight: "bold",
                          fontSize: "16px",
                        }}
                      >
                        Operator Pengerjaan
                      </span>
                      <span>{x.user_name}</span>
                    </div>
                  </div>
                </div>
                <div className="border border-bottom px-4 py-1">
                  <span
                    className="mb-2"
                    style={{ fontWeight: "bold", fontSize: "16px" }}
                  >
                    Hasil Dokumentasi Pekerjaan Operator
                  </span>
                  <div className="row">
                    {x.dokumentasis.map((img, index) => (
                      <div className="col-md-3 p-2" key={index}>
                        <div className="">
                          <LazyLoad once>
                            {" "}
                            <Image
                              loading="lazy"
                              src={img.dokumentasi_url}
                              style={{ width: "100%" }}
                              className="rounded"
                              alt={img.nama_jenis_dokumentasi_snapshot}
                            />
                          </LazyLoad>

                          <div className="figure-caption text-dark">
                            {img.nama_jenis_dokumentasi_snapshot}
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="border border-bottom px-4 py-1">
                  <div className="py-1">
                    <span
                      className="mb-2"
                      style={{
                        fontWeight: "bold",
                        fontSize: "16px",
                      }}
                    >
                      Temuan Operator
                    </span>
                  </div>
                  <div className="row ">
                    <div className="row col">
                      <div className="py-1">
                        <span
                          className="mb-2"
                          style={{
                            fontWeight: "bold",
                            fontSize: "16px",
                          }}
                        >
                          Keterangan:{" "}
                        </span>
                        <span>
                          {x?.temuan?.keterangan ? x.temuan.keterangan : "-"}
                        </span>
                      </div>
                      {x.temuan ? (
                        <div className="row py-2">
                          {x.temuan.temuan1_url && (
                            <div className="col-md-3 mb-3">
                              <div className="">
                                <LazyLoad once>
                                  <Image
                                    loading="lazy"
                                    src={x.temuan.temuan1_url}
                                    style={{ width: "100%" }}
                                    className="rounded"
                                    alt="Temuan 1"
                                  />
                                </LazyLoad>

                                <div className="figure-caption text-dark">
                                  Temuan 1
                                </div>
                              </div>
                            </div>
                          )}
                          {x.temuan.temuan2_url && (
                            <div className="col-md-3 mb-3">
                              <div className="">
                                <LazyLoad once>
                                  {" "}
                                  <Image
                                    loading="lazy"
                                    src={x.temuan.temuan2_url}
                                    style={{ width: "100%" }}
                                    className="rounded"
                                    alt="Temuan 2"
                                  />
                                </LazyLoad>

                                <div className="figure-caption text-dark">
                                  Temuan 2
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </>
        ))}
      </div>
    </>
  );
}
