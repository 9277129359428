import React, { useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import Datetime from "react-datetime";
import moment from "moment-timezone";

import {
  Col,
  Row,
  Button,
  Card,
  Table,
  Form,
  InputGroup,
  Pagination,
  Spinner,
  Nav,
  FormControl,
  Image,
} from "@themesberg/react-bootstrap";
import Select from "react-select";
import reportSvc from "../../services/reportSvc";
import userSvc from "../../services/userSvc";
import customerSvc from "../../services/customerSvc";
import assetSvc from "../../services/assetSvc";
import { showErrorAlert } from "../../utils/SwalUtil";
import { customStyles } from "../../utils/SelectUtils";
import { getLoginData } from "../../services/authSvc";
import { useHistory } from "react-router-dom";
import NavTabs from "../../components/NavTabs";
moment.tz.setDefault("Asia/Bangkok");

export default () => {
  const searchInputRef = useRef(null);
  const [reports, setReports] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingFilter, setIsLoadingFilter] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [startDate, setStartDate] = useState(
    moment().subtract(3, "days").format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState(moment().format("YYYY-MM-DD"));
  const [customers, setCustomers] = useState([]);
  const [users, setUsers] = useState([]);
  const [assets, setAssets] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState("");
  const [selectedUser, setSelectedUser] = useState("");
  const [selectedAsset, setSelectedAsset] = useState("");
  const [selectedTemuan, setSelectedTemuan] = useState("");
  const [totalRows, setTotalRows] = useState("");
  const { user } = getLoginData();
  const history = useHistory();
  const handleStartDateChange = (date) => {
    const formattedDate = moment(date).format("YYYY-MM-DD");
    setStartDate(formattedDate);
    if (moment(formattedDate).isAfter(endDate)) {
      setEndDate(formattedDate);
    }
  };

  const handleEndDateChange = (date) => {
    const formattedDate = moment(date).format("YYYY-MM-DD");
    setEndDate(formattedDate);
    if (moment(formattedDate).isBefore(startDate)) {
      setStartDate(formattedDate);
    }
  };

  const fetchReports = async () => {
    try {
      setIsLoading(true);
      const params = {
        page: 0,
        limit: 1000,
        query: searchTerm,
        start_date: startDate,
        end_date: endDate,
        is_temuan: selectedTemuan,
      };

      const resp = await reportSvc.getReports({
        params: { ...params },
      });
      if (resp.status === 200) {
        setReports(resp.data.data);
      }
    } catch (error) {
      let message =
        error.response?.data?.message || "Gagal mengambil data report";
      showErrorAlert("Gagal", message);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchReports();
  }, [startDate, endDate]);

  const isFirstRender = useRef(true);
  useEffect(() => {
    if (!isFirstRender.current) {
      let timerId;

      const delayedFetch = () => {
        clearTimeout(timerId);
        timerId = setTimeout(() => {
          fetchReports();
        }, 500);
      };

      delayedFetch();

      return () => {
        clearTimeout(timerId);
      };
    } else {
      isFirstRender.current = false;
    }
  }, [searchTerm]);

  const customPaddingStyle = {
    padding: "0.75rem 0.5rem",
  };

  const handleTabChange = () => {
    history.push("/");
  };

  return (
    <div
      className="align-items-center py-4 w-100"
      // tabIndex={0}
      // onKeyDown={handleKeyPress}
    >
      <Card border="light" className="shadow-sm">
        <Card.Header className="pb-0">
          <NavTabs
            activeTab={"/operator/laporan/view"}
            onTabChange={handleTabChange}
            sideTab={"operator"}
          />
        </Card.Header>
        <Card.Body className="">
          <Row className="align-items-center">
            <Form>
              <div className="mb-4">
                <Form.Group id="range">
                  <Form.Label className="text-primary fs-6">
                    Pilih rentang tanggal
                  </Form.Label>
                  <Row style={{ width: "100%" }}>
                    <Col>
                      <Form.Label>Tanggal dimulai</Form.Label>
                      <Datetime
                        timeFormat={false}
                        onChange={handleStartDateChange}
                        renderInput={(props, openCalendar) => (
                          <InputGroup className="me-2">
                            <InputGroup.Text>
                              <FontAwesomeIcon icon={faCalendarAlt} />
                            </InputGroup.Text>
                            <Form.Control
                              required
                              type="text"
                              value={
                                startDate
                                  ? moment(startDate).format("YYYY-MM-DD")
                                  : ""
                              }
                              placeholder="YYYY-MM-DD"
                              onFocus={openCalendar}
                              onChange={() => {}}
                            />
                          </InputGroup>
                        )}
                      />
                    </Col>
                    <Col>
                      <Form.Label>Tanggal berakhir</Form.Label>
                      <Datetime
                        timeFormat={false}
                        onChange={handleEndDateChange}
                        renderInput={(props, openCalendar) => (
                          <InputGroup className="me-2">
                            <InputGroup.Text>
                              <FontAwesomeIcon icon={faCalendarAlt} />
                            </InputGroup.Text>
                            <Form.Control
                              required
                              type="text"
                              value={
                                endDate
                                  ? moment(endDate).format("YYYY-MM-DD")
                                  : ""
                              }
                              placeholder="YYYY-MM-DD"
                              onFocus={openCalendar}
                              onChange={() => {}}
                            />
                          </InputGroup>
                        )}
                      />
                    </Col>
                  </Row>
                </Form.Group>
              </div>
            </Form>
          </Row>
          <div className="mb-3">
            <Row
              style={{
                width: "100%",
                justifyContent: "space-between",
                margin: "0 3px",
              }}
            >
              <Col
                style={{
                  minWidth: "125px",
                  padding: "0 3px 0 0",
                }}
              >
                <FormControl
                  type="text"
                  placeholder="Cari operator, ATM / kode ATM"
                  className="me-1"
                  style={{ height: "100%" }}
                  ref={searchInputRef}
                  value={searchTerm}
                  onChange={(e) => {
                    setSearchTerm(e.target.value);
                  }}
                />
              </Col>
            </Row>
          </div>

          <Table
            responsive
            className="table-centered table-nowrap rounded mb-0"
          >
            <thead className="thead-light">
              <tr>
                <th
                  className="border-0 items-center text-center justify-center"
                  colSpan={3}
                  style={customPaddingStyle}
                >
                  LAPORAN
                </th>
              </tr>
            </thead>
            {isLoading ? (
              <tr>
                <td
                  colSpan={6}
                  className="text-center"
                  style={{ height: "300px" }}
                >
                  <output>
                    <Spinner animation="border">
                      <span className="visually-hidden">Loading...</span>
                    </Spinner>
                  </output>
                </td>
              </tr>
            ) : reports?.length > 0 ? (
              <tbody>
                {reports.map((report) => (
                  <tr key={report.id_laporan} className="border-1">
                    <a
                      href={`/report-detail/${report.id_laporan}`}
                      rel="noopener noreferrer"
                      className="border-1 h-full w-full d-block cursor-pointer"
                      style={{
                        color: "black",
                        whiteSpace: "pre-line",
                        transition: "background-color 0.3s", // Add a transition for a smooth effect
                        display: "flex", // Use flex display for flexible layout
                        justifyContent: "space-between", // Space elements evenly
                        // alignItems: "center",
                      }}
                      onMouseOver={(e) => {
                        e.currentTarget.style.backgroundColor = "#f8fafc";
                      }}
                      onMouseOut={(e) => {
                        e.currentTarget.style.backgroundColor = "";
                      }}
                    >
                      <td
                        className="border-0 text-left w-3/10 pl-4" // Adjusted to align left and add padding
                        style={customPaddingStyle}
                      >
                        <span className="fw-bolder">
                          {report.is_temuan ? (
                            <div className="bg-danger text-white p-1 text-center">
                              K-{report.kunjungan}
                            </div>
                          ) : (
                            <div className="text-center p-1">
                              K-{report.kunjungan}
                            </div>
                          )}
                        </span>
                      </td>
                      <td
                        className="border-0 w-4/10"
                        style={{ ...customPaddingStyle, whiteSpace: "wrap" }}
                      >
                        <span className="fw-bolder">{report.asset_name}</span>
                        <br />
                        {report.asset_kode}
                      </td>
                      <td
                        className="border-0 text-right w-3/10 pr-4" // Adjusted to align right and add padding
                        style={customPaddingStyle}
                      >
                        <span className="fw-bolder">
                          <span>
                            {moment.utc(report.date_done).format("YYYY-MM-DD")}
                          </span>
                          <br />
                          {moment.utc(report.date_done).format("HH:mm:ss")}
                        </span>
                      </td>
                    </a>
                  </tr>
                ))}
              </tbody>
            ) : (
              <tr>
                <td
                  colSpan={5}
                  className="text-center"
                  style={{ height: "200px" }}
                >
                  <span className="text-muted">Tidak ada laporan</span>
                </td>
              </tr>
            )}
          </Table>
        </Card.Body>
      </Card>
    </div>
  );
};
