import { api, handleApiResponse } from "./api";

export default {
  getImageBuffer(src) {
    return api
      .get("/image", {
        params: { url: src },
        responseType: "arraybuffer", // Ensure binary data is handled correctly
      })
      .catch(handleApiResponse);
  },
};
