import React, { useState, useEffect } from "react";
import {
  Button,
  Form,
  InputGroup,
  Spinner,
  Card,
  Container,
  Image,
} from "@themesberg/react-bootstrap";
import Swal from "sweetalert2";
import { useParams } from "react-router-dom";
import EmrSvc from "../services/EmrSvc";
import GmeLogo from "../assets/img/icons/gme-icon.svg";
import docTypeSvc from "../services/docTypeSvc";
import imageCompression from "browser-image-compression";
import {
  showErrorAlert,
  showLoadingAlert,
  showSuccessAlert,
} from "../utils/SwalUtil";
import moment from "moment";

export default () => {
  const [isValid, setIsValid] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [temuanFiles, setTemuanFiles] = useState([]);
  const [isTemuan, setIsTemuan] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [keterangan, setKeterangan] = useState("");
  const [asset, setAsset] = useState([]);
  const [user, setUser] = useState([]);
  const [idUser, setIdUser] = useState(null);
  const [idAsset, setIdAsset] = useState(null);
  const [docType, setDocType] = useState([]);
  const [date, setDate] = useState(null);
  const [upload, setUpload] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const { token } = useParams();

  const fetchDocType = async () => {
    setIsLoading(true);

    try {
      const resp = await docTypeSvc.getJenisDokumentasiEmr({
        params: {
          active: true,
        },
      });
      if (resp.status === 200) {
        setDocType(resp.data.data);
      }
    } catch (error) {}
  };

  const validateToken = async () => {
    setIsLoading(true);
    const data = {
      token: token,
    };
    try {
      const resp = await EmrSvc.validateEmr(data);
      if (resp.status === 200) {
        setIsValid(true);
        setDate(resp.data.data.date);
        if (resp.data.data.id_user) {
          setIdUser(resp.data.data.id_user);
          setUser(resp.data.data.user);
        }
        if (resp.data.data.id_asset) {
          setIdAsset(resp.data.data.id_asset);
          setAsset(resp.data.data.asset);
        }
      } else {
        setIsValid(false);
      }
    } catch (error) {}
  };

  const resizeAndCompressImage = async (file) => {
    const options = {
      maxSizeMB: 0.7,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
      fileType: "image/jpeg",
    };

    try {
      const compressedBlob = await imageCompression(file, options);
      const timestamp = Date.now();
      const uniqueFileName = `${timestamp}_${file.name}`;
      const compressedFile = new File([compressedBlob], uniqueFileName, {
        type: "image/jpeg",
        lastModified: timestamp,
      });
      return compressedFile;
    } catch (error) {
      throw new Error("Gagal melakukan kompresi gambar");
    }
  };

  const handleReportFileUpload = async (files, index) => {
    try {
      showLoadingAlert("Tunggu", "Sedang mengompresi ukuran gambar...");

      const selectedFile = files[0];

      const resizedImage = await resizeAndCompressImage(selectedFile);

      const fileData = {
        [index]: resizedImage,
      };

      setUploadedFiles((prevUploadedFiles) => ({
        ...prevUploadedFiles,
        ...fileData,
      }));
    } catch (error) {
      showErrorAlert("Gagal", "Ukuran gambar gagal dikompres");
    } finally {
      Swal.close();
    }
  };

  useEffect(() => {
    // Check if all file inputs are filled
    const isAllFilesFilled = docType.every(
      (doc) => uploadedFiles[doc.id_jenis_dokumentasi]
    );

    // Update the form validation state
    setIsFormValid(isAllFilesFilled);
  }, [uploadedFiles, docType]);

  const handleTemuanFileUpload = async (files, index) => {
    try {
      showLoadingAlert("Tunggu", "Sedang mengompresi ukuran gambar...");

      const selectedFile = files[0];
      const resizedImage = await resizeAndCompressImage(selectedFile);

      const propertyName = `temuan${index + 1}_url`;

      const fileData = {
        [propertyName]: resizedImage,
      };

      setTemuanFiles((prevUploadedFiles) => ({
        ...prevUploadedFiles,
        ...fileData,
      }));
    } catch (error) {
      showErrorAlert("Gagal", "Ukuran gambar gagal dikompres");
    } finally {
      Swal.close();
    }
  };

  useEffect(() => {
    fetchDocType();
    validateToken();

    setIsLoading(false);
  }, []);

  const handleSave = async () => {
    // Show SweetAlert confirmation dialog
    const result = await Swal.fire({
      title: "Konfirmasi Upload",
      text: "Apa anda yakin untuk mengupload laporan ini?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    });

    // Proceed with adding the report if confirmed
    if (result) {
      const formData = new FormData();
      for (const key in uploadedFiles) {
        if (uploadedFiles.hasOwnProperty(key)) {
          const fileData = uploadedFiles[key];
          formData.append(key, fileData);
        }
      }

      for (const key in temuanFiles) {
        if (temuanFiles.hasOwnProperty(key)) {
          const fileData = temuanFiles[key];
          formData.append(key, fileData);
        }
      }

      formData.append("token", token);
      formData.append("id_asset", idAsset);
      formData.append("id_user", idUser);
      formData.append("keterangan", keterangan);
      formData.append("is_temuan", isTemuan);

      try {
        showLoadingOverlay();
        const resp = await EmrSvc.addReportEmr(formData);
        hideLoadingOverlay();

        if (resp.status === 200) {
          showSuccessAlert(
            "Berhasil",
            `Laporan untuk ${resp?.data?.laporan?.asset_name} berhasil dikirim`
          );
        } else {
          let message =
            resp?.response?.data?.message || "Gagal mengirim laporan";
          showErrorAlert("Gagal", message);
        }
      } catch (error) {
        let message = error.response?.data?.message || "Gagal mengirim laporan";
        showErrorAlert("Gagal", message);
      }
    }
  };

  const showLoadingOverlay = () => {
    const loadingOverlay = document.getElementById("root");

    if (loadingOverlay) {
      loadingOverlay.style.display = "none";
    }
    const load = document.getElementById("loading-overlay");
    if (load) {
      load.style.display = "block";
    }
  };

  const hideLoadingOverlay = () => {
    const loadingOverlay = document.getElementById("root");
    if (loadingOverlay) {
      loadingOverlay.style.display = "block";
    }
    const load = document.getElementById("loading-overlay");
    if (load) {
      load.style.display = "none";
    }
  };

  return (
    <>
      <Container>
        <div className="align-items-center py-4 w-100">
          {isValid ? (
            <Card border="light" className="shadow-sm">
              <Card.Header>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <a href="/">
                    <Image
                      src={GmeLogo}
                      alt="profile-pict"
                      style={{
                        borderRadius: "50%",
                        marginRight: "8px",
                      }}
                    />
                    <div>
                      <h2>GME - EMR Tools</h2>
                    </div>
                  </a>
                </div>
              </Card.Header>
              <Card.Body className="pb-0">
                {isLoading ? (
                  <div
                    className="d-flex justify-content-center align-items-center"
                    style={{ height: "300px" }}
                  >
                    <Spinner animation="border" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </Spinner>
                  </div>
                ) : (
                  <Form
                    onSubmit={(e) => {
                      e.preventDefault();
                      handleSave();
                    }}
                  >
                    <div className="report-info">
                      <div className="mb-3">
                        <span className="fw-bold">Laporan untuk ATM : </span>
                        <span>{asset.name}</span>
                      </div>
                      <div className="mb-3">
                        <span className="fw-bold">Oleh Operator : </span>
                        <span>{user.name}</span>
                      </div>
                      <div className="mb-3">
                        <span className="fw-bold">Tanggal : </span>
                        <span>{moment().format("YYYY-MM-DD")}</span>
                      </div>
                    </div>

                    <Form.Group className="mb-3">
                      <Form.Label>Apakah data sudah benar?</Form.Label>
                      <InputGroup>
                        <Form.Select
                          id="state"
                          defaultValue={true}
                          value={upload}
                          onChange={(e) => {
                            setUpload(e.target.value);
                          }}
                        >
                          <option value={true}>Iya</option>
                          <option value={false}>Tidak</option>
                        </Form.Select>
                      </InputGroup>
                    </Form.Group>

                    {upload === "true" && (
                      <>
                        <Form.Group className="mb-3">
                          <Form.Label>Upload Foto Laporan</Form.Label>
                          <br />

                          {docType.length > 0 ? (
                            docType.map((doc) => (
                              <React.Fragment key={doc.id_jenis_dokumentasi}>
                                <span className="mb-2 mt-1">
                                  {doc.nama_jenis_dokumentasi}
                                </span>
                                <InputGroup className="mt-1 mb-1">
                                  <Form.Control
                                    type="file"
                                    accept="image/*"
                                    // capture="environment"
                                    onChange={(e) =>
                                      handleReportFileUpload(
                                        e.target.files,
                                        doc.id_jenis_dokumentasi
                                      )
                                    }
                                    required // Added the required attribute
                                  />
                                </InputGroup>
                              </React.Fragment>
                            ))
                          ) : (
                            <div>
                              <p>Gagal mendapatkan jenis dokumentasi</p>
                            </div>
                          )}
                        </Form.Group>
                        <Form.Group className="mb-3">
                          <Form.Label>Apakah ada temuan?</Form.Label>
                          <InputGroup>
                            <Form.Select
                              id="state"
                              value={isTemuan}
                              onChange={(e) => {
                                setIsTemuan(e.target.value);
                              }}
                            >
                              <option value={true}>Iya</option>
                              <option value={false}>Tidak</option>
                            </Form.Select>
                          </InputGroup>
                        </Form.Group>

                        {isTemuan === "true" && (
                          <>
                            <Form.Group className="mb-3">
                              <Form.Label>Keterangan</Form.Label>
                              <InputGroup>
                                <Form.Control
                                  required
                                  type="text"
                                  placeholder="Enter keterangan"
                                  value={keterangan}
                                  onChange={(e) =>
                                    setKeterangan(e.target.value)
                                  }
                                />
                              </InputGroup>
                            </Form.Group>
                            <Form.Group className="mb-3">
                              <Form.Label>
                                Upload Foto Temuan (Max 2)
                              </Form.Label>
                              {Array.from(Array(2).keys()).map((index) => (
                                <InputGroup key={index} className="mb-2">
                                  <Form.Control
                                    type="file"
                                    accept="image/*"
                                    onChange={(e) =>
                                      handleTemuanFileUpload(
                                        e.target.files,
                                        index
                                      )
                                    }
                                  />
                                </InputGroup>
                              ))}
                            </Form.Group>
                          </>
                        )}
                      </>
                    )}

                    <Card.Footer className="px-0 d-lg-flex align-items-center justify-content-between">
                      <Button
                        type="submit"
                        variant="primary"
                        // onClick={(e) => {
                        //   e.preventDefault();
                        //   handleSave();
                        // }}
                        disabled={!isFormValid} // Disable the button if the form is not valid
                      >
                        Kirim
                      </Button>
                    </Card.Footer>
                  </Form>
                )}
              </Card.Body>
            </Card>
          ) : (
            <p>Token anda tidak valid</p>
          )}
        </div>
      </Container>
    </>
  );
};
