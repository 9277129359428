import { Button, Dropdown, Image, Menu, Space } from "antd";
import { formatToRupiah, truncateAddress } from "../utils/Helper";
import moment from "moment";
import { EllipsisOutlined } from "@ant-design/icons";
import { getLoginData } from "../services/authSvc";
moment.tz.setDefault("Asia/Bangkok");
const { user } = getLoginData();
const role = user?.role;

export const ColumnTransaction = (history, role, handleDelete, handleEdit) => {
  return [
    {
      title: "Nama Kegiatan",
      dataIndex: "name",
      key: "name",
      render: (text) => <span>{text}</span>,
    },
    {
      title: "Harga",
      dataIndex: "price",
      key: "price",
      render: (text) => <span>{formatToRupiah(text)}</span>,
    },
    {
      title: "Deskripsi",
      dataIndex: "description",
      key: "description",
      render: (text) => <span>{text}</span>,
    },
    {
      title: "ATM",
      dataIndex: "asset",
      key: "asset",
      render: (text) => <span>{text?.name}</span>,
    },
    {
      title: "Cabang",
      dataIndex: "pelanggan",
      key: "pelanggan",
      render: (text) => <span>{text?.name}</span>,
    },
    {
      title: "Foto Sebelum",
      key: "beforeImages",
      render: (text, record) => {
        const beforeImages = record.report_transaction_images.filter(
          (img) => img.image_type === "before"
        );

        return (
          <div>
            {beforeImages.length > 0 ? (
              beforeImages.map((img, index) => (
                <Image
                  key={index}
                  width={60}
                  src={img.image_url}
                  style={{ paddingRight: "2px" }}
                  fallback="https://storage.googleapis.com/bni-gme/transaksi/2024-08/2024-08-12/3b072d70-58d1-11ef-a445-8b8613c4cc11.png"
                />
              ))
            ) : (
              <span>-</span>
            )}
          </div>
        );
      },
    },
    {
      title: "Foto Sesudah",
      key: "afterImages",
      render: (text, record) => {
        const afterImages = record.report_transaction_images.filter(
          (img) => img.image_type === "after"
        );

        return (
          <div>
            {afterImages.length > 0 ? (
              afterImages.map((img, index) => (
                <Image
                  key={index}
                  width={60}
                  src={img.image_url}
                  style={{ paddingRight: "2px" }}
                  fallback="https://storage.googleapis.com/bni-gme/transaksi/2024-08/2024-08-12/3b072d70-58d1-11ef-a445-8b8613c4cc11.png"
                />
              ))
            ) : (
              <span>-</span>
            )}
          </div>
        );
      },
    },

    {
      title: "Tanggal Dibuat",
      key: "createdAt",
      render: (text, record) => {
        const date = record.createdAt
          ? moment(record.createdAt).format("DD MMMM YYYY HH:mm:ss")
          : "-";

        return <div>{date}</div>;
      },
    },

    {
      title: "Tanggal Pengerjaan",
      key: "operation_date",
      render: (text, record) => {
        const date = record.operation_date
          ? moment(record.operation_date).format("DD MMMM YYYY HH:mm:ss")
          : "-";

        return <div>{date}</div>;
      },
    },
    {
      title: "Aksi",
      key: "actions",
      fixed: "right",
      render: (text, record) => {
        let items = [
          {
            key: "0",
            label: "Lihat",
            onClick: () => {
              history.push(
                `/report/transaction/view/${record.id_report_transaction}`
              );
            },
          },
          {
            key: "1",
            label: "Edit",
            onClick: () => handleEdit(record.id_report_transaction),
          },
          {
            key: "2",
            label: "Delete",
            danger: true,
            onClick: () => handleDelete(record.id_report_transaction),
          },
        ];

        if (role !== "admin") {
          items = [items[0]];
        }
        return (
          <Space size="middle">
            <Dropdown overlay={<Menu items={items} />}>
              <Button icon={<EllipsisOutlined />} />
            </Dropdown>
          </Space>
        );
      },
    },
  ];
};

export const ColumnLaporanTemuan = (handleDetaiLaporan) => {
  return [
    {
      title: "Nama Operator",
      dataIndex: "user_name",
      key: "namaOperator",
      render: (text, record) => (
        <div
          style={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            {record.user_photo_url ? (
              <img
                src={record.user_photo_url}
                alt="profile-pict"
                width="40px"
                height="40px"
                style={{
                  borderRadius: "50%",
                  marginRight: "10px",
                }}
                loading="lazy"
              />
            ) : (
              <div
                className="bg-primary"
                style={{
                  width: "40px",
                  height: "40px",
                  borderRadius: "50%",
                  backgroundColor: "orange",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginRight: "10px",
                }}
              >
                <span
                  style={{
                    color: "#fff",
                    fontSize: "16px",
                    fontWeight: "bold",
                  }}
                >
                  {record.user_name.charAt(0)}
                </span>
              </div>
            )}
            <div>
              <span>{record.user_name}</span>
              <br />
              Operator
            </div>
          </div>
        </div>
      ),
    },
    {
      title: "ATM",
      dataIndex: "asset_name",
      key: "atm",
      render: (text, record) => (
        <div
          style={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          <span>{record.asset_name}</span>
          <br />
          {record.asset_kode}
        </div>
      ),
    },
    {
      title: "Lokasi",
      dataIndex: "asset_alamat",
      key: "lokasi",
      render: (text, record) => (
        <div
          style={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          <span>{truncateAddress(record.asset_alamat)}</span>
          <br />
          {record.pelanggan_name}
        </div>
      ),
    },
    {
      title: "Kunjungan",
      dataIndex: "kunjungan",
      key: "kunjungan",
      render: (text) => (
        <div
          style={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            textAlign: "center",
          }}
        >
          <span>{text}</span>
        </div>
      ),
    },
    {
      title: "Keterangan",
      dataIndex: "temuan.keterangan",
      key: "keterangan",
      render: (text, data) => (
        <div
          style={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          <span>{data.temuan.keterangan}</span>
        </div>
      ),
    },
    {
      title: "Foto",
      key: "beforeImages",
      render: (text, record) => {
        const imageUrls = [
          record.temuan.temuan1_url,
          record.temuan.temuan2_url,
          // Add more URLs if there are more images
        ];

        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            {imageUrls.length > 0 ? (
              imageUrls.map((url, index) =>
                url ? (
                  <Image
                    key={index}
                    height={50}
                    width={30}
                    src={url}
                    style={{ paddingRight: "2px" }}
                    fallback="https://storage.googleapis.com/bni-gme/transaksi/2024-08/2024-08-12/3b072d70-58d1-11ef-a445-8b8613c4cc11.png"
                  />
                ) : null
              )
            ) : (
              <span>-</span>
            )}
          </div>
        );
      },
    },
    {
      title: "Tanggal",
      dataIndex: "date_done",
      key: "tanggal",
      render: (text) => (
        <div
          style={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          <span>{moment.utc(text).format("YYYY-MM-DD")}</span>
          <br />
          {moment.utc(text).format("HH:mm:ss")}
        </div>
      ),
    },
    {
      title: "Detail",
      key: "details",
      fixed: "right",
      render: (text, record) => {
        const handleClick = () => {
          handleDetaiLaporan(record.id_laporan);
        };

        return (
          <a
            onClick={(e) => {
              e.preventDefault(); // Prevent default link behavior
              handleClick(); // Call your custom function
            }}
            rel="noopener noreferrer"
            className="fw-bolder fw-bold border-0"
          >
            Lihat
          </a>
        );
      },
    },
  ];
};
export const ColumnLaporan = (goToDetailLaporan) => {
  return [
    {
      title: "Nama Operator",
      dataIndex: "user_name",
      key: "user_name",
      fixed: "left",
      render: (text, record) => (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {record.user_photo_url ? (
            <Image
              src={`${record.user_photo_url}`}
              alt="profile-pict"
              width={40}
              height={40}
              style={{ borderRadius: "50%", marginRight: "10px" }}
            />
          ) : (
            <div
              className="bg-primary"
              style={{
                width: "40px",
                height: "40px",
                borderRadius: "50%",
                backgroundColor: "orange",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginRight: "10px",
              }}
            >
              <span
                style={{
                  color: "#fff",
                  fontSize: "16px",
                  fontWeight: "bold",
                }}
              >
                {record.user_name ? record.user_name.charAt(0) : ""}
              </span>
            </div>
          )}
          <div style={{ flex: 1 }}>
            <span className="fw-bold" style={{ display: "block" }}>
              {record.user_name}
            </span>
            Operator
          </div>
        </div>
      ),
    },
    {
      title: "ATM",
      dataIndex: "asset_name",
      key: "asset_name",
      render: (text, record) => (
        <div
          style={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          <span className="fw-bold" style={{ display: "block" }}>
            {record.asset_name}
          </span>
          {record.asset_kode}
        </div>
      ),
    },
    {
      title: "Lokasi",
      dataIndex: "asset_alamat",
      key: "asset_alamat",
      render: (text, record) => (
        <div
          style={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          <span className="fw-bold">{record.pelanggan_name}</span>
          <br />
          <span>{truncateAddress(record.asset_alamat)}</span>
        </div>
      ),
    },
    {
      title: "Kunjungan",
      dataIndex: "kunjungan",
      key: "kunjungan",
      align: "center",
      render: (text) => <span className="fw-bold">{text}</span>,
    },
    {
      title: "Temuan",
      dataIndex: "is_temuan",
      key: "is_temuan",
      render: (isTemuan) => (
        <div
          style={{
            display: "inline-block",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {isTemuan ? (
            <div className="bg-danger text-white rounded-pill p-2 text-center">
              Temuan
            </div>
          ) : (
            <div className="bg-success text-white rounded-pill p-2 text-center">
              Tidak
            </div>
          )}
        </div>
      ),
    },
    {
      title: "Tanggal",
      dataIndex: "date_done",
      key: "date_done",
      render: (date) => (
        <div
          style={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          <span>
            {moment.utc(date).format("YYYY-MM-DD")}
            <br />
            {moment.utc(date).format("HH:mm:ss")}
          </span>
        </div>
      ),
    },
    {
      title: "Aksi",
      dataIndex: "id_laporan",
      key: "id_laporan",
      fixed: "right",
      render: (idLaporan) => (
        <a
          rel="noopener noreferrer"
          className="fw-bold"
          onClick={() => {
            goToDetailLaporan(idLaporan);
          }}
        >
          Lihat
        </a>
      ),
    },
  ];
};
