import React, { useState } from "react";

import Datetime from "react-datetime";
import moment from "moment-timezone";

import {
  Button,
  Card,
  Form,
  InputGroup,
  Spinner,
} from "@themesberg/react-bootstrap";
import { useEffect } from "react";
import Swal from "sweetalert2";
import assetSvc from "../services/assetSvc";
import userSvc from "../services/userSvc";
import EmrSvc from "../services/EmrSvc";
import Select from "react-select";
import { getLoginData } from "../services/authSvc";
import Emr2Svc from "../services/Emr2Svc";
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  showErrorAlert,
  showLoadingAlert,
  showSuccessAlert,
  showWarningAlert,
} from "../utils/SwalUtil";
import { customStyles } from "../utils/SelectUtils";

export default () => {
  const [assets, setAssets] = useState([]);
  const [users, setUsers] = useState([]);
  const [selectedOperator, setSelectedOperator] = useState(null);
  const [selectedOperator2, setSelectedOperator2] = useState(null);
  const [selectedAsset, setSelectedAsset] = useState(null);
  const [emrToken, setEmrToken] = useState("");
  const [emr2Token, setEmr2Token] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const { user } = getLoginData();
  const [startDate, setStartDate] = useState(moment().format("YYYY-MM-DD"));
  const [endDate, setEndDate] = useState(null);

  const fetchAssets = async (page, limit) => {
    const resp = await assetSvc.getAsset({
      params: {
        page: 0,
        limit: 10000,
      },
    });
    if (resp.status === 200) {
      setAssets(resp.data.data);
    }
  };

  // admin
  const fetchOperator = async () => {
    const resp = await userSvc.getUser({
      params: {
        page: 0,
        limit: 10000,
        role: "operator",
      },
    });
    if (resp.status === 200) {
      setUsers(resp.data.data);
    }
  };

  const handleGenerate = async () => {
    let data = {};
    if (selectedOperator && selectedAsset) {
      data = {
        id_user: selectedOperator.value,
        id_asset: selectedAsset.value,
      };
    } else {
      showWarningAlert("Perhatian", "Semua data wajib diisi");
      return;
    }
    showLoadingAlert("Tunggu", "Sedang memproses token EMR");

    try {
      const resp = await EmrSvc.generateEmr(data);
      if (resp.status === 200) {
        setEmrToken(resp.data.token);
        showSuccessAlert("Berhasil", "Token EMR berhasil dibuat");
      }
    } catch (error) {
      let message =
        error.response?.data?.message || "Gagal memproses token EMR";
      showErrorAlert("Gagal", message);
    } finally {
      Swal.close();
    }
  };

  const handleStartDateChange = (date) => {
    const formattedDate = moment(date).format("YYYY-MM-DD");
    setStartDate(formattedDate);
  };

  const handleEndDateChange = (date) => {
    const formattedDate = moment(date).format("YYYY-MM-DD");
    setEndDate(formattedDate);
  };

  const handleGenerateV2 = async () => {
    let data = {};
    if (
      selectedOperator2 &&
      selectedOperator2.value &&
      startDate !== null &&
      endDate !== null
    ) {
      data = {
        id_user: selectedOperator2.value,
        start_date: startDate,
        end_date: endDate,
      };
      try {
        showLoadingAlert("Tunggu", "Sedang memproses token EMR");
        const resp = await Emr2Svc.generateEmrV2(data);
        if (resp.status === 200) {
          setEmr2Token(resp.data.token);
          showSuccessAlert("Berhasil", "Token EMR V2 berhasil dibuat");
        }
      } catch (error) {
        let message =
          error.response?.data?.message || "Gagal memproses token EMR V2";
        showErrorAlert("Gagal", message);
      }
    } else {
      showWarningAlert("Perhatian", "Semua data wajib diisi");
    }
  };

  const handleOperatorChange = (selectedOption) => {
    setSelectedOperator(selectedOption);
  };
  const handleOperator2Change = (selectedOption) => {
    setSelectedOperator2(selectedOption);
  };

  const handleAssetChange = (selectedOption) => {
    setSelectedAsset(selectedOption);
  };

  useEffect(() => {
    fetchAssets();
    fetchOperator();
    setIsLoading(false);
  }, []);

  return (
    <>
      <div className="align-items-center py-4 w-100">
        <Card border="light" className="shadow-sm">
          <Card.Header>
            <h5>Buat Token EMR V1</h5>
          </Card.Header>
          <Card.Body className="pb-0">
            {isLoading ? (
              <div
                className="d-flex justify-content-center align-items-center"
                style={{ height: "300px" }}
              >
                <Spinner animation="border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              </div>
            ) : (
              <Form>
                <Form.Group className="mb-3">
                  <Form.Label>Operator</Form.Label>
                  <Select
                    id="state"
                    required
                    placeholder="Pilih Operator"
                    value={selectedOperator}
                    onChange={handleOperatorChange}
                    options={users.map((user) => ({
                      value: user.id_user,
                      label: `${user.name} - ${user?.pelanggan?.name}`,
                    }))}
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>ATM</Form.Label>
                  <Select
                    id="state"
                    required
                    value={selectedAsset}
                    onChange={handleAssetChange}
                    options={assets.map((asset) => ({
                      value: asset.id_asset,
                      label: `${asset.name} - ${asset?.pelanggan?.name}`,
                    }))}
                  />
                </Form.Group>
              </Form>
            )}
          </Card.Body>
          <Card.Footer className="px-3 border-0 d-lg-flex align-items-center justify-content-between">
            <Button
              variant="primary"
              onClick={(e) => {
                e.preventDefault();
                handleGenerate();
              }}
            >
              Buat
            </Button>
          </Card.Footer>
        </Card>
        {emrToken === "" ? (
          ""
        ) : (
          <Card>
            <Card.Header>
              <p>Berikut adalah link untuk menggunakan EMR</p>
              <a
                href={`/emr/${emrToken}`}
                rel="noopener noreferrer"
                className="fw-bolder"
                target="_blank"
              >
                {`${emrToken}`}
              </a>
            </Card.Header>
          </Card>
        )}
      </div>
      <div className="align-items-center py-4 w-100">
        <Card border="light" className="shadow-sm">
          <Card.Header>
            <h5>Buat Token EMR V2</h5>
          </Card.Header>
          <Card.Body className="pb-0">
            {isLoading ? (
              <div
                className="d-flex justify-content-center align-items-center"
                style={{ height: "300px" }}
              >
                <Spinner animation="border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              </div>
            ) : (
              <Form>
                <Form.Group className="mb-3">
                  <Form.Label>Operator</Form.Label>
                  <Select
                    id="state"
                    required
                    placeholder="Pilih Operator"
                    value={selectedOperator2}
                    onChange={handleOperator2Change}
                    options={users.map((user) => ({
                      value: user.id_user,
                      label: `${user.name} - ${user?.pelanggan?.name}`,
                    }))}
                    // className="bg-dark"
                    styles={customStyles}
                  />
                </Form.Group>
                <div className="row">
                  <div className="col">
                    {/* <label htmlFor="startDate" className="form-label">
                      Tanggal Dimulai
                    </label>
                    <input
                      type="date"
                      id="startDate"
                      className="form-control"
                      value={startDate}
                      onChange={handleStartDateChange}
                    /> */}
                    <Form.Label>Tanggal dimulai</Form.Label>
                    <Datetime
                      timeFormat={false}
                      onChange={handleStartDateChange}
                      renderInput={(props, openCalendar) => (
                        <InputGroup className="me-2">
                          <InputGroup.Text>
                            <FontAwesomeIcon icon={faCalendarAlt} />
                          </InputGroup.Text>
                          <Form.Control
                            required
                            type="text"
                            value={
                              startDate
                                ? moment(startDate).format("YYYY-MM-DD")
                                : ""
                            }
                            placeholder="YYYY-MM-DD"
                            onFocus={openCalendar}
                            onChange={() => {}}
                          />
                        </InputGroup>
                      )}
                    />
                  </div>
                  <div className="col">
                    <Form.Label>Tanggal berakhir</Form.Label>
                    <Datetime
                      timeFormat={false}
                      onChange={handleEndDateChange}
                      renderInput={(props, openCalendar) => (
                        <InputGroup className="me-2">
                          <InputGroup.Text>
                            <FontAwesomeIcon icon={faCalendarAlt} />
                          </InputGroup.Text>
                          <Form.Control
                            required
                            type="text"
                            value={
                              endDate
                                ? moment(endDate).format("YYYY-MM-DD")
                                : ""
                            }
                            placeholder="YYYY-MM-DD"
                            onFocus={openCalendar}
                            onChange={() => {}}
                          />
                        </InputGroup>
                      )}
                    />
                  </div>
                </div>
              </Form>
            )}
          </Card.Body>
          <Card.Footer className="px-3 border-0 d-lg-flex align-items-center justify-content-between">
            <Button
              variant="primary"
              onClick={(e) => {
                e.preventDefault();
                handleGenerateV2();
              }}
            >
              Buat
            </Button>
          </Card.Footer>
        </Card>
        {emr2Token === "" ? (
          ""
        ) : (
          <Card>
            <Card.Header>
              <p>Berikut adalah link untuk menggunakan EMR</p>
              <a
                href={`/emrv2/${emr2Token}`}
                rel="noopener noreferrer"
                className="fw-bolder"
                target="_blank"
              >
                {`${emr2Token}`}
              </a>
            </Card.Header>
          </Card>
        )}
      </div>
    </>
  );
};
