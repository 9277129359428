import { api, handleApiResponse } from "./api";

export default {
  generateEmr(config) {
    return api.post("/api/v1/emr", config).catch(handleApiResponse);
  },

  validateEmr(config) {
    return api.post("/api/v1/emr/validate", config).catch(handleApiResponse);
  },

  addReportEmr(config) {
    return api.post(`/api/v1/emr/laporan`, config).catch(handleApiResponse);
  },

  getAllEmr(config) {
    return api.get(`/api/v1/emr`, config).catch(handleApiResponse);
  },

  deleteEmr(id) {
    return api.delete(`/api/v1/emr/${id}`).catch(handleApiResponse);
  },
};
