import React, { useRef, useState } from "react";

import {
  Button,
  Card,
  Table,
  Image,
  Spinner,
  Pagination,
  Nav,
  Form,
  FormControl,
  InputGroup,
} from "@themesberg/react-bootstrap";
import { useEffect } from "react";
import Swal from "sweetalert2";

import EmrSvc from "../services/EmrSvc";
import assetSvc from "../services/assetSvc";
import userSvc from "../services/userSvc";
import {
  showErrorAlert,
  showLoadingAlert,
  showSuccessAlert,
} from "../utils/SwalUtil";
import Select from "react-select";
import { customStyles } from "../utils/SelectUtils";

export default () => {
  const [emr, setEmr] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [activePage, setActivePage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [startIndex, setStartIndex] = useState(null);
  const [endIndex, setEndIndex] = useState(null);
  const [totalPages, setTotalPages] = useState(null);
  const [totalRows, setTotalRows] = useState(null);

  const searchInputRef = useRef(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedAsset, setSelectedAsset] = useState("");
  const [selectedUser, setSelectedUser] = useState("");
  const [assets, setAssets] = useState([]);
  const [users, setUsers] = useState([]);

  const renderPaginationItems = () => {
    const paginationItems = [];

    paginationItems.push(
      <Pagination.First
        key="first"
        onClick={() => handlePageChange(1)}
        disabled={activePage === 1}
      />
    );

    paginationItems.push(
      <Pagination.Prev
        key="prev"
        onClick={() => activePage > 1 && handlePageChange(activePage - 1)}
        disabled={activePage === 1}
      />
    );

    for (let i = 1; i <= totalPages; i++) {
      if (
        i === 1 ||
        i === totalPages ||
        (i >= activePage - 2 && i <= activePage + 2)
      ) {
        paginationItems.push(
          <Pagination.Item
            key={i}
            onClick={() => handlePageChange(i)}
            active={i === activePage}
          >
            {i}
          </Pagination.Item>
        );
      } else if (
        (i === activePage - 3 && i > 2) ||
        (i === activePage + 3 && i < totalPages - 1)
      ) {
        paginationItems.push(<Pagination.Ellipsis key={i} />);
      }
    }

    paginationItems.push(
      <Pagination.Next
        key="next"
        onClick={() =>
          activePage < totalPages && handlePageChange(activePage + 1)
        }
        disabled={activePage === totalPages}
      />
    );

    paginationItems.push(
      <Pagination.Last
        key="last"
        onClick={() => handlePageChange(totalPages)}
        disabled={activePage === totalPages}
      />
    );

    return paginationItems;
  };

  const handleItemsPerPageChange = (event) => {
    const newItemsPerPage = parseInt(event.target.value);
    setItemsPerPage(newItemsPerPage);
    setActivePage(1); // Reset halaman aktif ke halaman pertama
  };

  const fetchEmrList = async (page, limit) => {
    setIsLoading(true);

    const resp = await EmrSvc.getAllEmr({
      params: {
        page: page - 1,
        limit: limit,
        query: searchTerm || undefined,
        id_asset: parseInt(selectedAsset.value) || undefined,
        id_user: parseInt(selectedUser.value) || undefined,
      },
    });
    if (resp.status === 200) {
      setEmr(resp.data.data);
      setStartIndex((activePage - 1) * itemsPerPage);
      setEndIndex(startIndex + itemsPerPage);
      setTotalPages(resp.data.totalPage);
      setTotalRows(resp.data.totalRows);
    }
    setIsLoading(false);
  };

  const isFirstRenderEmr = useRef(true);
  useEffect(() => {
    if (!isFirstRenderEmr.current) {
      fetchEmrList(activePage, itemsPerPage);
    } else {
      isFirstRenderEmr.current = false;
    }
  }, [activePage, itemsPerPage]);

  useEffect(() => {
    fetchEmrList();
  }, [selectedAsset, selectedUser]);

  const isFirstRender = useRef(true);
  useEffect(() => {
    if (!isFirstRender.current) {
      let timerId;
      const fetchData = () => {
        fetchEmrList();
      };
      const delayedFetch = () => {
        clearTimeout(timerId);
        timerId = setTimeout(fetchData, 500); // Adjust the delay time as per your needs
      };
      delayedFetch(); // Call the delayedFetch initially to trigger the initial API call
      return () => {
        // Clean up by clearing the timer when the component unmounts
        clearTimeout(timerId);
      };
    } else {
      isFirstRender.current = false;
    }
  }, [searchTerm]);

  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
  };

  const handleDelete = async (id) => {
    const willDelete = await Swal.fire({
      title: "Konfirmasi hapus EMR",
      text: "Tindakan ini akan menghapus permanen emr.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Hapus",
      cancelButtonText: "Batalkan",
    });

    if (willDelete.isConfirmed) {
      try {
        showLoadingAlert("Tunggu", "Menghapus data emr...");
        const res = await EmrSvc.deleteEmr(id);
        fetchEmrList(activePage, itemsPerPage);
        showSuccessAlert("Berhasil", "Data EMR berhasil dihapus");
      } catch (error) {
        let message =
          error.response?.data?.message || "Gagal menghapus data EMR";
        showErrorAlert("Gagal", message);
      }
    }
  };

  const fetchAssets = async () => {
    const resp = await assetSvc.getAsset({
      params: {
        page: 0,
        limit: 1000,
      },
    });
    if (resp.status === 200) {
      setAssets(resp.data.data);
    }
  };

  const fetchUser = async () => {
    const resp = await userSvc.getUser({
      params: {
        page: 0,
        limit: 1000,
        role: "operator",
      },
    });
    if (resp.status === 200) {
      setUsers(resp.data.data);
    }
  };

  useEffect(() => {
    fetchAssets();
    fetchUser();
  }, []);

  const handleClearFilters = () => {
    setSearchTerm("");
    setSelectedUser("");
    setSelectedAsset("");
    setActivePage(1);
    if (searchInputRef.current) {
      searchInputRef.current.value = "";
    }
    fetchEmrList();
  };

  const handleSelectAsset = (selectedOption) => {
    setSelectedAsset(selectedOption);
  };

  const handleSelectUser = (selectedOption) => {
    setSelectedUser(selectedOption);
  };

  return (
    <>
      <div className="align-items-center py-4 w-100">
        <Card border="light" className="shadow-sm">
          <Card.Body className="pb-0">
            <Card.Title className="">List EMR</Card.Title>
            <hr />
            <div className="d-flex justify-content-between mb-3">
              <FormControl
                ref={searchInputRef}
                type="text"
                placeholder="Cari nama operator, token...."
                className="me-1"
                // value={searchTerm}
                onChange={(e) => {
                  setSearchTerm(e.target.value);
                }}
              />
              <InputGroup>
                <Select
                  id="state"
                  className="me-1"
                  placeholder="Semua Operator"
                  styles={customStyles}
                  value={selectedUser}
                  onChange={handleSelectUser}
                  options={users.map((user) => ({
                    value: user.id_user,
                    label: user.name,
                  }))}
                />
              </InputGroup>
              <InputGroup>
                <Select
                  className="me-1"
                  id="state"
                  placeholder="Pilih ATM"
                  styles={customStyles}
                  value={selectedAsset}
                  onChange={handleSelectAsset}
                  options={
                    assets &&
                    assets.map((asset) => ({
                      value: asset.id_asset,
                      label: asset.name,
                    }))
                  }
                />
              </InputGroup>
              <Button
                variant="secondary"
                size="sm"
                className="ms-2"
                onClick={handleClearFilters}
                style={{ marginRight: "10px" }}
              >
                Reset Filter
              </Button>
            </div>
            <Table
              responsive
              className="table-centered table-nowrap rounded mb-0"
            >
              <thead className="thead-light">
                <tr>
                  <th className="border-0">Pengguna</th>
                  <th className="border-0">ATM</th>
                  <th className="border-0">Token</th>
                  <th className="border-0">Tanggal</th>
                  <th className="border-0">Aksi</th>
                </tr>
              </thead>
              {isLoading ? (
                <tr>
                  <td
                    colSpan={5}
                    className="text-center"
                    style={{ height: "300px" }}
                  >
                    <Spinner animation="border" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </Spinner>
                  </td>
                </tr>
              ) : totalRows > 0 ? (
                <tbody>
                  {emr.map((x) => (
                    <tr key={x.id_emr}>
                      <td className="border-0 mr-2">
                        <div style={{ display: "flex", alignItems: "center" }}>
                          {x.user.photo_url ? (
                            <Image
                              src={x.user_photo_url}
                              alt="profile-pict"
                              width="40px"
                              height="40px"
                              style={{
                                borderRadius: "50%",
                                marginRight: "10px",
                              }}
                            />
                          ) : (
                            <div
                              className="bg-primary"
                              style={{
                                width: "40px",
                                height: "40px",
                                borderRadius: "50%",
                                backgroundColor: "orange",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                marginRight: "10px",
                              }}
                            >
                              <span
                                style={{
                                  color: "#fff",
                                  fontSize: "16px",
                                  fontWeight: "bold",
                                }}
                              >
                                {x?.user.name?.charAt(0).toUpperCase()}
                              </span>
                            </div>
                          )}
                          <div>
                            <span className="fw-bold">{x.user.name}</span>
                            <br />
                            {x.user.phone}
                          </div>
                        </div>
                      </td>
                      <td className="border-0">
                        <span className="fw-bolder">{x.asset.name}</span>
                        <br />
                        {x.asset.lokasi} - {x.asset.kode}
                      </td>
                      <td className="fw-bold border-0">
                        <a href={`/emr/${x.token}`} target="_blank">
                          <span className="fw-bolder">{x.token}</span>
                        </a>
                      </td>
                      <td className="fw-bold border-0">
                        <span className="fw-bolder">{x.date}</span>
                      </td>
                      <td className="border-0">
                        <Button
                          variant="danger"
                          size="sm"
                          onClick={(e) => {
                            e.preventDefault();
                            handleDelete(x.id_emr);
                          }}
                        >
                          Delete
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              ) : (
                <tr>
                  <td
                    colSpan={5}
                    className="text-center"
                    style={{ height: "200px" }}
                  >
                    <span className="text-muted">Tidak ada EMR</span>
                  </td>
                </tr>
              )}
            </Table>
          </Card.Body>
          <Card.Footer className="px-3 border-0 d-lg-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center">
              <small className="me-2">Limit:</small>
              <Form.Select
                className="me-3"
                style={{ width: "70px" }}
                value={itemsPerPage}
                onChange={handleItemsPerPageChange}
              >
                <option value="5">5</option>
                <option value="10">10</option>
                <option value="15">15</option>
              </Form.Select>
              <small className="fw-bold">
                Menampilkan {startIndex + 1} -{" "}
                {Math.min(startIndex + itemsPerPage, totalRows)} dari{" "}
                {totalRows} EMR List
              </small>
            </div>

            <div className="d-flex align-items-center">
              <Nav className="mx-2">
                <Pagination className="mb-2 mb-lg-0">
                  {renderPaginationItems()}
                </Pagination>
              </Nav>
            </div>
          </Card.Footer>
        </Card>
      </div>
    </>
  );
};
