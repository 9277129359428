import axios from "axios";
import saveLoginData, {
  removeLoginData,
  restoreToken,
  memoizedRefreshToken,
} from "./authSvc";
import { showErrorAlert } from "../utils/SwalUtil";
import userSvc from "./userSvc";
import { setupCache } from "axios-cache-interceptor";

let baseUrl;
const NODE_ENV = process.env.REACT_APP_NODE_ENV;

console.log(NODE_ENV);

switch (NODE_ENV) {
  case "production":
    baseUrl = process.env.REACT_APP_API_HOST;
    break;
  case "local":
    baseUrl = process.env.REACT_APP_API_LOCAL;
    break;
  default:
    baseUrl = process.env.REACT_APP_API_DEV;
}

const axiosTemplate = axios.create({
  baseURL: baseUrl,
});

export const api = setupCache(axiosTemplate, {
  ttl: NODE_ENV === "local" ? 0 : 1 * 5 * 1000,
});
export const apiClient = baseUrl;

let refreshingFunc = null;
let isRefreshing = false;

export const handleApiResponse = async (error) => {
  // console.log({ error_handler: error });
  // if (error?.response?.data?.relog === true) {
  //   showErrorAlert("Gagal", "Sesi login ada berakhir");
  //   setTimeout(() => {
  //     window.location.href = "/login";
  //   }, 500);
  //   // removeLoginData();
  // }

  return error;
};

export const middlewareResponse = (response) => {
  if (response.headers["x-new-access-token"]) {
    restoreToken(response.headers["x-new-access-token"]);
  }

  return response;
};

api.interceptors.request.use(
  async (config) => {
    const authToken = localStorage.getItem("authToken");
    if (authToken) {
      config.headers.Authorization = `Bearer ${authToken}`;
    }

    if (!api.defaults.baseURL) {
      api.defaults.baseURL = baseUrl;
    }

    config.withCredentials = true;

    return config;
  },
  (error) => Promise.reject(error)
);

api.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    // console.log("error in interceptors", { error });
    if (!error) {
      return Promise.reject(error);
    }
    const originalConfig = error.config;
    const token = localStorage.getItem("authToken");
    if (!token || error.response?.status !== 403) {
      return Promise.reject(error);
    }
    // console.log({ error });
    try {
      if (error.response?.data?.refresh) {
        throw new Error(error);
      }

      const newToken = await memoizedRefreshToken();
      // console.log("masuk refresh token");
      if (newToken) localStorage.setItem("authToken", newToken);

      originalConfig.headers.Authorization = `Bearer ${localStorage.getItem(
        "authToken"
      )}`;

      try {
        return await axios.request(originalConfig);
      } catch (innerError) {
        // console.log({ innerError });
        if (innerError.response?.status === 403) {
          throw innerError;
        }
      }
    } catch (err) {
      // console.log("in catch", { err });
      if (err?.response?.data?.relog === true) {
        removeLoginData();
        showErrorAlert("Gagal", "Sesi login ada berakhir");
        setTimeout(() => {
          window.location.href = "/login";
        }, 500);
      }
    } finally {
      refreshingFunc = undefined;
    }
    return Promise.reject(error);
  }
);

async function renewToken() {
  const response = await userSvc.refreshToken();
  const token = response.data.access_token;

  const newRefreshToken = response.data.refresh_token;

  return token;
}
