import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import {
  Button,
  Card,
  Table,
  Image,
  Spinner,
} from "@themesberg/react-bootstrap";

import { useEffect } from "react";
import customerSvc from "../services/customerSvc";
import Swal from "sweetalert2";
import FormAddUserInUserAsset from "./FormAddUserInUserAsset";
import userAssetSvc from "../services/userAssetSvc";
import { useHistory, useParams } from "react-router-dom";
import userSvc from "../services/userSvc";
import { showErrorAlert, showSuccessAlert } from "../utils/SwalUtil";

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
  // const searchInputRef = useRef(null);
  const [operators, setOperators] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  // const [activePage, setActivePage] = useState(1);
  // const [startIndex, setStartIndex] = useState(null);
  // const [endIndex, setEndIndex] = useState(null);
  // const [totalPages, setTotalPages] = useState(null);
  const [showForm, setShowForm] = useState(false);
  const [customerToEdit, setCustomerToEdit] = useState(null);
  // const [searchTerm, setSearchTerm] = useState("");
  // const [selectedStatus, setSelectedStatus] = useState("");
  // const [itemsPerPage, setItemsPerPage] = useState(10);
  const [keterangan, setKeterangan] = useState("");
  const [userAsset, setUserAsset] = useState(null);
  const [id_user_asset, setIdUserAsset] = useState(null);
  const { id } = useParams();
  const history = useHistory();

  const fetchUserInUserAsset = async () => {
    setIsLoading(true);
    const resp = await userAssetSvc.getUserAssetById(id);
    if (resp.status === 200) {
      setIdUserAsset(resp.data.data.id_user_asset);
      setUserAsset(resp.data.data.users);
      setKeterangan(resp.data.data.keterangan);
    }

    setIsLoading(false);
  };

  const fetchOperator = async () => {
    const resp = await userSvc.getUser({
      params: {
        page: 0,
        limit: 10000,
        role: "operator",
      },
    });
    if (resp.status === 200) {
      const filteredOperators = resp.data.data.filter(
        (operator) => operator.id_user_asset === null
      );
      setOperators(filteredOperators);
    }
  };

  useEffect(() => {
    fetchUserInUserAsset();
    fetchOperator();
    setIsLoading(false);
  }, []);

  const handleRemoveUser = async (id) => {
    const willDelete = await Swal.fire({
      title: "Konfirmasi Hapus User",
      text: "Tindakan ini akan menghapus permanen user.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Hapus",
      cancelButtonText: "Batalkan",
    });

    if (willDelete.isConfirmed) {
      try {
        const resp = await userSvc.removeUserInUserAssetById(id);
        fetchUserInUserAsset();
        if (resp.status >= 200 && resp.status < 300) {
          showSuccessAlert(
            "Berhasil",
            `Data pengguna di ${keterangan} berhasil dihapus`
          );
        } else {
          showErrorAlert("Gagal", `Gagal menghapus data pengguna di ${keterangan}`);
        }
      } catch (error) {
        let message = error.response?.data?.message || `Gagal menghapus data pengguna di ${keterangan}`;
        showErrorAlert("Gagal", message);
      }
    }
  };

  const handleAddCustomer = () => {
    setShowForm(true);
    setCustomerToEdit(null);
  };

  const handleEditCustomer = async (customerId) => {
    const resp = await customerSvc.getCustomerById(customerId);
    if (resp.status === 200) {
      setShowForm(true);
      setCustomerToEdit(resp.data.data);
    } else {
      setShowForm(true);
    }
  };

  const handleCloseForm = () => {
    setShowForm(false);
    setCustomerToEdit(null);
    fetchUserInUserAsset();
  };

  return (
    <>
      <div className="align-items-center py-4 w-100">
        <Card border="light" className="shadow-sm">
          <Card.Body className="pb-0">
            <Card.Title className="">
              Pengguna User Asset <strong>{keterangan}</strong>
            </Card.Title>
            <div className="d-flex mb-3">
              <Button
                variant="secondary"
                size="sm"
                className="me-2"
                onClick={() => history.goBack()}
              >
                Kembali
              </Button>
              <Button
                variant="success"
                size="sm"
                className=""
                style={{ width: "100px" }}
                onClick={handleAddCustomer}
              >
                <FontAwesomeIcon
                  icon={faPlus}
                  className="me-2"
                  style={{ fontSize: "14px" }}
                />
                Tambah
              </Button>
            </div>

            <Table
              responsive
              className="table-centered table-nowrap rounded mb-0"
            >
              <thead className="thead-light">
                <tr>
                  <th className="border-0">Nama Pengguna</th>
                  <th className="border-0">Nomor Telepon</th>
                  <th className="border-0">Role</th>
                  <th className="border-0">Status</th>
                  <th className="border-0">Aksi</th>
                </tr>
              </thead>
              {isLoading ? (
                <div
                  className="d-flex justify-content-center align-items-center"
                  style={{ height: "300px" }}
                >
                  <Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </Spinner>
                </div>
              ) : (
                <tbody>
                  {userAsset &&
                    userAsset.map((user) => (
                      <tr key={user.id_user}>
                        <td className="border-0">
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                            className="mb-2"
                          >
                            {user.photo_url ? (
                              <Image
                                src={user.photo_url}
                                alt="profile-pict"
                                width="40px"
                                height="40px"
                                style={{
                                  borderRadius: "50%",
                                  marginRight: "10px",
                                }}
                              />
                            ) : (
                              <div
                                style={{
                                  width: "40px",
                                  height: "40px",
                                  borderRadius: "50%",
                                  backgroundColor: "orange",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  marginRight: "10px",
                                }}
                              >
                                {user.name !== "" && (
                                  <span
                                  style={{
                                    color: "#fff",
                                    fontSize: "16px",
                                    fontWeight: "bold",
                                  }}>
                                  {user?.name?.charAt(0).toUpperCase()}
                                  </span>
                                )}
                              </div>
                            )}
                            <div>
                              <span className="fw-bold">{user.name}</span>
                              <br />
                              {user.email}
                            </div>
                          </div>
                        </td>
                        <td className="border-0">
                          <span className="fw-bolder">{user.phone}</span>
                        </td>
                        <td className="fw-bold border-0">
                          <span className="fw-bolder">
                            {user.role.toUpperCase()}
                          </span>
                          {user.pelanggan && (
                            <>
                              <br />
                              {user.pelanggan.name}
                            </>
                          )}
                        </td>
                        <td className="border-0">
                          <div>
                            {user.active ? (
                              <div className="bg-success text-white rounded-pill p-2 text-center">
                                Aktif
                              </div>
                            ) : (
                              <div className="bg-danger text-white rounded-pill p-2 text-center">
                                Nonaktif
                              </div>
                            )}
                          </div>
                        </td>
                        <td className="border-0">
                          <Button
                            variant="danger"
                            size="sm"
                            className="text-white rounded-pill p-2 text-center me-2"
                            onClick={() => {
                              handleRemoveUser(user.id_user);
                            }}
                          >
                            Hapus
                          </Button>
                        </td>
                      </tr>
                    ))}
                </tbody>
              )}
            </Table>
          </Card.Body>
          <Card.Footer className="px-3 border-0 d-lg-flex align-items-center justify-content-between"></Card.Footer>
        </Card>
      </div>
      <FormAddUserInUserAsset
        show={showForm}
        handleClose={handleCloseForm}
        // setOperators={operators}
        setIdUserAsset={id_user_asset}
      />
    </>
  );
};
