import React, { useEffect, useState, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt, faSquare } from "@fortawesome/free-solid-svg-icons";
import Datetime from "react-datetime";
import moment from "moment";

import {
  Col,
  Row,
  Card,
  Table,
  Form,
  InputGroup,
  Spinner,
  FormControl,
} from "@themesberg/react-bootstrap";
import reportSvc from "../services/reportSvc";

import { getLoginData } from "../services/authSvc";
import customerSvc from "../services/customerSvc";
import userSvc from "../services/userSvc";
import { customStyles } from "../utils/SelectUtils";
import Select from "react-select";
import NavTabs from "../components/NavTabs";
import { useHistory } from "react-router-dom";
import LaporanInformationCard from "../components/LaporanInformationCard";
moment.tz.setDefault("Asia/Bangkok");

export default () => {
  const [isLoading, setIsLoading] = useState(false);
  const [startDate, setStartDate] = useState(
    moment().subtract(2, "days").format("YYYY-MM-DD")
  );
  const [searchTerm, setSearchTerm] = useState("");
  const searchInputRef = useRef(null);
  const [endDate, setEndDate] = useState(moment().format("YYYY-MM-DD"));
  const [reportExports, setReportExport] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [operators, setOperators] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState("");
  const [selectedOperator, setSelectedOperator] = useState("");
  const [daysInRange, setDaysInRange] = useState(0);
  const { user } = getLoginData();

  const fetchCustomers = async () => {
    if (user.role === "admin") {
      const resp = await customerSvc.getCustomer({
        params: {
          page: 0,
          limit: 1000,
        },
      });
      if (resp.status === 200) {
        setCustomers(resp.data.data);
      }
    }
  };

  const fetchOperators = async () => {
    const resp = await userSvc.getUser({
      params: {
        role: "operator",
        page: 0,
        limit: 1000,
      },
    });
    if (resp.status === 200) {
      setOperators(resp.data.data);
    }
  };

  // Function to calculate and update the days in range
  const updateDaysInRange = () => {
    const start = moment(startDate);
    const end = moment(endDate);
    const days = end.diff(start, "days") + 1; // Add 1 to include both start and end dates
    setDaysInRange(days);
  };

  const handleStartDateChange = (date) => {
    setIsLoading(true);
    const formattedDate = moment(date).format("YYYY-MM-DD");
    setStartDate(formattedDate);
    if (moment(formattedDate).isAfter(endDate)) {
      setEndDate(formattedDate);
    }
    setIsLoading(false);
  };

  const handleEndDateChange = (date) => {
    setIsLoading(true);

    const formattedDate = moment(date).format("YYYY-MM-DD");
    setEndDate(formattedDate);
    if (moment(formattedDate).isBefore(startDate)) {
      setStartDate(formattedDate);
    }
    setIsLoading(false);
  };

  const fetchReportsExport = async () => {
    try {
      setIsLoading(true);
      const respdata = await reportSvc.getReportExportDaily({
        params: {
          start_date: startDate,
          end_date: endDate,
          query: searchTerm,
          id_pelanggan: selectedCustomer ? selectedCustomer.value : "",
          id_user: selectedOperator ? selectedOperator.value : "",
        },
      });
      if (respdata.status === 200) {
        setReportExport(respdata.data.data);
      }

      setIsLoading(false);
    } catch (err) {}
  };

  useEffect(() => {
    fetchCustomers();
    fetchOperators();
  }, []);

  useEffect(() => {
    updateDaysInRange();
  }, [startDate, endDate]);

  useEffect(() => {
    fetchReportsExport();
  }, [startDate, endDate, selectedCustomer, selectedOperator]);

  const isFirstRender = useRef(true);
  useEffect(() => {
    if (!isFirstRender.current) {
      let timerId;
      const fetchData = () => {
        fetchReportsExport();
      };
      const delayedFetch = () => {
        clearTimeout(timerId);
        timerId = setTimeout(fetchData, 500);
      };
      delayedFetch();
      return () => {
        clearTimeout(timerId);
      };
    } else {
      isFirstRender.current = false;
    }
  }, [searchTerm]);

  const handleSelectCustomer = (selectedOption) => {
    setSelectedCustomer(selectedOption);
  };

  const handleSelectOperator = async (selectedOption) => {
    setSelectedOperator(selectedOption);
    if (selectedOption.value === "") {
      return;
    }
    const user = await userSvc.getUserById(selectedOption.value);
    if (user.status === 200) {
      setSelectedCustomer({
        label: user.data.data.pelanggan.name,
        value: user.data.data.id_pelanggan,
      });
    }
  };

  const generateDateLabels = (startDate, daysInRange) => {
    const labels = [];
    for (let i = 0; i < daysInRange; i++) {
      const date = moment(startDate).add(i, "days");
      labels.push(date.format("D MMM"));
    }
    return labels;
  };

  const handleTabChange = () => {
    useHistory.push("/");
  };

  return (
    <div className="align-items-center py-4 w-100">
      <Card border="light" className="shadow-sm">
        <Card.Header>
          <NavTabs
            activeTab={"/report-summary"}
            onTabChange={handleTabChange}
            sideTab={"infografis"}
          />

          <Row className="align-items-center mt-3">
            <Form>
              <div className="mb-2">
                <Form.Group id="range">
                  <Form.Label className="text-primary fs-6">
                    Pilih Tanggal
                  </Form.Label>
                  <Row
                    style={{ width: "100%", margin: "0 3px" }}
                    className="fs-6"
                  >
                    <Col
                      style={{
                        minWidth: "125px",
                        height: "54px",
                        padding: "0 3px 0 0",
                      }}
                    >
                      <Datetime
                        timeFormat={false}
                        dateFormat="MM-DD"
                        style={{ height: "100%" }}
                        onChange={handleStartDateChange}
                        renderInput={(props, openCalendar) => (
                          <InputGroup className="me-1">
                            <InputGroup.Text>
                              <FontAwesomeIcon icon={faCalendarAlt} />
                            </InputGroup.Text>
                            <Form.Control
                              required
                              type="text"
                              value={
                                startDate
                                  ? moment(startDate).format("YYYY-MM-DD")
                                  : ""
                              }
                              placeholder="Tanggal Awal"
                              onFocus={openCalendar}
                              onChange={() => {}}
                            />
                          </InputGroup>
                        )}
                      />
                    </Col>
                    <Col
                      style={{
                        minWidth: "125px",
                        height: "54px",
                        padding: "0 3px 0 0",
                      }}
                    >
                      <Datetime
                        timeFormat={false}
                        dateFormat="MM-DD"
                        style={{ height: "1px" }}
                        onChange={handleEndDateChange}
                        renderInput={(props, openCalendar) => (
                          <InputGroup>
                            <InputGroup.Text>
                              <FontAwesomeIcon icon={faCalendarAlt} />
                            </InputGroup.Text>
                            <Form.Control
                              required
                              type="text"
                              value={
                                endDate
                                  ? moment(endDate).format("YYYY-MM-DD")
                                  : ""
                              }
                              placeholder="Tanggal Akhir"
                              onFocus={openCalendar}
                              onChange={() => {}}
                            />
                          </InputGroup>
                        )}
                      />
                    </Col>
                    <Col
                      style={{
                        minWidth: "125px",
                        height: "54px",
                        padding: "0 3px 0 0",
                      }}
                    >
                      <InputGroup>
                        <Select
                          className="me-1"
                          id="state"
                          placeholder="Semua Operator"
                          styles={{
                            ...customStyles,
                            input: (provided) => ({
                              ...provided,
                              minHeight: "36px",
                            }),
                          }}
                          value={selectedOperator}
                          onChange={handleSelectOperator}
                          options={[
                            {
                              value: "",
                              label: "Semua Operator",
                            }, // Default option
                            ...operators.map((user) => ({
                              value: user.id_user,
                              label: user.name,
                            })),
                          ]}
                        />
                      </InputGroup>
                    </Col>
                    <Col
                      style={{
                        minWidth: "125px",
                        height: "54px",
                        padding: "0 3px 0 0",
                      }}
                    >
                      <FormControl
                        ref={searchInputRef}
                        type="text"
                        placeholder="Cari ATM & ID ATM..."
                        className="me-1"
                        // value={searchTerm}
                        onChange={(e) => {
                          setSearchTerm(e.target.value);
                        }}
                      />
                    </Col>
                    {user.role === "admin" && (
                      <Col
                        style={{
                          minWidth: "125px",
                          height: "54px",
                          padding: "0 3px 0 0",
                        }}
                      >
                        <InputGroup>
                          <Select
                            className="me-1"
                            id="state"
                            placeholder="Semua Kantor Cabang"
                            styles={{
                              ...customStyles,
                              input: (provided) => ({
                                ...provided,
                                minHeight: "36px",
                              }),
                            }}
                            value={selectedCustomer}
                            onChange={handleSelectCustomer}
                            options={[
                              {
                                value: "",
                                label: "Semua Kantor Cabang",
                              },
                              ...customers.map((user) => ({
                                value: user.id_pelanggan,
                                label: user.name,
                              })),
                            ]}
                          />
                        </InputGroup>
                      </Col>
                    )}
                  </Row>
                </Form.Group>
              </div>
            </Form>
          </Row>
          <LaporanInformationCard />
        </Card.Header>

        <Card.Body style={{ overflowX: "auto", display: "visible" }}>
          {isLoading ? (
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ height: "300px" }}
            >
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </div>
          ) : (
            <>
              <Table
                id="export-table"
                style={{
                  minWidth: "100%",
                  borderCollapse: "collapse",
                  border: "1px solid #ccc",
                }}
              >
                <colgroup>
                  <col style={{ borderRight: "1px solid #ccc" }} />
                  <col style={{ borderRight: "1px solid #ccc" }} />
                  <col style={{ borderRight: "1px solid #ccc" }} />
                  <col style={{ borderRight: "1px solid #ccc" }} />
                  {Array.from({ length: daysInRange * 3 }, (_, index) => (
                    <col
                      key={index}
                      style={{ borderRight: "1px solid #ccc" }}
                    />
                  ))}
                </colgroup>
                <thead>
                  <tr>
                    <th
                      style={{
                        padding: "8px",
                        textAlign: "center",
                        fontWeight: "bold",
                        borderBottom: "1px solid #ccc",
                        verticalAlign: "middle",
                      }}
                      colSpan={5}
                    >
                      INFORMASI ATM
                    </th>
                    <th
                      style={{
                        padding: "6px",
                        textAlign: "center",
                        fontWeight: "bold",
                        borderBottom: "1px solid #ccc",
                        borderRight: "1px solid #ccc",
                      }}
                      colSpan={daysInRange ? daysInRange * 3 : 0}
                    >
                      TANGGAL & KUNJUNGAN {startDate} - {endDate}
                    </th>
                  </tr>
                  <tr>
                    <th
                      style={{
                        padding: "8px",
                        textAlign: "center",
                        fontWeight: "bold",
                        borderBottom: "1px solid #ccc",
                        verticalAlign: "middle",
                      }}
                      rowSpan={2}
                    >
                      No
                    </th>
                    <th
                      style={{
                        padding: "8px",
                        textAlign: "center",
                        fontWeight: "bold",
                        borderBottom: "1px solid #ccc",
                        verticalAlign: "middle",
                      }}
                      rowSpan={2}
                    >
                      ID ATM
                    </th>
                    <th
                      style={{
                        padding: "8px",
                        textAlign: "center",
                        fontWeight: "bold",
                        borderBottom: "1px solid #ccc",
                        verticalAlign: "middle",
                      }}
                      rowSpan={2}
                    >
                      NAMA ATM
                    </th>
                    <th
                      style={{
                        padding: "8px",
                        textAlign: "center",
                        fontWeight: "bold",
                        borderBottom: "1px solid #ccc",
                        verticalAlign: "middle",
                      }}
                      rowSpan={2}
                    >
                      KANTOR CABANG
                    </th>
                    <th
                      style={{
                        padding: "8px",
                        textAlign: "center",
                        fontWeight: "bold",
                        borderBottom: "1px solid #ccc",
                        verticalAlign: "middle",
                      }}
                      rowSpan={2}
                    >
                      Ring
                    </th>
                    {generateDateLabels(startDate, daysInRange).map(
                      (label, index) => (
                        <th
                          style={{
                            padding: "6px",
                            textAlign: "center",
                            borderBottom: "1px solid #ccc",
                            borderRight: "1px solid #ccc",
                          }}
                          key={index}
                          colSpan={3}
                        >
                          {label}
                        </th>
                      )
                    )}
                  </tr>
                  <tr>
                    {Array.from({ length: daysInRange }, (_, index) => (
                      <React.Fragment key={index}>
                        <th
                          style={{
                            padding: "6px",
                            textAlign: "center",
                            borderBottom: "1px solid #ccc",
                            borderRight: "1px solid #ccc",
                          }}
                        >
                          1
                        </th>
                        <th
                          style={{
                            padding: "6px",
                            textAlign: "center",
                            borderBottom: "1px solid #ccc",
                            borderRight: "1px solid #ccc",
                          }}
                        >
                          2
                        </th>
                        <th
                          style={{
                            padding: "6px",
                            textAlign: "center",
                            borderBottom: "1px solid #ccc",
                            borderRight: "1px solid #ccc",
                          }}
                        >
                          3
                        </th>
                      </React.Fragment>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {reportExports.map((item, index) => {
                    const { asset, laporans } = item;
                    const { kode, pelanggan, name, ring, just_on_holiday } =
                      asset;

                    return (
                      <tr key={index}>
                        {/* Asset details */}
                        <td
                          style={{
                            padding: "8px",
                            borderBottom: "1px solid #ccc",
                            borderRight: "1px solid #ccc",
                          }}
                        >
                          {index + 1}
                        </td>
                        <td
                          style={{
                            padding: "8px",
                            borderBottom: "1px solid #ccc",
                            borderRight: "1px solid #ccc",
                            whiteSpace: "wrap",
                          }}
                        >
                          {kode.replaceAll(",", " ")}
                        </td>
                        <td
                          style={{
                            padding: "8px",
                            borderBottom: "1px solid #ccc",
                            borderRight: "1px solid #ccc",
                          }}
                        >
                          {name}
                        </td>
                        <td
                          style={{
                            padding: "8px",
                            borderBottom: "1px solid #ccc",
                            borderRight: "1px solid #ccc",
                          }}
                        >
                          {pelanggan?.name ? pelanggan.name : "KC Dihapus"}
                        </td>
                        <td
                          style={{
                            padding: "8px",
                            borderBottom: "1px solid #ccc",
                            borderRight: "1px solid #ccc",
                          }}
                        >
                          Ring {ring}
                        </td>

                        {/* Loop through each day in the range */}
                        {Array.from({ length: daysInRange }, (_, dayIndex) => {
                          const date = moment(startDate).add(dayIndex, "days");
                          const dateString = date.format("YYYY-MM-DD");
                          const dataLaporan = laporans[dateString] || [];

                          // Render cells for each kunjungan in a day
                          const cells = Array.from(
                            { length: 3 },
                            (_, laporanIndex) => {
                              let backgroundColor = "#FF0000";
                              if (!laporans[dateString] && just_on_holiday)
                                backgroundColor = "#FFFF00";
                              if (laporanIndex >= parseInt(ring))
                                backgroundColor = "#000000";

                              const laporan = dataLaporan[laporanIndex];

                              if (laporan && laporan.is_temuan !== undefined) {
                                backgroundColor = laporan.is_temuan
                                  ? "#0070C0"
                                  : "#92D050";
                              }

                              return (
                                <td
                                  key={laporanIndex}
                                  style={{
                                    padding: "8px",
                                    backgroundColor,
                                    color: "#000000",
                                    fontSize: "10px",
                                    border: " solid #ccc",
                                  }}
                                >
                                  {laporan && laporan.id_laporan ? (
                                    <a
                                      href={`report-detail/${laporan.id_laporan}`}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      style={{
                                        color: "#000000",
                                        fontSize: "10px",
                                      }}
                                    >
                                      {laporan.date_done
                                        ? moment
                                            .utc(laporan.date_done)
                                            .format("HH:mm")
                                        : ""}
                                    </a>
                                  ) : null}
                                </td>
                              );
                            }
                          );

                          return cells;
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </>
          )}
        </Card.Body>
      </Card>
    </div>
  );
};
