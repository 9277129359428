import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, HashRouter } from "react-router-dom";
import useUserStore from "./zustand/useUserStore";

// core styles
import "./scss/volt.scss";
import "./scss/index.css";

// vendor styles
import "react-datetime/css/react-datetime.css";

import HomePage from "./pages/HomePage";
import ScrollToTop from "./components/ScrollToTop";
import { AppContext } from "./context/appContext";
import { ConfigProvider } from "antd";
ReactDOM.render(
  // value={{ user: "Bagus Nurhuda" }}
  <ConfigProvider
    theme={{
      token: {
        // Seed Token
        colorPrimary: "#f76617",
      },
    }}
  >
    <BrowserRouter>
      <AppContext.Provider>
        <ScrollToTop />
        <HomePage />
      </AppContext.Provider>
    </BrowserRouter>
  </ConfigProvider>,
  document.getElementById("root")
);
