import { Nav } from "@themesberg/react-bootstrap";
import React from "react";
import { Link } from "react-router-dom";

const tabFactory = {
  infografis: [
    { label: "Report Summary", path: "/report-summary" },
    { label: "Asset Summary", path: "/asset-summary" },
    { label: "Operator Summary", path: "/operator-summary" },
  ],
  user: [
    { label: "Pengguna", path: "/user" },
    { label: "History Pengguna", path: "/user/history" },
  ],
  operator: [
    { label: "Cari ATM", path: "/operator/laporan" },
    { label: "Riwayat Laporan", path: "/operator/laporan/view" },
  ],
};

const NavTabs = ({ activeTab, onTabChange, sideTab }) => {
  const tabs = tabFactory[sideTab] ? tabFactory[sideTab] : [];
  return (
    <div className="overflow-auto">
      <ul className="nav nav-tabs d-flex flex-nowrap">
        {tabs.map((tab, index) => (
          <li className="nav-item" key={index}>
            <Nav.Link
              className={`nav-link ${activeTab === tab.path ? "active" : ""}`}
              to={tab.path}
              as={Link}
            >
              {tab.label}
            </Nav.Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default NavTabs;
