import { api, handleApiResponse } from "./api";
import { getLoginData } from "./authSvc";
const { user } = getLoginData();

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getUser(config) {
    return api.get("/api/v1/user", config).catch(handleApiResponse);
  },

  updateUserAll(id, config) {
    if (user.role === "admin") {
      return api
        .put(`/api/v1/user/admin/${id}`, { ...config })
        .catch(handleApiResponse);
    } else {
      return api
        .put(`/api/v1/user/admincabang/${id}`, { ...config })
        .catch(handleApiResponse);
    }
  },

  updateUserPasswordById(id, config) {
    return api
      .put(`/api/v1/user/password/${id}`, config)
      .catch(handleApiResponse);
  },

  updateActivate(id) {
    return api.put(`/api/v1/user/activate/${id}`).catch(handleApiResponse);
  },

  updatePasswordByEmail(id) {
    return api.put(`/api/v1/user/byemail/${id}`).catch(handleApiResponse);
  },

  loginUser(config) {
    return api.post("/api/v1/login", config);
  },

  registUser(config) {
    return api.post("/api/v1/signup", { ...config }).catch(handleApiResponse);
  },

  getUserById(id) {
    return api.get(`/api/v1/user/${id}`).catch(handleApiResponse);
  },

  removeUserInUserAssetById(id) {
    return api.put(`/api/v1/user/${id}/userasset`).catch(handleApiResponse);
  },

  refreshToken() {
    return api.post("/api/v1/auth/refresh").catch(handleApiResponse);
  },
};
