import { api, handleApiResponse } from "./api";

export default {

  getAllLogs(config) {
    return api.get(`/api/v1/logs`, config).catch(handleApiResponse);
  },


};
