import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUnlockAlt,
  faUser,
  faEye,
  faEyeSlash,
} from "@fortawesome/free-solid-svg-icons";

import {
  Col,
  Row,
  Form,
  Button,
  Container,
  InputGroup,
} from "@themesberg/react-bootstrap";
import { useHistory } from "react-router-dom";
import GmeLogo from "../../assets/img/icons/gme-icon.svg";
import BgImage from "../../assets/img/illustrations/signin.svg";
import userSvc from "../../services/userSvc";
import Swal from "sweetalert2";
import saveLoginData from "../../services/authSvc";
import useUserStore from "../../zustand/useUserStore";
import moment from "moment";
import {
  hideLoadingAlert,
  showErrorAlert,
  showLoadingAlert,
  showWarningAlert,
} from "../../utils/SwalUtil";
import { useContext } from "react";
import { AppContext } from "../../context/appContext";
import useAuthStore from "../../zustand/useUserStore";

export default () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const history = useHistory();
  const [showPassword, setShowPassword] = useState(false);
  const useStore = useAuthStore();

  const currentYear = moment().get("year");

  // console.log("context in signin", context);
  const handleLogin = async () => {
    try {
      showLoadingAlert("Loading...", "Sedang melakukan login...");

      const resp = await userSvc.loginUser({
        email: email,
        password: password,
      });

      if (resp && resp.status === 200) {
        saveLoginData(resp.data.user, resp.data.token);
        // console.log(resp.data.user);
        // useStore.login(resp.data.user);
        hideLoadingAlert();
        history.push("/");
        // window.location.reload();
      }
    } catch (error) {
      // console.log({ error });
      let message = error.response?.data?.message || "Gagal login";
      showErrorAlert("Gagal", message);
    }
  };

  return (
    <main>
      <section className="d-flex align-items-center my-5 mt-lg-6 mb-lg-5">
        <Container>
          <Row
            className="justify-content-center form-bg-image"
            style={{ backgroundImage: `url(${BgImage})` }}
          >
            <Col
              xs={12}
              className="d-flex align-items-center justify-content-center"
            >
              <div className="bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-500">
                <div className="text-center text-md-center mb-4 mt-md-0">
                  <img
                    src={GmeLogo}
                    alt="logo"
                    className="m-auto"
                    style={{ width: "100px" }}
                  />
                  <h4 className="mb-1">GME Monitoring</h4>
                </div>
                <Form
                  className="mt-4"
                  onSubmit={(e) => {
                    e.preventDefault();
                    handleLogin();
                  }}
                >
                  {/* {loginError && (
                    <div className="text-danger mb-3">{loginError}</div>
                  )} */}
                  <Form.Group id="email" className="mb-4">
                    <Form.Label>Email</Form.Label>
                    <InputGroup>
                      <InputGroup.Text>
                        <FontAwesomeIcon icon={faUser} />
                      </InputGroup.Text>
                      <Form.Control
                        autoFocus
                        required
                        type="email"
                        placeholder="Masukkan email anda..."
                        value={email}
                        onChange={(e) =>
                          setEmail(e.target.value.toLocaleLowerCase())
                        }
                      />
                    </InputGroup>
                  </Form.Group>

                  <Form.Group>
                    <Form.Group id="password" className="mb-5">
                      <Form.Label>Password</Form.Label>
                      <InputGroup>
                        <InputGroup.Text>
                          <FontAwesomeIcon icon={faUnlockAlt} />
                        </InputGroup.Text>
                        <Form.Control
                          required
                          type={showPassword ? "text" : "password"}
                          placeholder="Masukkan password anda..."
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                        />
                        <InputGroup.Text
                          onClick={() => setShowPassword(!showPassword)}
                          style={{ cursor: "pointer" }}
                          className="border bg-primary text-white"
                        >
                          <FontAwesomeIcon
                            icon={showPassword ? faEyeSlash : faEye}
                          />
                        </InputGroup.Text>
                      </InputGroup>
                      {/* <InputGroup>
                        <InputGroup.Text>
                          <FontAwesomeIcon icon={faUnlockAlt} />
                        </InputGroup.Text>
                        <Form.Control
                          required
                          type={showPassword ? "text" : "password"}
                          placeholder="Input your password here..."
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                        />
                        <InputGroup.Text
                          onClick={() => setShowPassword(!showPassword)}
                          style={{ cursor: "pointer" }}
                          className="border-right-1 bg-primary text-white"
                        >
                          <FontAwesomeIcon
                            icon={showPassword ? faEyeSlash : faEye}
                          />
                        </InputGroup.Text>
                      </InputGroup> */}
                    </Form.Group>

                    {/* <div className="d-flex justify-content-between align-items-center mb-4">
											<Card.Link className="small text-end">Forgot password?</Card.Link>
										</div> */}
                  </Form.Group>
                  <Button type="submit" className="w-100 mt-1">
                    Masuk
                  </Button>
                  <Row className="justify-content-center">
                    <Col
                      xs={12}
                      lg={6}
                      className="mb-1 d-flex justify-content-center text-muted mt-5"
                      style={{ fontSize: "12px" }}
                    >
                      JWA Software © {`${currentYear} `}
                    </Col>
                  </Row>
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </main>
  );
};
