import { api, handleApiResponse } from "./api";

export default {
  generateEmrV2(config) {
    return api.post("/api/v1/emrv2", config).catch(handleApiResponse);
  },

  validateEmrV2(config) {
    return api.post("/api/v1/emrv2/validate", config).catch(handleApiResponse);
  },

  addReportEmrV2(config) {
    return api.post(`/api/v1/emrv2/laporan`, config).catch(handleApiResponse);
  },

  getAllEmrV2(config) {
    return api.get(`/api/v1/emrv2`, config).catch(handleApiResponse);
  },

  deleteEmrV2(id) {
    return api.delete(`/api/v1/emrv2/${id}`).catch(handleApiResponse);
  },
};
