import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import Datetime from "react-datetime";
import moment from "moment";
import { saveAs } from "file-saver";
import ExcelJS from "exceljs";

import {
  Col,
  Row,
  Button,
  Card,
  Table,
  Form,
  InputGroup,
  Spinner,
} from "@themesberg/react-bootstrap";
import reportSvc from "../services/reportSvc";

import { getLoginData } from "../services/authSvc";
import customerSvc from "../services/customerSvc";
import { customStyles } from "../utils/SelectUtils";
import Select from "react-select";
import LaporanInformationCard from "../components/LaporanInformationCard";
moment.tz.setDefault("Asia/Bangkok");

export default () => {
  const [isLoading, setIsLoading] = useState(false);
  const [startDate, setStartDate] = useState(moment().format("YYYY-MM"));
  const [year, setYear] = useState(moment().format("YYYY"));
  const [month, setMonth] = useState(moment().format("MM"));
  const [reportExports, setReportExport] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [currentMonth, setCurrentMonth] = useState(moment().month());
  const [daysInMonth, setDaysInMonth] = useState(moment().daysInMonth());
  const [selectedCell, setSelectedCell] = useState(null);

  const exportToExcel = () => {
    const table = document.getElementById("export-table1");

    // Create a new workbook object
    const workbook = new ExcelJS.Workbook();

    // Add a worksheet to the workbook
    const worksheet = workbook.addWorksheet("Sheet1");
    // Set alignment settings for merged cells
    const mergedCellStyles = {
      alignment: {
        horizontal: "center",
        vertical: "middle",
      },
      border: {
        top: { style: "thin" },
        bottom: { style: "thin" },
        left: { style: "thin" },
        right: { style: "thin" },
      },
    };

    // Loop through each row in the table
    for (let row = 0; row < table.rows.length; row++) {
      // Loop through each cell in the row
      for (let col = 0; col < table.rows[row].cells.length; col++) {
        const tableCell = table.rows[row].cells[col];

        // Get the cell value and background color
        const cellValue = tableCell.innerText;
        if (cellValue) {
          worksheet.getColumn(col + 1).width = 6.5;
        } else {
        }
        const backgroundColor = getComputedStyle(tableCell).backgroundColor;

        if (backgroundColor !== "rgba(0, 0, 0, 0)") {
          // Get the ARGB value of the background color
          const argbColor = convertColorToARGB(backgroundColor);

          // Set the cell style with the background color
          const cellStyle = {
            fill: {
              type: "pattern",
              pattern: "solid",
              fgColor: { argb: argbColor },
              // fgColor: { argb: `FFFFFFFF` },
            },
            border: {
              top: { style: "thin" },
              bottom: { style: "thin" },
              left: { style: "thin" },
              right: { style: "thin" },
            },
            alignment: {
              horizontal: "center",
              vertical: "middle",
            },
          };

          // Set the cell value and style in the worksheet
          worksheet.getCell(`${getColumnName(col + 1)}${row + 1}`).value =
            cellValue;
          worksheet.getCell(`${getColumnName(col + 1)}${row + 1}`).style =
            cellStyle;
        } else {
          // Set the cell value without background color
          const cellStyle = {
            fill: {
              type: "pattern",
              pattern: "solid",
              fgColor: { argb: `FFFFFFFF` },
            },
            border: {
              top: { style: "thin" },
              bottom: { style: "thin" },
              left: { style: "thin" },
              right: { style: "thin" },
            },
          };
          worksheet.getCell(`${getColumnName(col + 1)}${row + 1}`).value =
            cellValue;
          worksheet.getCell(`${getColumnName(col + 1)}${row + 1}`).style =
            cellStyle;
        }
      }
    }

    for (let col = 6; col < table.rows[1].cells.length; col++) {
      let hasValue = false;
      for (let row = 3; row < table.rows.length; row++) {
        const tableCell = table.rows[row].cells[col];
        const cellValue = tableCell.innerText;
        if (cellValue) {
          hasValue = true;
          break;
        } else {
          const column = worksheet.getColumn(col + 1);
          column.width = 2.7;
        }
      }

      if (hasValue) {
        const column = worksheet.getColumn(col + 1);
        column.width = 6.5;
      }
    }

    // Set the width of specific columns
    const columnWidths = [
      { width: 5 }, // Column NO
      { width: 15 }, // Column ID ATM
      { width: 30 }, // Column NAMA ATM
      { width: 27 }, // Column KC
      { width: 9 },
      { width: 30 },
      // Add more objects for other columns
    ];

    // Loop through the columnWidths array and apply the width to the corresponding columns
    columnWidths.forEach((columnWidth, index) => {
      worksheet.getColumn(index + 1).width = columnWidth.width;
    });

    worksheet.views = [
      {
        zoomScale: 60, // Zoom level in percentage (100% is the default)
        zoomScaleNormal: 100, // Zoom level in percentage for normal view (100% is the default)
        zoomScalePageLayoutView: 100, // Zoom level in percentage for page layout view (100% is the default)
        zoomScaleSheetLayoutView: 100, // Zoom level in percentage for sheet layout view (100% is the default)
      },
    ];

    // Merge cells in rows

    const lineInformasiATM = 1;
    const lineIDATM = 2;
    const lineKunjungan = 3;

    // merge first row
    worksheet.mergeCells(`A${lineInformasiATM}:F${lineInformasiATM}`);
    worksheet.getCell(`A${lineInformasiATM}`).style = mergedCellStyles;

    const columnLine1 = daysInMonth === 31 ? "CU" : "CR";
    worksheet.mergeCells(
      `G${lineInformasiATM}:${columnLine1}${lineInformasiATM}`
    );
    worksheet.getCell(`G${lineInformasiATM}`).style = mergedCellStyles;

    //merge second row
    worksheet.mergeCells(`A${lineIDATM}:A${lineKunjungan}`);
    worksheet.getCell(`A${lineIDATM}`).style = mergedCellStyles;

    worksheet.mergeCells(`B${lineIDATM}:B${lineKunjungan}`);
    worksheet.getCell(`B${lineIDATM}`).style = mergedCellStyles;

    worksheet.mergeCells(`C${lineIDATM}:C${lineKunjungan}`);
    worksheet.getCell(`C${lineIDATM}`).style = mergedCellStyles;

    worksheet.mergeCells(`D${lineIDATM}:D${lineKunjungan}`);
    worksheet.getCell(`D${lineIDATM}`).style = mergedCellStyles;

    worksheet.mergeCells(`E${lineIDATM}:E${lineKunjungan}`);
    worksheet.getCell(`E${lineIDATM}`).style = mergedCellStyles;

    worksheet.mergeCells(`F${lineIDATM}:F${lineKunjungan}`);
    worksheet.getCell(`F${lineIDATM}`).style = mergedCellStyles;

    const startDay = 1;
    const endDay = daysInMonth;
    const startColumnIndex = 6; // Column G
    const columnsPerDay = 3;

    const getColumnRange = (columnIndex) => {
      let dividend = columnIndex + 1;
      let columnName = "";

      while (dividend > 0) {
        let modulo = (dividend - 1) % 26;
        columnName = String.fromCharCode(65 + modulo) + columnName;
        dividend = Math.floor((dividend - modulo) / 26);
      }

      return columnName;
    };

    for (let day = startDay; day <= endDay; day++) {
      const startColumnIndexForDay =
        startColumnIndex + (day - 1) * columnsPerDay;
      const endColumnIndexForDay = startColumnIndexForDay + columnsPerDay - 1;

      const startColumn = getColumnRange(startColumnIndexForDay);
      const endColumn = getColumnRange(endColumnIndexForDay);
      const range = `${startColumn}${lineIDATM}:${endColumn}${lineIDATM}`;

      worksheet.mergeCells(range);
      worksheet.getCell(`${startColumn}${lineIDATM}`).style = mergedCellStyles;

      const dayIndex = day - startDay + 1;
      const cellAddress = `${startColumn}${lineIDATM}`;
      worksheet.getCell(cellAddress).value = `T ${dayIndex}`;
    }

    // Generate the Excel file as a buffer
    workbook.xlsx.writeBuffer().then((buffer) => {
      // Create a blob from the buffer
      const blob = new Blob([buffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      // Generate the filename based on the selected customer or all customers
      let customerName;
      if (selectedCustomer !== null) {
        // Specific customer is selected
        customerName =
          reportExports.length > 0 ? reportExports[0].asset.pelanggan.name : "";
      } else {
        // All customers
        customerName = "all_customers";
      }

      const yearMonth = `${year}-${month.toString().padStart(2, "0")}`;
      const fileName = `laporan_${customerName}_${yearMonth}_downloaded_${moment().format(
        "YYYY-MM-DD_HH-mm-ss"
      )}.xlsx`;

      // Save the Excel file with the custom name
      saveAs(blob, fileName);
    });
  };

  // Helper function to convert CSS color to ARGB format
  const convertColorToARGB = (color) => {
    const rgbaValues = color.match(/\d+/g);
    const [r, g, b] = rgbaValues.slice(0, 3);
    return `FF${toHex(r)}${toHex(g)}${toHex(b)}`;
  };

  // Helper function to convert decimal value to 2-digit hexadecimal value
  const toHex = (value) => {
    const hex = Number(value).toString(16);
    return hex.length === 1 ? "0" + hex : hex;
  };

  // Helper function to get the column name based on the column index
  const getColumnName = (colIndex) => {
    let columnName = "";
    while (colIndex > 0) {
      const remainder = (colIndex - 1) % 26;
      columnName = String.fromCharCode(65 + remainder) + columnName;
      colIndex = Math.floor((colIndex - 1) / 26);
    }
    return columnName;
  };

  const { user } = getLoginData();

  const fetchCustomers = async () => {
    if (user?.role === "admin") {
      const resp = await customerSvc.getCustomer({
        params: {
          page: 0,
          limit: 1000,
        },
      });
      if (resp.status === 200) {
        setCustomers(resp.data.data);
      }
    }
  };

  const handleStartDateChange = (date) => {
    setIsLoading(true);
    const selectedMonth = date.month();
    const selectedYear = date.year();
    const selectedDaysInMonth = moment()
      .year(selectedYear)
      .month(selectedMonth)
      .daysInMonth();

    setStartDate(date.format("YYYY-MM"));
    setMonth(date.format("MM"));
    setYear(date.format("YYYY"));
    setCurrentMonth(selectedMonth);
    setDaysInMonth(selectedDaysInMonth);
    setIsLoading(false);
  };

  const fetchReportsExport = async () => {
    try {
      setIsLoading(true);
      const respdata = await reportSvc.getReportExport({
        params: {
          year: year,
          month: month,
          id_pelanggan:
            selectedCustomer?.length > 0
              ? selectedCustomer.map((customer) => customer.value)
              : "",
        },
      });
      if (respdata.status === 200) {
        setReportExport(respdata.data.data);
      }

      setIsLoading(false);
    } catch (err) {}
  };

  useEffect(() => {
    fetchCustomers();
  }, []);

  useEffect(() => {
    fetchReportsExport();
  }, [startDate, selectedCustomer]);

  const handleSelectCustomer = (selectedOption) => {
    setSelectedCustomer(selectedOption);
  };

  return (
    <>
      <div className="align-items-center py-4 w-100">
        <Card border="light" className="shadow-sm">
          <Card.Header>
            <h5>Download Laporan</h5>
            <hr />
            <Row className="align-items-center">
              <Form>
                <div className="mb-2">
                  <Form.Group id="range">
                    <Form.Label className="text-primary fs-6">
                      Pilih Bulan
                    </Form.Label>
                    <Row style={{ width: "100%" }}>
                      <Col>
                        <Datetime
                          timeFormat={false}
                          dateFormat="MM"
                          style={{ height: "1px" }}
                          onChange={handleStartDateChange}
                          renderInput={(props, openCalendar) => (
                            <InputGroup>
                              <InputGroup.Text>
                                <FontAwesomeIcon icon={faCalendarAlt} />
                              </InputGroup.Text>
                              <Form.Control
                                required
                                type="text"
                                value={
                                  startDate
                                    ? moment(startDate).format("YYYY-MM")
                                    : ""
                                }
                                placeholder="Tahun - Bulan"
                                onFocus={openCalendar}
                                onChange={() => {}}
                              />
                            </InputGroup>
                          )}
                        />
                      </Col>
                      {user.role == "admin" && (
                        <Col>
                          <InputGroup>
                            <Select
                              className="me-1"
                              id="state"
                              placeholder="Semua Kantor Cabang"
                              styles={{
                                ...customStyles,
                                input: (provided) => ({
                                  ...provided,
                                  minHeight: "36px",
                                }),
                              }}
                              value={selectedCustomer}
                              onChange={handleSelectCustomer}
                              options={customers.map((user) => ({
                                value: user.id_pelanggan,
                                label: user.name,
                              }))}
                              isMulti
                            />
                          </InputGroup>
                        </Col>
                      )}
                      <Col className="text-start">
                        <Button
                          variant="primary"
                          size="md"
                          onClick={exportToExcel}
                        >
                          Download
                        </Button>
                      </Col>
                    </Row>
                  </Form.Group>
                </div>
              </Form>
            </Row>
            <LaporanInformationCard />
          </Card.Header>

          <Card.Body style={{ overflowX: "auto", display: "visible" }}>
            {isLoading ? (
              <div
                className="d-flex justify-content-center align-items-center"
                style={{ height: "300px" }}
              >
                <Spinner animation="border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              </div>
            ) : (
              <Table
                id="export-table"
                style={{
                  minWidth: "100%",
                  borderCollapse: "collapse",
                  border: "1px solid #ccc",
                }}
              >
                <colgroup>
                  <col style={{ borderRight: "1px solid #ccc" }} />
                  <col style={{ borderRight: "1px solid #ccc" }} />
                  <col style={{ borderRight: "1px solid #ccc" }} />
                  <col style={{ borderRight: "1px solid #ccc" }} />
                  <col style={{ borderRight: "1px solid #ccc" }} />
                  {Array.from({ length: daysInMonth * 4 }, (_, index) => (
                    <col
                      key={`col-group ${index}`}
                      style={{ borderRight: "1px solid #ccc" }}
                    />
                  ))}
                </colgroup>
                <thead>
                  <tr>
                    <th
                      style={{
                        padding: "8px",
                        textAlign: "center",
                        fontWeight: "bold",
                        borderBottom: "1px solid #ccc",
                        verticalAlign: "middle",
                      }}
                      colSpan={6}
                    >
                      INFORMASI ATM
                    </th>
                    <th
                      style={{
                        padding: "6px",
                        textAlign: "center",
                        fontWeight: "bold",
                        borderBottom: "1px solid #ccc",
                        borderRight: "1px solid #ccc",
                      }}
                      colSpan={daysInMonth * 3}
                    >
                      TANGGAL & KUNJUNGAN BULAN {startDate}
                    </th>
                  </tr>
                  <tr>
                    <th
                      style={{
                        padding: "8px",
                        textAlign: "center",
                        fontWeight: "bold",
                        borderBottom: "1px solid #ccc",
                        verticalAlign: "middle",
                      }}
                      rowSpan={2}
                    >
                      No
                    </th>
                    <th
                      style={{
                        padding: "8px",
                        textAlign: "center",
                        fontWeight: "bold",
                        borderBottom: "1px solid #ccc",
                        verticalAlign: "middle",
                      }}
                      rowSpan={2}
                    >
                      ID ATM
                    </th>
                    <th
                      style={{
                        padding: "8px",
                        textAlign: "center",
                        fontWeight: "bold",
                        borderBottom: "1px solid #ccc",
                        verticalAlign: "middle",
                      }}
                      rowSpan={2}
                    >
                      NAMA ATM
                    </th>
                    <th
                      style={{
                        padding: "8px",
                        textAlign: "center",
                        fontWeight: "bold",
                        borderBottom: "1px solid #ccc",
                        verticalAlign: "middle",
                      }}
                      rowSpan={2}
                    >
                      KANTOR CABANG
                    </th>
                    <th
                      style={{
                        padding: "8px",
                        textAlign: "center",
                        fontWeight: "bold",
                        borderBottom: "1px solid #ccc",
                        verticalAlign: "middle",
                      }}
                      rowSpan={2}
                    >
                      Ring
                    </th>
                    <th
                      style={{
                        padding: "8px",
                        textAlign: "center",
                        fontWeight: "bold",
                        borderBottom: "1px solid #ccc",
                        verticalAlign: "middle",
                      }}
                      rowSpan={2}
                    >
                      Lokasi
                    </th>
                    {Array.from({ length: daysInMonth }, (_, index) => {
                      return (
                        <React.Fragment key={`day-${index}`}>
                          {[1].map((label, labelIndex) => (
                            <th
                              style={{
                                padding: "6px",
                                textAlign: "center",
                                fontWeight: "bold",
                                borderBottom: "1px solid #ccc",
                                borderRight: "1px solid #ccc",
                              }}
                              key={`${index}${labelIndex}`}
                              colSpan={3}
                            >
                              {labelIndex === 0 ? index + 1 : ""}
                            </th>
                          ))}
                        </React.Fragment>
                      );
                    })}
                  </tr>
                  <tr>
                    {Array.from({ length: daysInMonth }, (_, index) => (
                      <React.Fragment key={`hari-${index}`}>
                        <th
                          style={{
                            padding: "6px",
                            textAlign: "center",
                            borderBottom: "1px solid #ccc",
                            borderRight: "1px solid #ccc",
                          }}
                        >
                          1
                        </th>
                        <th
                          style={{
                            padding: "6px",
                            textAlign: "center",
                            borderBottom: "1px solid #ccc",
                            borderRight: "1px solid #ccc",
                          }}
                        >
                          2
                        </th>
                        <th
                          style={{
                            padding: "6px",
                            textAlign: "center",
                            borderBottom: "1px solid #ccc",
                            borderRight: "1px solid #ccc",
                          }}
                        >
                          3
                        </th>
                      </React.Fragment>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {reportExports.map((item, assetIndex) => {
                    const { asset, laporans } = item;
                    const {
                      kode,
                      pelanggan,
                      name,
                      lokasi,
                      ring,
                      just_on_holiday,
                    } = asset;

                    return (
                      <tr key={`row-${assetIndex}`}>
                        <td
                          style={{
                            padding: "8px",
                            borderBottom: "1px solid #ccc",
                            borderRight: "1px solid #ccc",
                          }}
                        >
                          {assetIndex + 1}
                        </td>
                        <td
                          style={{
                            padding: "8px",
                            borderBottom: "1px solid #ccc",
                            borderRight: "1px solid #ccc",
                            whiteSpace: "wrap",
                          }}
                        >
                          {kode.replaceAll(",", " ")}
                        </td>
                        <td
                          style={{
                            padding: "8px",
                            borderBottom: "1px solid #ccc",
                            borderRight: "1px solid #ccc",
                          }}
                        >
                          {name}
                        </td>
                        <td
                          style={{
                            padding: "8px",
                            borderBottom: "1px solid #ccc",
                            borderRight: "1px solid #ccc",
                          }}
                        >
                          {pelanggan?.name ? pelanggan.name : "KC Dihapus"}
                        </td>
                        <td
                          style={{
                            padding: "8px",
                            borderBottom: "1px solid #ccc",
                            borderRight: "1px solid #ccc",
                          }}
                        >
                          Ring {ring}
                        </td>
                        <td
                          style={{
                            padding: "8px",
                            borderBottom: "1px solid #ccc",
                            borderRight: "1px solid #ccc",
                          }}
                        >
                          {lokasi}
                        </td>

                        {Array.from({ length: daysInMonth }, (_, index) => {
                          const date = moment()
                            .month(currentMonth)
                            .date(index + 1)
                            .format("YYYY-MM-DD");

                          const dataLaporan = laporans[date] || [];

                          const cells = Array.from(
                            { length: 3 },
                            (_, laporanIndex) => {
                              let backgroundColor = "#FF0000"; // Default background color when no data is available
                              if (!laporans[date] && just_on_holiday)
                                backgroundColor = "#FFFF00";
                              if (laporanIndex >= parseInt(ring))
                                backgroundColor = "#000000";

                              const laporan = dataLaporan[laporanIndex];

                              if (laporan && laporan.is_temuan !== undefined) {
                                backgroundColor = laporan.is_temuan
                                  ? "#0070C0"
                                  : "#92D050";
                              }
                              // console.log({ laporanIndex, index });

                              return (
                                <td
                                  key={`${assetIndex}${laporanIndex}${index}`}
                                  style={{
                                    padding: "8px",
                                    backgroundColor,
                                    color: "#000000",
                                    fontSize: "10px",
                                    border: " solid #ccc",
                                  }}
                                >
                                  {laporan && laporan.id_laporan ? (
                                    <a
                                      href={`report-detail/${laporan.id_laporan}`}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      style={{
                                        color: "#000000",
                                        fontSize: "10px",
                                      }}
                                    >
                                      {laporan.date_done
                                        ? moment
                                            .utc(laporan.date_done)
                                            .format("HH:mm")
                                        : ""}
                                    </a>
                                  ) : null}
                                </td>
                              );
                            }
                          );

                          return cells;
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            )}
          </Card.Body>

          <Card.Body style={{ overflowX: "auto", display: "none" }}>
            {isLoading ? (
              <div
                className="d-flex justify-content-center align-items-center"
                style={{ height: "300px" }}
              >
                <Spinner animation="border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              </div>
            ) : (
              <Table
                id="export-table1"
                style={{
                  minWidth: "100%",
                  borderCollapse: "collapse",
                  border: "1px solid #ccc",
                }}
              >
                <colgroup>
                  <col style={{ borderRight: "1px solid #ccc" }} />
                  <col style={{ borderRight: "1px solid #ccc" }} />
                  <col style={{ borderRight: "1px solid #ccc" }} />
                  <col style={{ borderRight: "1px solid #ccc" }} />
                  <col style={{ borderRight: "1px solid #ccc" }} />
                  {Array.from({ length: daysInMonth * 3 }, (_, index) => (
                    <col
                      key={`x${index}`}
                      style={{ borderRight: "1px solid #ccc" }}
                    />
                  ))}
                </colgroup>
                <thead>
                  <tr>
                    <th
                      style={{
                        padding: "8px",
                        textAlign: "center",
                        fontWeight: "bold",
                        borderBottom: "1px solid #ccc",
                        verticalAlign: "middle",
                      }}
                    >
                      INFORMASI ATM
                    </th>
                    <th
                      style={{
                        padding: "8px",
                        textAlign: "center",
                        fontWeight: "bold",
                        borderBottom: "1px solid #ccc",
                        verticalAlign: "middle",
                      }}
                    ></th>
                    <th
                      style={{
                        padding: "8px",
                        textAlign: "center",
                        fontWeight: "bold",
                        borderBottom: "1px solid #ccc",
                        verticalAlign: "middle",
                      }}
                    ></th>
                    <th
                      style={{
                        padding: "8px",
                        textAlign: "center",
                        fontWeight: "bold",
                        borderBottom: "1px solid #ccc",
                        verticalAlign: "middle",
                      }}
                    ></th>
                    <th
                      style={{
                        padding: "8px",
                        textAlign: "center",
                        fontWeight: "bold",
                        borderBottom: "1px solid #ccc",
                        verticalAlign: "middle",
                      }}
                    ></th>
                    <th
                      style={{
                        padding: "8px",
                        textAlign: "center",
                        fontWeight: "bold",
                        borderBottom: "1px solid #ccc",
                        verticalAlign: "middle",
                      }}
                    ></th>
                    {Array.from({ length: daysInMonth }, (_, index) => (
                      <React.Fragment key={`xx-${index}`}>
                        {[1, 2, 3].map((label, labelIndex) => (
                          <th
                            style={{
                              padding: "6px",
                              textAlign: "center",
                              fontWeight: "bold",
                              borderBottom: "1px solid #ccc",
                              borderRight: "1px solid #ccc",
                            }}
                            key={`${index}${labelIndex}`}
                          >
                            {index === 0 && labelIndex === 0
                              ? `TANGGAL & KUNJUNGAN BULAN ${startDate}`
                              : ""}
                          </th>
                        ))}
                      </React.Fragment>
                    ))}
                  </tr>
                  <tr>
                    <th
                      style={{
                        padding: "8px",
                        textAlign: "center",
                        fontWeight: "bold",
                        borderBottom: "1px solid #ccc",
                        verticalAlign: "middle",
                      }}
                    >
                      No
                    </th>
                    <th
                      style={{
                        padding: "8px",
                        textAlign: "center",
                        fontWeight: "bold",
                        borderBottom: "1px solid #ccc",
                        verticalAlign: "middle",
                      }}
                    >
                      ID ATM
                    </th>
                    <th
                      style={{
                        padding: "8px",
                        textAlign: "center",
                        fontWeight: "bold",
                        borderBottom: "1px solid #ccc",
                        verticalAlign: "middle",
                      }}
                    >
                      NAMA ATM
                    </th>
                    <th
                      style={{
                        padding: "8px",
                        textAlign: "center",
                        fontWeight: "bold",
                        borderBottom: "1px solid #ccc",
                        verticalAlign: "middle",
                      }}
                    >
                      KANTOR CABANG
                    </th>
                    <th
                      style={{
                        padding: "8px",
                        textAlign: "center",
                        fontWeight: "bold",
                        borderBottom: "1px solid #ccc",
                        verticalAlign: "middle",
                      }}
                    >
                      Ring
                    </th>
                    <th
                      style={{
                        padding: "8px",
                        textAlign: "center",
                        fontWeight: "bold",
                        borderBottom: "1px solid #ccc",
                        verticalAlign: "middle",
                      }}
                    >
                      Lokasi
                    </th>
                    {Array.from({ length: daysInMonth }, (_, index) => (
                      <React.Fragment key={`xxx-${index}`}>
                        {[1, 2, 3].map((label, labelIndex) => (
                          <th
                            style={{
                              padding: "6px",
                              textAlign: "center",
                              fontWeight: "bold",
                              borderBottom: "1px solid #ccc",
                              borderRight: "1px solid #ccc",
                            }}
                            key={`xxxx-${labelIndex}`}
                          >
                            {labelIndex === 0 ? index + 1 : ""}
                          </th>
                        ))}
                      </React.Fragment>
                    ))}
                  </tr>
                  <tr>
                    <th
                      style={{
                        padding: "8px",
                        textAlign: "center",
                        fontWeight: "bold",
                        borderBottom: "1px solid #ccc",
                        verticalAlign: "middle",
                      }}
                    ></th>
                    <th
                      style={{
                        padding: "8px",
                        textAlign: "center",
                        fontWeight: "bold",
                        borderBottom: "1px solid #ccc",
                        verticalAlign: "middle",
                      }}
                    ></th>
                    <th
                      style={{
                        padding: "8px",
                        textAlign: "center",
                        fontWeight: "bold",
                        borderBottom: "1px solid #ccc",
                        verticalAlign: "middle",
                      }}
                    ></th>
                    <th
                      style={{
                        padding: "8px",
                        textAlign: "center",
                        fontWeight: "bold",
                        borderBottom: "1px solid #ccc",
                        verticalAlign: "middle",
                      }}
                    ></th>
                    <th
                      style={{
                        padding: "8px",
                        textAlign: "center",
                        fontWeight: "bold",
                        borderBottom: "1px solid #ccc",
                        verticalAlign: "middle",
                      }}
                    ></th>
                    <th
                      style={{
                        padding: "8px",
                        textAlign: "center",
                        fontWeight: "bold",
                        borderBottom: "1px solid #ccc",
                        verticalAlign: "middle",
                      }}
                    ></th>
                    {Array.from({ length: daysInMonth }, (_, index) => (
                      <React.Fragment key={`xxxxx-${index}`}>
                        <th
                          style={{
                            padding: "6px",
                            textAlign: "center",
                            borderBottom: "1px solid #ccc",
                            borderRight: "1px solid #ccc",
                          }}
                        >
                          1
                        </th>
                        <th
                          style={{
                            padding: "6px",
                            textAlign: "center",
                            borderBottom: "1px solid #ccc",
                            borderRight: "1px solid #ccc",
                          }}
                        >
                          2
                        </th>
                        <th
                          style={{
                            padding: "6px",
                            textAlign: "center",
                            borderBottom: "1px solid #ccc",
                            borderRight: "1px solid #ccc",
                          }}
                        >
                          3
                        </th>
                      </React.Fragment>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {reportExports.map((item, index) => {
                    const { asset, laporans } = item;
                    const {
                      kode,
                      pelanggan,
                      name,
                      lokasi,
                      ring,
                      just_on_holiday,
                    } = asset;

                    return (
                      <tr key={index}>
                        <td
                          style={{
                            padding: "8px",
                            borderBottom: "1px solid #ccc",
                            borderRight: "1px solid #ccc",
                          }}
                        >
                          {index + 1}
                        </td>
                        <td
                          style={{
                            padding: "8px",
                            borderBottom: "1px solid #ccc",
                            borderRight: "1px solid #ccc",
                            whiteSpace: "wrap",
                          }}
                        >
                          {kode.replaceAll(",", " ")}
                        </td>
                        <td
                          style={{
                            padding: "8px",
                            borderBottom: "1px solid #ccc",
                            borderRight: "1px solid #ccc",
                          }}
                        >
                          {name}
                        </td>
                        <td
                          style={{
                            padding: "8px",
                            borderBottom: "1px solid #ccc",
                            borderRight: "1px solid #ccc",
                          }}
                        >
                          {pelanggan?.name ? pelanggan.name : "KC Dihapus"}
                        </td>
                        <td
                          style={{
                            padding: "8px",
                            borderBottom: "1px solid #ccc",
                            borderRight: "1px solid #ccc",
                          }}
                        >
                          Ring {ring}
                        </td>
                        <td
                          style={{
                            padding: "8px",
                            borderBottom: "1px solid #ccc",
                            borderRight: "1px solid #ccc",
                          }}
                        >
                          {lokasi}
                        </td>

                        {Array.from({ length: daysInMonth }, (_, index) => {
                          const date = moment()
                            .month(currentMonth)
                            .date(index + 1)
                            .format("YYYY-MM-DD");

                          const dataLaporan = laporans[date] || [];

                          const cells = Array.from(
                            { length: 3 },
                            (_, laporanIndex) => {
                              let backgroundColor = "#FF0000"; // Default background color when no data is available
                              if (!laporans[date] && just_on_holiday)
                                backgroundColor = "#FFFF00";
                              if (laporanIndex >= parseInt(ring))
                                backgroundColor = "#000000";

                              const laporan = dataLaporan[laporanIndex];

                              if (laporan && laporan.is_temuan !== undefined) {
                                backgroundColor = laporan.is_temuan
                                  ? "#0070C0"
                                  : "#92D050";
                              }

                              return (
                                <td
                                  key={laporanIndex}
                                  style={{
                                    padding: "8px",
                                    backgroundColor,
                                    color: "#000000",
                                    fontSize: "10px",
                                    border: " solid #ccc",
                                  }}
                                >
                                  {laporan && laporan.id_laporan ? (
                                    <a
                                      href={`report-detail/${laporan.id_laporan}`}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      style={{
                                        color: "#000000",
                                        fontSize: "10px",
                                      }}
                                    >
                                      {laporan.date_done
                                        ? moment
                                            .utc(laporan.date_done)
                                            .format("HH:mm")
                                        : ""}
                                    </a>
                                  ) : null}
                                </td>
                              );
                            }
                          );

                          return cells;
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            )}
          </Card.Body>
        </Card>
      </div>
    </>
  );
};
