import React, { useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

import {
  Button,
  Card,
  Table,
  Spinner,
  Pagination,
  Nav,
  Form,
} from "@themesberg/react-bootstrap";

import { useEffect } from "react";
import Swal from "sweetalert2";

import userSvc from "../services/userSvc";

import customerSvc from "../services/customerSvc";
import userAssetSvc from "../services/userAssetSvc";
import { useHistory } from "react-router-dom";
import {
  showErrorAlert,
  showLoadingAlert,
  showSuccessAlert,
} from "../utils/SwalUtil";

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
  const [isLoading, setIsLoading] = useState(false);
  const [activePage, setActivePage] = useState(1);
  const [startIndex, setStartIndex] = useState(null);
  const [endIndex, setEndIndex] = useState(null);
  const [totalPages, setTotalPages] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [userAsset, setUserAsset] = useState([]);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [totalRows, setTotalRows] = useState("");
  const history = useHistory();

  const renderPaginationItems = () => {
    const paginationItems = [];

    paginationItems.push(
      <Pagination.First
        key="first"
        onClick={() => handlePageChange(1)}
        disabled={activePage === 1}
      />
    );

    paginationItems.push(
      <Pagination.Prev
        key="prev"
        onClick={() => activePage > 1 && handlePageChange(activePage - 1)}
        disabled={activePage === 1}
      />
    );

    for (let i = 1; i <= totalPages; i++) {
      if (
        i === 1 ||
        i === totalPages ||
        (i >= activePage - 2 && i <= activePage + 2)
      ) {
        paginationItems.push(
          <Pagination.Item
            key={i}
            onClick={() => handlePageChange(i)}
            active={i === activePage}
          >
            {i}
          </Pagination.Item>
        );
      } else if (
        (i === activePage - 3 && i > 2) ||
        (i === activePage + 3 && i < totalPages - 1)
      ) {
        paginationItems.push(<Pagination.Ellipsis key={i} />);
      }
    }

    paginationItems.push(
      <Pagination.Next
        key="next"
        onClick={() =>
          activePage < totalPages && handlePageChange(activePage + 1)
        }
        disabled={activePage === totalPages}
      />
    );

    paginationItems.push(
      <Pagination.Last
        key="last"
        onClick={() => handlePageChange(totalPages)}
        disabled={activePage === totalPages}
      />
    );

    return paginationItems;
  };

  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
  };

  const handleItemsPerPageChange = (event) => {
    const newItemsPerPage = parseInt(event.target.value);
    setItemsPerPage(newItemsPerPage);
    setActivePage(1); // Reset halaman aktif ke halaman pertama
  };

  const fetchUserAssets = async (page, limit) => {
    setIsLoading(true);
    const resp = await userAssetSvc.getUserAssets({
      params: {
        page: page - 1,
        limit: limit,
        query: searchTerm,
      },
    });
    if (resp.status === 200) {
      setUserAsset(resp.data.data);
      setStartIndex((activePage - 1) * itemsPerPage);
      setEndIndex(startIndex + itemsPerPage);
      setTotalPages(resp.data.totalPage);
      setTotalRows(resp.data.totalRows);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchUserAssets();
  }, []);

  const handleDelete = async (id) => {
    const willDelete = await Swal.fire({
      title: "Apakah anda yakin?",
      text: "Anda akan menghapus data user aset ini!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes!",
      cancelButtonText: "Cancel",
    });

    if (willDelete.isConfirmed) {
      try {
        showLoadingAlert("Tunggu", "Menghapus data user aset...");
        const response = await userAssetSvc.deleteUserAsset(id);
        fetchUserAssets();
        showSuccessAlert("Berhasil", "Data user aset berhasil dihapus");
      } catch (error) {
        let message =
          error.response?.data?.message || "Gagal menghapus data user aset";
        showErrorAlert("Gagal", message);
      }
    }
  };

  const handleAddUserAsset = () => {
    history.push("/user-asset/add");
  };

  const handleEditUser = async (user) => {
    history.push(`/user-asset/${user}`);
  };

  const handleManageUser = async (user) => {
    history.push(`/user-asset/users/${user}`);
  };

  return (
    <>
      <div className="align-items-center py-4 w-100">
        <Card border="light" className="shadow-sm">
          <Card.Body className="pb-0">
            <Card.Title className="">User Asset</Card.Title>
            <hr />
            <div className="d-flex mb-3">
              <Button
                variant="success"
                size="sm"
                className=""
                style={{ width: "100px" }}
                onClick={handleAddUserAsset}
              >
                <FontAwesomeIcon
                  icon={faPlus}
                  className="me-2"
                  style={{ fontSize: "14px" }}
                />
                Tambah
              </Button>
            </div>

            <Table
              responsive
              className="table-centered table-nowrap rounded mb-0"
            >
              <thead className="thead-light">
                <tr>
                  <th className="border-0">Nama User Asset</th>
                  <th className="border-0">Pelanggan-1</th>
                  <th className="border-0">Pelanggan-2</th>
                  <th className="border-0">Pelanggan-3</th>
                  <th className="border-0">Pelanggan-4</th>
                  <th className="border-0">Pelanggan-5</th>
                  <th className="border-0">Aksi</th>
                </tr>
              </thead>
              {isLoading ? (
                <tr>
                  <td
                    colSpan={5}
                    className="text-center"
                    style={{ height: "300px" }}
                  >
                    <Spinner animation="border" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </Spinner>
                  </td>
                </tr>
              ) : (
                <tbody>
                  {userAsset.map((user) => (
                    <tr key={user.id_user_asset}>
                      <td className="border-0">
                        <span className="fw-bolder">{user.keterangan}</span>
                      </td>
                      <td>{user.pelanggan1 ? user.pelanggan1.name : "-"}</td>
                      <td>{user.pelanggan2 ? user.pelanggan2.name : "-"}</td>
                      <td>{user.pelanggan3 ? user.pelanggan3.name : "-"}</td>
                      <td>{user.pelanggan4 ? user.pelanggan4.name : "-"}</td>
                      <td>{user.pelanggan5 ? user.pelanggan5.name : "-"}</td>
                      <td className="border-0">
                        <Button
                          variant="secondary"
                          size="sm"
                          className="me-2"
                          onClick={(e) => {
                            handleEditUser(user.id_user_asset);
                          }}
                        >
                          Update
                        </Button>
                        <Button
                          variant="danger"
                          size="sm"
                          className="me-2"
                          onClick={(e) => {
                            e.preventDefault();
                            handleDelete(user.id_user_asset);
                          }}
                        >
                          Delete
                        </Button>
                        <Button
                          variant="info"
                          size="sm"
                          onClick={(e) => {
                            e.preventDefault();
                            handleManageUser(user.id_user_asset);
                          }}
                        >
                          Manage Pengguna
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              )}
            </Table>
          </Card.Body>
          <Card.Footer className="px-3 border-0 d-lg-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center">
              <small className="me-2">Limit:</small>
              <Form.Select
                className="me-3"
                style={{ width: "70px" }}
                value={itemsPerPage}
                onChange={handleItemsPerPageChange}
              >
                <option value="5">5</option>
                <option value="10">10</option>
                <option value="15">15</option>
              </Form.Select>
              <small className="fw-bold">
                Menampilkan {startIndex + 1} -{" "}
                {Math.min(startIndex + itemsPerPage, totalRows)} dari{" "}
                {totalRows} User Asset
              </small>
            </div>

            <div className="d-flex align-items-center">
              <Nav className="mx-2">
                <Pagination className="mb-2 mb-lg-0">
                  {renderPaginationItems()}
                </Pagination>
              </Nav>
              {/* <small className="fw-bold">
							Showing <b>{startIndex + 1} - {endIndex}</b> of <b>{assets.length}</b> assets
						</small> */}
            </div>
          </Card.Footer>
        </Card>
      </div>
    </>
  );
};
