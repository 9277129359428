import React, { useState, useEffect } from "react";
import {
  Button,
  Form,
  InputGroup,
  Spinner,
  Card,
  Container,
} from "@themesberg/react-bootstrap";
import { Image } from "antd";
import Swal from "sweetalert2";

import { useHistory, useLocation, useParams } from "react-router-dom";
import EmrSvc from "../../services/EmrSvc";
import ImageTest from "../../assets/img/react-mockup.png";
import docTypeSvc from "../../services/docTypeSvc";

import {
  showErrorAlert,
  showLoadingAlert,
  showSuccessAlert,
} from "../../utils/SwalUtil";
import moment from "moment-timezone";
import reportSvc from "../../services/reportSvc";
import { getLoginData } from "../../services/authSvc";
import ImageWithWatermark from "./CompWatermark";
import {
  addWatermark,
  hideLoadingOverlay,
  resizeAndCompressImage,
  showLoadingOverlay,
} from "../../utils/Helper";
moment.tz.setDefault("Asia/Bangkok");
export default () => {
  const [isValid, setIsValid] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [temuanFiles, setTemuanFiles] = useState([]);
  const [isTemuan, setIsTemuan] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [keterangan, setKeterangan] = useState("");
  // const [asset, setAsset] = useState([]);
  // const [user, setUser] = useState([]);
  // const [idUser, setIdUser] = useState(null);
  // const [idAsset, setIdAsset] = useState(null);
  const [docType, setDocType] = useState([]);
  const [date, setDate] = useState(null);
  const [upload, setUpload] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const [selectedImages, setSelectedImages] = useState({});
  const [selectedTemuans, setSelectedTemuans] = useState({});
  const [notUploadedFiles, setNotUploadedFiles] = useState([]);
  const { user } = getLoginData();
  const history = useHistory();
  const location = useLocation();
  const asset = location?.state?.asset;
  const screen = location?.state?.screen;

  // Use asset and screen in your component

  useEffect(() => {
    console.log({ asset, screen });
  }, [asset, screen]);

  const fetchDocType = async () => {
    setIsLoading(true);
    try {
      const resp = await docTypeSvc.getJenisDokumentasiEmr({
        params: {
          active: true,
        },
      });
      if (resp.status === 200) {
        setDocType(resp.data.data);
      }
    } catch (error) {}
  };

  const handleReportFileUpload = async (files, index, doc) => {
    try {
      showLoadingAlert("Tunggu", "Sedang mengompresi ukuran gambar...");

      const selectedFile = files[0];

      if (selectedFile) {
        const reader = new FileReader();
        reader.onload = async () => {
          const imageDataUrl = reader.result;
          const imageWatermarked = await addWatermark(
            "Website",
            imageDataUrl,
            asset,
            doc.nama_jenis_dokumentasi,
            user
          );

          setSelectedImages((prevImages) => ({
            ...prevImages,
            [index]: URL.createObjectURL(imageWatermarked),
          }));

          const resizedImage = await resizeAndCompressImage(imageWatermarked);

          console.log({ resizedImage });
          const fileData = {
            [index]: resizedImage,
          };

          setUploadedFiles((prevUploadedFiles) => ({
            ...prevUploadedFiles,
            ...fileData,
          }));
        };
        reader.onerror = () => {
          showErrorAlert("Gagal", "Gagal membaca file gambar");
        };
        reader.readAsDataURL(selectedFile);
      }
    } catch (error) {
      console.log({ error });
      showErrorAlert("Gagal", "Ukuran gambar gagal dikompres");
    } finally {
      Swal.close();
    }
  };

  useEffect(() => {
    const isAllFilesFilled = docType.every(
      (doc) => uploadedFiles[doc.id_jenis_dokumentasi]
    );

    console.log({ selectedImages });

    const notUploadedFiles = docType.filter(
      (doc) => !uploadedFiles[doc.id_jenis_dokumentasi]
    );
    setNotUploadedFiles(notUploadedFiles);

    setIsFormValid(isAllFilesFilled);
  }, [uploadedFiles, docType]);

  const handleTemuanFileUpload = async (files, index) => {
    try {
      showLoadingAlert("Tunggu", "Sedang mengompresi ukuran gambar...");

      const selectedFile = files[0];

      if (selectedFile) {
        const reader = new FileReader();
        reader.onload = async () => {
          const imageDataUrl = reader.result;

          const imageWatermarked = await addWatermark(
            "Website",
            imageDataUrl,
            asset,
            `temuan ${index + 1}`,
            user
          );
          setSelectedTemuans((prevImages) => ({
            ...prevImages,
            [`temuan${index + 1}_url`]: URL.createObjectURL(imageWatermarked),
          }));

          const resizedImage = await resizeAndCompressImage(imageWatermarked);

          const propertyName = `temuan${index + 1}_url`;

          const fileData = {
            [propertyName]: resizedImage,
          };

          setTemuanFiles((prevUploadedFiles) => ({
            ...prevUploadedFiles,
            ...fileData,
          }));
        };
        reader.onerror = () => {
          showErrorAlert("Gagal", "Gagal membaca file gambar");
        };
        reader.readAsDataURL(selectedFile);
      }
    } catch (error) {
      console.log({ error });
      showErrorAlert("Gagal", "Ukuran gambar gagal dikompres");
    } finally {
      Swal.close();
    }
  };

  useEffect(() => {
    fetchDocType();
    setIsLoading(false);
  }, []);

  const handleSave = async () => {
    // check first file
    if (notUploadedFiles.length > 0) {
      const stringAtm = notUploadedFiles
        .map((file) => file.nama_jenis_dokumentasi)
        .join(", ");

      showErrorAlert(`Gagal`, `Dokumentasi harus diisi semua. ${stringAtm}`);
      return;
    }

    const willUpdate = await Swal.fire({
      title: "Konfirmasi Upload",
      text: "Apa anda yakin untuk mengupload laporan ini?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Upload",
      cancelButtonText: "Batal",
    });

    // Proceed with adding the report if confirmed
    if (willUpdate.isConfirmed) {
      const formData = new FormData();
      for (const key in uploadedFiles) {
        if (uploadedFiles.hasOwnProperty(key)) {
          const fileData = uploadedFiles[key];
          formData.append(key, fileData);
        }
      }

      for (const key in temuanFiles) {
        if (temuanFiles.hasOwnProperty(key)) {
          const fileData = temuanFiles[key];
          formData.append(key, fileData);
        }
      }
      formData.append("id_asset", asset.id_asset);
      formData.append("id_user", user.id_user);
      formData.append("keterangan", keterangan);
      formData.append("is_temuan", isTemuan);

      try {
        showLoadingOverlay();
        const resp = await reportSvc.addReport(formData);
        hideLoadingOverlay();

        if (resp.status === 200) {
          showSuccessAlert(
            "Berhasil",
            `Laporan untuk ${resp?.data?.laporan?.asset_name} berhasil dikirim`
          );
          history.push("/operator/laporan");
        } else {
          let message =
            resp?.response?.data?.message || "Gagal mengirim laporan";
          showErrorAlert("Gagal", message);
        }
      } catch (error) {
        let message = error.response?.data?.message || "Gagal mengirim laporan";
        showErrorAlert("Gagal", message);
      }
    }
  };

  return (
    <>
      <Container className="px-0">
        <div className="align-items-center py-4 w-100">
          <Card border="light" className="shadow-sm">
            <Card.Body className="pb-0">
              {isLoading ? (
                <div
                  className="d-flex justify-content-center align-items-center"
                  style={{ height: "300px" }}
                >
                  <Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </Spinner>
                </div>
              ) : (
                <Form
                  onSubmit={(e) => {
                    e.preventDefault();
                    handleSave();
                  }}
                >
                  <div className="report-info">
                    <div className="mb-3">
                      <span className="fw-bold">Laporan untuk ATM : </span>
                      <span>{asset.name}</span>
                    </div>
                    <div className="mb-3">
                      <span className="fw-bold">Oleh Operator : </span>
                      <span>{user.name}</span>
                    </div>
                    <div className="mb-3">
                      <span className="fw-bold">Tanggal : </span>
                      <span>{moment().format("YYYY-MM-DD")}</span>
                    </div>
                  </div>
                  <div id="hudahuda"></div>
                  <Form.Group className="mb-3">
                    <Form.Label>Upload Foto Laporan</Form.Label>
                    <br />

                    {docType.length > 0 ? (
                      docType.map((doc) => (
                        <React.Fragment key={doc.id_jenis_dokumentasi}>
                          <span className="mb-2 mt-1">
                            {doc.nama_jenis_dokumentasi}
                          </span>

                          <InputGroup className="mt-1 mb-1">
                            <Form.Control
                              type="file"
                              accept="image/*"
                              capture="environment"
                              onChange={(e) =>
                                handleReportFileUpload(
                                  e.target.files,
                                  doc.id_jenis_dokumentasi,
                                  doc
                                )
                              }
                              required // Added the required attribute
                            />
                          </InputGroup>

                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              maxWidth: "70%",
                              margin: "10px auto",
                            }}
                          >
                            {selectedImages[doc.id_jenis_dokumentasi] && (
                              <Image
                                src={selectedImages[doc.id_jenis_dokumentasi]}
                                alt="Selected"
                              />
                            )}
                          </div>
                        </React.Fragment>
                      ))
                    ) : (
                      <div>
                        <p>Gagal mendapatkan jenis dokumentasi</p>
                      </div>
                    )}
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Apakah ada temuan?</Form.Label>
                    <InputGroup>
                      <Form.Select
                        id="state"
                        value={isTemuan}
                        onChange={(e) => {
                          setIsTemuan(e.target.value);
                        }}
                      >
                        <option value={true}>Iya</option>
                        <option value={false}>Tidak</option>
                      </Form.Select>
                    </InputGroup>
                  </Form.Group>

                  {isTemuan === "true" && (
                    <>
                      <Form.Group className="mb-3">
                        <Form.Label>Keterangan</Form.Label>
                        <InputGroup>
                          <Form.Control
                            required
                            type="text"
                            placeholder="Enter keterangan"
                            value={keterangan}
                            onChange={(e) => setKeterangan(e.target.value)}
                          />
                        </InputGroup>
                      </Form.Group>
                      <Form.Group className="mb-3">
                        <Form.Label>Upload Foto Temuan (Max 2)</Form.Label>
                        {Array.from(Array(2).keys()).map((index) => (
                          <div key={index}>
                            <InputGroup className="mb-2">
                              <Form.Control
                                type="file"
                                accept="image/*"
                                capture="environment"
                                onChange={(e) =>
                                  handleTemuanFileUpload(e.target.files, index)
                                }
                              />
                            </InputGroup>

                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                maxWidth: "70%",
                                margin: "10px auto",
                              }}
                            >
                              {selectedTemuans[`temuan${index + 1}_url`] && (
                                <Image
                                  src={
                                    selectedTemuans[`temuan${index + 1}_url`]
                                  }
                                  alt="Selected"
                                />
                              )}
                            </div>
                          </div>
                        ))}
                      </Form.Group>
                    </>
                  )}

                  <Card.Footer className="px-0 d-lg-flex align-items-center justify-content-between">
                    <Button
                      type="submit"
                      variant="primary"
                      onClick={(e) => {
                        e.preventDefault();
                        handleSave();
                      }}
                      // disabled={!isFormValid}
                    >
                      Kirim
                    </Button>
                  </Card.Footer>
                </Form>
              )}
            </Card.Body>
          </Card>
        </div>
      </Container>
    </>
  );
};
