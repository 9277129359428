import { api, handleApiResponse } from "./api";

export default {
  addUserAsset(config) {
    return api.post("/api/v1/userasset", config).catch(handleApiResponse);
  },

  getUserAssets(config) {
    return api.get("/api/v1/userasset", config).catch(handleApiResponse);
  },

  getUserAssetById(id) {
    return api.get(`/api/v1/userasset/${id}`).catch(handleApiResponse);
  },

  updateUserAsset(id, config) {
    return api.put(`/api/v1/userasset/${id}`, config).catch(handleApiResponse);
  },

  deleteUserAsset(id, config) {
    return api.delete(`/api/v1/userasset/${id}`).catch(handleApiResponse);
  },
};
