import React, { useState, useEffect, useRef } from "react";
import {
  Card,
  Button,
  Form,
  InputGroup,
  Spinner,
  Modal,
} from "@themesberg/react-bootstrap";
import Swal from "sweetalert2";
import assetSvc from "../../services/assetSvc";
import { useHistory, useParams } from "react-router-dom";
import customerSvc from "../../services/customerSvc";
import { getLoginData } from "../../services/authSvc";
import {
  MapContainer,
  TileLayer,
  Marker,
  useMapEvents,
  Popup,
  useMap,
} from "react-leaflet";

import "../../assets/styles/leaflet.css";
import {
  showErrorAlert,
  showSuccessAlert,
  showWarningAlert,
} from "../../utils/SwalUtil";
import L from "leaflet";
import marker from "../../assets/img/marker-removebg-preview.png";
const customMarkerIcon = new L.Icon({
  iconUrl: marker,
  iconRetinaUrl: marker,
  // popupAnchor:  [-0, -0],
  // iconSize: [32,45],
  iconSize: [32, 45],
  iconAnchor: [16, 45],
});

export default () => {
  const { id } = useParams();
  const history = useHistory();
  const [idPelanggan, setIdPelanggan] = useState(null);
  const [kode, setKode] = useState("");
  const [name, setName] = useState("");
  const [location, setLocation] = useState("");
  const [address, setAddress] = useState("");
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");
  const [ring, setRing] = useState("2");
  const [isLoading, setIsLoading] = useState(false);
  const [customers, setCustomers] = useState([]);
  const [isMapModalVisible, setIsMapModalVisible] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState({
    lat: -6.1754,
    lng: 106.8272,
  });
  const [selectedAddress, setSelectedAddress] = useState("");
  const [center, setCenter] = useState({ lat: -6.1754, lng: 106.8272 });
  const { user } = getLoginData();

  const MAPBOX_URL = `https://api.mapbox.com/styles/v1/mapbox/streets-v12/tiles/{z}/{x}/{y}?access_token=${process.env.REACT_APP_MAPBOX_TOKEN}`;

  useEffect(() => {
    if (id) {
      fetchAssetById();
    }
    fetchCustomers();
  }, []);

  const fetchCustomers = async () => {
    if (user?.role === "admin") {
      setIsLoading(true);
      const resp = await customerSvc.getCustomer({
        params: {
          page: 0,
          limit: 1000,
          active: true,
        },
      });
      if (resp.status === 200) {
        setCustomers(resp.data.data);
      }
      setIsLoading(false);
    }
  };

  const fetchAssetById = async () => {
    setIsLoading(true);
    const respDetailAsset = await assetSvc.getAssetById(id);
    if (respDetailAsset.status === 200) {
      const assetData = respDetailAsset.data.data;

      setIdPelanggan(assetData.id_pelanggan);
      setKode(assetData.kode);
      setName(assetData.name);
      setLocation(assetData.lokasi);
      setAddress(assetData.alamat);
      setLatitude(assetData.latitude);
      setLongitude(assetData.longitude);
      setRing(assetData.ring);

      const lat = parseFloat(assetData.latitude);
      const lng = parseFloat(assetData.longitude);
      setSelectedLocation({ lat, lng });
      setCenter({ lat, lng });
      getFullAddress(lat, lng);
    }
    setIsLoading(false);
  };

  const updateAddress = (newAddress) => {
    if (newAddress.trim() !== "") {
      setAddress(newAddress);
      setSelectedAddress(newAddress);
    } else {
      setSelectedAddress(address);
    }
  };

  const getFullAddress = async (latitude, longitude) => {
    try {
      const response = await fetch(
        `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}`
      );
      const data = await response.json();
      const fullAddress = data.display_name;
      setSelectedAddress(fullAddress);
    } catch (error) {}
  };

  const CheckKodeAtmOnDb = async (kode) => {
    try {
      const response = await assetSvc.getAsset({
        params: { query: kode },
      });

      if (response?.data?.totalRows > 0) {
        showWarningAlert("Warning", "Terdapat ATM dengan ID yang sama!");
      }
    } catch (err) {}
  };

  const handleLocationSelect = (e) => {
    setLocation(e.target.value);
    const latitude = e.target.value.split(",")[0].trim();
    const longitude = e.target.value.split(",")[1].trim();
    setSelectedLocation({ lat: latitude, lng: longitude });
    getFullAddress(latitude, longitude);
  };

  const handleMapClick = (e) => {
    const { lat, lng } = e.latlng;
    setLocation(`${lat}, ${lng}`);
    setSelectedLocation({ lat, lng });
    getFullAddress(lat, lng);
  };

  function MapClickHandler() {
    useMapEvents({
      click: handleMapClick,
    });
    return null;
  }

  const handleSave = async () => {
    if (!name || !kode || !location || !ring) {
      showErrorAlert("Gagal", "Kolom harus diisi lengkap");
      return;
    }

    try {
      if (id) {
        const data = {
          id_pelanggan: idPelanggan,
          kode: kode,
          name: name,
          lokasi: location,
          alamat: selectedAddress !== "" ? selectedAddress : address,
          latitude: selectedLocation.lat,
          longitude: selectedLocation.lng,
          ring: ring,
        };
        const resp = await assetSvc.updateAsset(id, data);
        if (resp.status === 200) {
          showSuccessAlert("Berhasil", "Data ATM berhasil diubah");
          history.goBack();
        } else {
          let message =
            resp?.response?.data?.message || "Gagal mengubah data ATM";
          showErrorAlert("Gagal", message);
        }
      } else {
        const data = {
          id_pelanggan: user.role === "admin" ? idPelanggan : user.id_pelanggan,
          kode: kode,
          name: name,
          lokasi: location,
          alamat: selectedAddress,
          latitude: selectedLocation.lat,
          longitude: selectedLocation.lng,
          ring: ring,
        };
        const resp = await assetSvc.addAsset(data);
        if (resp.status === 200) {
          await showSuccessAlert("Berhasil", "Data aset berhasil ditambahkan");
          history.goBack();
        } else {
          let message = resp?.response?.data?.message || "Gagal membuat ATM";
          showErrorAlert("Gagal", message);
        }
      }
    } catch (error) {
      let message = error?.response?.data?.message || "Gagal sistem error";
      showErrorAlert("Gagal", message);
    }
  };

  function UpdateMapCentre(props) {
    const map = useMap();
    map.panTo(props.mapCentre);
    return null;
  }

  return (
    <div className="align-items-center py-4 w-100">
      <Card>
        <Card.Header>
          <Card.Title className="h6">
            {id ? "Ubah Data ATM" : "Tambah ATM"}
          </Card.Title>
        </Card.Header>
        {isLoading ? (
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ height: "300px" }}
          >
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        ) : (
          <>
            <Card.Body>
              <Form>
                <div className="row">
                  <div className="col-md-6">
                    <Form.Group className="mb-3">
                      <Form.Label>Kantor Cabang</Form.Label>
                      <InputGroup>
                        <Form.Select
                          id="state"
                          value={idPelanggan}
                          placeholder="Pilih Kantor Cabang"
                          onChange={(e) => {
                            setIdPelanggan(e.target.value);
                          }}
                        >
                          {idPelanggan === null && (
                            <option value={null}>Pilih Pelanggan</option>
                          )}
                          {customers.map((customer) => (
                            <option
                              key={customer.id_pelanggan}
                              value={customer.id_pelanggan}
                            >
                              {customer.name}
                            </option>
                          ))}
                        </Form.Select>
                      </InputGroup>
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <Form.Label>Kode</Form.Label>
                      <InputGroup>
                        <Form.Control
                          required
                          type="text"
                          placeholder="Masukkan Kode"
                          value={kode}
                          onChange={(e) => setKode(e.target.value)}
                          onBlur={async (e) =>
                            await CheckKodeAtmOnDb(e.target.value)
                          }
                        />
                      </InputGroup>
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <Form.Label>Alamat</Form.Label>
                      <InputGroup>
                        <Form.Control
                          required
                          type="text"
                          placeholder="Masukkan Alamat"
                          value={selectedAddress}
                          onChange={(e) => updateAddress(e.target.value)}
                        />
                      </InputGroup>
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <Form.Label>Latitude</Form.Label>
                      <InputGroup>
                        <Form.Control
                          id="latitude"
                          type="number"
                          className="form-control"
                          value={selectedLocation?.lat || ""}
                          onChange={(e) => {
                            const lat = parseFloat(e.target.value);
                            // Validate latitude
                            if (isNaN(lat)) {
                              return;
                            }
                            const lng = selectedLocation?.lng || 0;
                            setSelectedLocation({ lat, lng });
                            getFullAddress(lat, lng);
                          }}
                        />
                      </InputGroup>
                    </Form.Group>
                  </div>
                  <div className="col-md-6">
                    <Form.Group className="mb-3">
                      <Form.Label>Nama</Form.Label>
                      <InputGroup>
                        <Form.Control
                          required
                          type="text"
                          placeholder="Masukkan Nama ATM"
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                        />
                      </InputGroup>
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <Form.Label>Lokasi</Form.Label>
                      <InputGroup>
                        <Form.Control
                          required
                          type="text"
                          placeholder="Masukkan Lokasi"
                          value={location}
                          onChange={(e) => handleLocationSelect(e)}
                        />
                      </InputGroup>
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <Form.Label>Pilih Ring</Form.Label>
                      <InputGroup>
                        <Form.Select
                          id="state"
                          defaultValue={ring || "2"}
                          value={ring}
                          onChange={(e) => {
                            setRing(e.target.value);
                          }}
                        >
                          <option value="1">1</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                        </Form.Select>
                      </InputGroup>
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <Form.Label>Longitude</Form.Label>
                      <InputGroup>
                        <Form.Control
                          id="longitude"
                          type="number"
                          className="form-control"
                          value={selectedLocation?.lng || ""}
                          onChange={(e) => {
                            const lat = selectedLocation?.lat || 0;
                            const lng = parseFloat(e.target.value);
                            if (isNaN(lng)) {
                              return;
                            }
                            setSelectedLocation({ lat, lng });
                            getFullAddress(lat, lng);
                          }}
                        />
                      </InputGroup>
                    </Form.Group>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <MapContainer
                      center={[selectedLocation.lat, selectedLocation.lng]}
                      zoom={13}
                      scrollWheelZoom={true}
                      style={{ height: "400px", width: "100%" }}
                    >
                      <MapClickHandler />
                      <TileLayer url={MAPBOX_URL} />
                      <UpdateMapCentre mapCentre={selectedLocation} />
                      <Marker
                        position={selectedLocation}
                        icon={customMarkerIcon}
                      >
                        <Popup>{selectedAddress}</Popup>
                      </Marker>
                    </MapContainer>
                  </div>
                </div>
              </Form>
            </Card.Body>

            <Card.Footer>
              <Button
                variant="secondary"
                className="me-2"
                onClick={() => history.goBack()}
              >
                Batalkan
              </Button>
              <Button variant="primary" onClick={handleSave}>
                Simpan Perubahan
              </Button>
            </Card.Footer>
          </>
        )}
      </Card>
    </div>
  );
};
