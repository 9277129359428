import React, { useState, useRef, useEffect } from "react";
import {
  Button,
  Card,
  Table,
  Spinner,
  Form,
  InputGroup,
  FormControl,
} from "@themesberg/react-bootstrap";
import InfiniteScroll from "react-infinite-scroll-component";
import moment from "moment";
import Datetime from "react-datetime";

import { getLoginData } from "../services/authSvc";
import logsSvc from "../services/logsSvc";
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
moment.tz.setDefault("Asia/Bangkok");

export default () => {
  const [logs, setLogs] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const { user } = getLoginData();
  const [scroll, setScroll] = useState(10);
  const [selectedLogType, setSelectedLogType] = useState("");
  const [selectedEntity, setSelectedEntity] = useState("");
  const searchInputRef = useRef(null);
  const [isFetching, setIsFetching] = useState(false);
  const [maxSelectedDate, setMaxSelectedDate] = useState(null);

  const handleClearFilters = () => {
    setSearchTerm("");
    setSelectedLogType("");
    setScroll(10);
    setMaxSelectedDate(moment().format("YYYY-MM-DD"));
    if (searchInputRef.current) {
      searchInputRef.current.value = "";
    }
    fetchLogs();
  };

  const fetchLogs = async (scroll) => {
    try {
      setIsFetching(true);
      const resp = await logsSvc.getAllLogs({
        params: {
          scroll: scroll,
          query: searchTerm,
          last_created_at: maxSelectedDate,
          entity: selectedEntity,
          jenis_log: selectedLogType,
        },
      });
      if (resp.status === 200) {
        setLogs(resp.data.data);
      }
    } catch (error) {
    } finally {
      setIsFetching(false);
    }
  };

  const handleMaxSelectedDateChange = (date) => {
    const formattedDate = moment(date).format("YYYY-MM-DD");
    setMaxSelectedDate(formattedDate);
  };

  useEffect(() => {
    fetchLogs(scroll);
  }, [scroll, selectedLogType, selectedEntity, maxSelectedDate]);

  const isFirstRender = useRef(true);
  useEffect(() => {
    if (!isFirstRender.current) {
      let timerId;
      const fetchData = () => {
        fetchLogs();
      };
      const delayedFetch = () => {
        clearTimeout(timerId);
        timerId = setTimeout(fetchData, 500); // Adjust the delay time as per your needs
      };
      delayedFetch(); // Call the delayedFetch initially to trigger the initial API call
      return () => {
        // Clean up by clearing the timer when the component unmounts
        clearTimeout(timerId);
      };
    } else {
      isFirstRender.current = false;
    }
  }, [searchTerm]);

  const fetchMoreLogs = () => {
    setScroll((prevScroll) => prevScroll + 500);
  };

  return (
    <>
      <div className="align-items-center py-4 w-100">
        <Card border="light" className="shadow-sm">
          <Card.Body className="pb-0">
            <Card.Title className="">Data Logs</Card.Title>
            <hr />

            <div className="d-flex justify-content-between mb-3">
              <FormControl
                ref={searchInputRef}
                type="text"
                placeholder="Cari keterangan log..."
                className="me-1"
                value={searchTerm}
                onChange={(e) => {
                  setSearchTerm(e.target.value);
                }}
              />
              <InputGroup>
                <Form.Select
                  id="state"
                  className="me-1"
                  defaultValue=""
                  value={selectedLogType}
                  style={{ width: "30px" }}
                  onChange={(e) => {
                    setSelectedLogType(e.target.value);
                  }}
                >
                  <option value="">Semua Jenis</option>
                  <option value="create">Create</option>
                  <option value="update">Update</option>
                  <option value="delete">Delete</option>
                </Form.Select>
              </InputGroup>
              <InputGroup>
                <Form.Select
                  id="state"
                  className="me-1"
                  defaultValue=""
                  value={selectedEntity}
                  style={{ width: "30px" }}
                  onChange={(e) => {
                    setSelectedEntity(e.target.value);
                  }}
                >
                  <option value="">Semua entitas</option>
                  <option value="laporan">Laporan</option>
                  <option value="asset">Aset</option>
                  <option value="user">User</option>
                  <option value="pelanggan">Pelanggan</option>
                  <option value="jenis dokumentasi">Jenis Dokumentasi</option>
                  <option value="user asset">User Aset</option>
                  <option value="emr">EMR</option>
                  <option value="emrv2">EMR V2</option>
                </Form.Select>
              </InputGroup>

              <Button
                variant="secondary"
                size="sm"
                className="me-1"
                onClick={handleClearFilters}
              >
                Reset Filter
              </Button>
            </div>
            <div className="mb-4">
              <Form.Label>Batas Tanggal</Form.Label>
              <Datetime
                timeFormat={false}
                onChange={handleMaxSelectedDateChange}
                renderInput={(props, openCalendar) => (
                  <InputGroup className="me-2">
                    <InputGroup.Text>
                      <FontAwesomeIcon icon={faCalendarAlt} />
                    </InputGroup.Text>
                    <Form.Control
                      required
                      type="text"
                      value={
                        maxSelectedDate
                          ? moment(maxSelectedDate).format("YYYY-MM-DD")
                          : ""
                      }
                      placeholder="YYYY-MM-DD"
                      onFocus={openCalendar}
                      onChange={() => {}}
                    />
                  </InputGroup>
                )}
              />
            </div>
            <InfiniteScroll
              dataLength={logs.length}
              next={fetchMoreLogs}
              hasMore={true}
              loader={
                <div>
                  <div
                    colSpan={5}
                    className="text-center justify-center m-auto pt-4"
                    style={{ height: "300px" }}
                  >
                    <Spinner animation="border" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </Spinner>
                  </div>
                </div>
              }
              scrollableTarget="table-scroll"
            >
              <Table
                responsive
                className="table-centered table-nowrap rounded mb-0"
              >
                <thead className="thead-light">
                  <tr>
                    <th className="border-0">Jenis Logs</th>
                    <th className="border-0">Entitas</th>
                    <th className="border-0">Keterangan</th>
                    <th className="border-0">Tanggal</th>
                  </tr>
                </thead>
                <tbody>
                  {logs.map((log) => (
                    <tr key={log.id}>
                      <td className="border-0">
                        <span
                          className={`fw-bolder text-white p-1 rounded ${
                            log.jenis_log === "create"
                              ? "bg-success"
                              : log.jenis_log === "update"
                              ? "bg-secondary"
                              : log.jenis_log === "delete"
                              ? "bg-danger"
                              : ""
                          }`}
                        >
                          {log.jenis_log}
                        </span>
                      </td>
                      <td className="border-0">
                        <span className="fw-bolder">{log.entity}</span>
                      </td>
                      <td className="border-0">
                        <span
                          className="fw-bolder"
                          style={{ whiteSpace: "pre-line" }}
                        >
                          {log.message}
                        </span>
                      </td>
                      <td className="fw-bold border-0">
                        <span>
                          {moment(log.created_at).format("YYYY-MM-DD")}
                        </span>
                        <br />
                        {moment(log.created_at).format("HH:mm:ss")}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </InfiniteScroll>
          </Card.Body>
        </Card>
      </div>
    </>
  );
};
