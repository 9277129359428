import { api, handleApiResponse } from "./api";

export default {
  addReportTransaction(config) {
    return api
      .post("/api/v1/report/transaction", config)
      .catch(handleApiResponse);
  },

  getReportTransactions(config) {
    return api
      .get("/api/v1/report/transaction", config)
      .catch(handleApiResponse);
  },

  updateReportTransaction(id, config) {
    return api
      .put(`/api/v1/report/transaction/${id}`, config)
      .catch(handleApiResponse);
  },

  getReportTransactionById(id) {
    return api.get(`/api/v1/report/transaction/${id}`).catch(handleApiResponse);
  },

  deleteReportTransaction(id) {
    return api
      .delete(`/api/v1/report/transaction/${id}`)
      .catch(handleApiResponse);
  },

  uploadFileService(config) {
    return api.post("api/v1/file/upload", config).catch(handleApiResponse);
  },
};
