import { faSquare } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default () => {
  return (
    <div className="d-flex flex-wrap gap-2 gap-lg-4">
      <div>
        <FontAwesomeIcon icon={faSquare} style={{ color: "red" }} />
        {" : "}
        Belum dikerjakan
      </div>
      <div>
        {" "}
        <FontAwesomeIcon icon={faSquare} style={{ color: "#92D050" }} />
        {" : "}Dikerjakan tidak ada temuan
      </div>
      <div>
        {" "}
        <FontAwesomeIcon icon={faSquare} style={{ color: "#0070C0" }} />
        {" : "}Dikerjakan ada temuan
      </div>
      <div>
        {" "}
        <FontAwesomeIcon icon={faSquare} style={{ color: "yellow" }} />
        {" : "}Libur
      </div>
    </div>
  );
};
