export const customStyles = {
  control: (provided, state) => ({
    ...provided,
    borderRadius: "8px",
    fontSize: "16px",
    borderColor: state.isFocused ? "#f76617" : "#A3A39D",
    boxShadow: state.isFocused ? "0 0 0 1px #f76617" : provided.boxShadow,
    "&:hover": {
      borderColor: "#f76617",
    },
    "&:focus": {
      borderColor: "#f76617",
    },
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? "lightgray" : "white",
    color: state.isSelected ? "black" : "inherit",
    "&:hover": {
      backgroundColor: "lightgray",
    },
  }),
  container: (provided) => ({
    ...provided,
    width: "100%",
    height: "100%",
  }),
  input: (provided) => ({
    ...provided,
    minHeight: "42px",
  }),
};
