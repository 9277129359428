import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faKey,
  faPenToSquare,
  faPlus,
  faWrench,
} from "@fortawesome/free-solid-svg-icons";

import {
  Button,
  Card,
  Table,
  Form,
  InputGroup,
  Spinner,
  Pagination,
  Nav,
  FormControl,
  Image,
} from "@themesberg/react-bootstrap";
import { useEffect, useRef } from "react";
import Swal from "sweetalert2";
import userSvc from "../../services/userSvc";
import customerSvc from "../../services/customerSvc";
import { useHistory } from "react-router-dom";
import {
  showErrorAlert,
  showLoadingAlert,
  showSuccessAlert,
} from "../../utils/SwalUtil";
import { getLoginData } from "../../services/authSvc";
import Select from "react-select";
import { customStyles } from "../../utils/SelectUtils";
import NavTabs from "../../components/NavTabs";
import reportSvc from "../../services/reportSvc";

export default () => {
  const searchInputRef = useRef(null);
  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [activePage, setActivePage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [startIndex, setStartIndex] = useState(null);
  const [endIndex, setEndIndex] = useState(null);
  const [totalPages, setTotalPages] = useState(null);
  const { user } = getLoginData();
  const role = user?.role;
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");
  const [selectedRole, setSelectedRole] = useState(
    role === "admin cabang" ? "operator" : ""
  );
  const [customers, setCustomers] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState("");
  const [totalRows, setTotalRows] = useState("");

  const history = useHistory();
  const dev = process.env.REACT_APP_UI_DEV;

  const getCustomers = async () => {
    if (role === "admin") {
      const respCust = await customerSvc.getCustomer({
        params: {
          page: 0,
          limit: 1000,
        },
      });

      if (respCust.status === 200) {
        setCustomers(respCust.data.data);
      } else {
        setCustomers([]);
      }
    }
  };

  const fetchUsers = async (page, limit) => {
    setIsLoading(true);
    if (role === "admin cabang") {
      setSelectedRole("operator");
    }
    const resp = await reportSvc.getUserHistory({
      params: {
        page: page - 1,
        limit: limit,
        query: searchTerm,
        id_pelanggan: selectedCustomer ? selectedCustomer.value : "",
      },
    });
    if (resp.status === 200) {
      setUsers(resp.data.data);
    }
    setIsLoading(false);
  };

  const fetchUsersRemoveFilter = async (page, limit) => {
    setIsLoading(true);
    const resp = await reportSvc.getUserHistory({
      params: {
        page: page - 1,
        limit: limit,
      },
    });
    if (resp.status === 200) {
      setUsers(resp.data.data);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getCustomers();
  }, []);

  useEffect(() => {
    fetchUsers();
  }, [selectedCustomer]);

  const isFirstRender = useRef(true);
  useEffect(() => {
    if (!isFirstRender.current) {
      let timerId;
      const fetchData = () => {
        fetchUsers();
      };
      const delayedFetch = () => {
        clearTimeout(timerId);
        timerId = setTimeout(fetchData, 1000);
      };
      delayedFetch();
      return () => {
        clearTimeout(timerId);
      };
    } else {
      isFirstRender.current = false;
    }
  }, [searchTerm]);

  const handleClearFilters = () => {
    setSearchTerm("");
    setSelectedCustomer("");

    if (searchInputRef.current) {
      searchInputRef.current.value = "";
    }

    fetchUsersRemoveFilter();
  };

  const handleSelectCustomer = (selectedOption) => {
    setSelectedCustomer(selectedOption);
  };

  const handleTabChange = () => {
    useHistory.push("/");
  };

  return (
    <div className="align-items-center py-4 w-100">
      <Card border="light" className="shadow-sm">
        <Card.Header style={{ paddingBottom: "0px" }}>
          <NavTabs
            activeTab={"/user/history"}
            onTabChange={handleTabChange}
            sideTab={"user"}
          />
          <hr />
          <div className="d-flex justify-content-between mb-3">
            <FormControl
              type="text"
              placeholder="Cari nama, no hp, email pengguna..."
              className="me-2"
              ref={searchInputRef}
              onChange={(e) => {
                setSearchTerm(e.target.value);
              }}
            />
            {user.role === "admin" && (
              <InputGroup>
                <Select
                  className="me-1"
                  id="state"
                  placeholder="Semua Kantor Cabang"
                  styles={customStyles}
                  value={selectedCustomer}
                  onChange={handleSelectCustomer}
                  options={customers.map((user) => ({
                    value: user.id_pelanggan,
                    label: user.name,
                  }))}
                />
              </InputGroup>
            )}

            <Button
              variant="secondary"
              size="sm"
              className="ms-2"
              onClick={handleClearFilters}
              style={{ marginRight: "10px" }}
            >
              Reset Filter
            </Button>
          </div>
        </Card.Header>

        <Card.Body className="pb-0">
          <Table responsive className="table-centered rounded mb-0">
            <thead className="thead-light">
              <tr>
                <th className="border-0 px-1">Nama Pengguna</th>
                <th className="border-0 px-1">Terakhir Aktif</th>
                <th className="border-0 px-1">Informasi</th>
              </tr>
            </thead>

            {isLoading ? (
              <tr>
                <td
                  colSpan={5}
                  className="text-center"
                  style={{ height: "300px" }}
                >
                  <Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </Spinner>
                </td>
              </tr>
            ) : users.length > 0 ? (
              <tbody>
                {users.map((user) => (
                  <tr key={user.id_user}>
                    <td className="border-0 px-1">
                      <div
                        style={{ display: "flex", alignItems: "center" }}
                        className="mb-2"
                      >
                        {user.photo_url ? (
                          <Image
                            src={user.photo_url}
                            alt="profile-pict"
                            width="40px"
                            height="40px"
                            style={{
                              borderRadius: "50%",
                              marginRight: "10px",
                            }}
                          />
                        ) : (
                          <div
                            className="bg-primary"
                            style={{
                              width: "40px",
                              height: "40px",
                              borderRadius: "50%",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              marginRight: "10px",
                            }}
                          >
                            {user.name !== "" && (
                              <span
                                style={{
                                  color: "#fff",
                                  fontSize: "16px",
                                  fontWeight: "bold",
                                }}
                              >
                                {user?.name?.charAt(0).toUpperCase()}
                              </span>
                            )}
                          </div>
                        )}
                        <div>
                          <span className="fw-bold">{user.name}</span>
                          <br />
                          {user.email}
                        </div>
                      </div>
                    </td>
                    <td className="fw-bold border-0 px-1">
                      <span className="fw-bolder">
                        {user?.updatedAt
                          ? new Date(user.updatedAt).toLocaleDateString(
                              "id-ID",
                              {
                                timeZone: "Asia/Bangkok",
                                day: "numeric",
                                month: "long",
                                year: "numeric",
                              }
                            )
                          : ""}
                      </span>
                      <br />
                      <span className="fw-bolder">
                        {user?.updatedAt
                          ? new Date(user.updatedAt).toLocaleTimeString(
                              "id-ID",
                              {
                                timeZone: "Asia/Bangkok",
                                hour: "2-digit",
                                minute: "2-digit",
                                second: "2-digit",
                              }
                            )
                          : ""}
                      </span>
                    </td>
                    <td className="fw-bold border-0 px-1">
                      <span className="fw-bolder">{user?.last_ip}</span>
                      {user?.pelanggan && (
                        <>
                          <br />
                          {user?.pelanggan?.name}
                        </>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            ) : (
              <tr>
                <td
                  colSpan={5}
                  className="text-center"
                  style={{ height: "200px" }}
                >
                  <span className="text-muted">Tidak ada Pengguna</span>
                </td>
              </tr>
            )}
          </Table>
        </Card.Body>
      </Card>
      {/* <div id="loading-overlay" style="display: none;">
          <div class="loader"></div>
            <p>Loading...</p>
        </div> */}
    </div>
  );
};
