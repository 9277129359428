import { api, handleApiResponse } from "./api";

export default {
  addCustomer(config) {
    return api.post("/api/v1/pelanggan", config).catch(handleApiResponse());
  },

  getCustomer(config) {
    return api.get("/api/v1/pelanggan", config).catch(handleApiResponse());
  },

  updateActivateCustomer(id) {
    return api
      .put(`/api/v1/pelanggan/activate/${id}`)
      .catch(handleApiResponse());
  },

  getCustomerById(id) {
    return api.get(`/api/v1/pelanggan/${id}`).catch(handleApiResponse());
  },

  updateCustomer(id, config) {
    return api
      .put(`/api/v1/pelanggan/${id}`, config)
      .catch(handleApiResponse());
  },
};
