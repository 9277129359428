import React, { useEffect, useState } from "react";
import { useParams, useHistory, useLocation } from "react-router-dom";
import {
  MapContainer,
  TileLayer,
  Marker,
  Popup,
  useMap,
  Circle,
} from "react-leaflet";

import "../../assets/styles/leaflet.css";
import L from "leaflet";
import marker from "../../assets/img/marker-removebg-preview.png";
import markerGPS from "../../assets/img/gps-icon.png";

import assetSvc from "../../services/assetSvc";
import { useGeolocated } from "react-geolocated";
import {
  showConfirmButton,
  showErrorAlert,
  showWarningAlert,
} from "../../utils/SwalUtil";
import reportSvc from "../../services/reportSvc";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLandmark, faUser } from "@fortawesome/free-solid-svg-icons";
import { faMailchimp } from "@fortawesome/free-brands-svg-icons";
// import { AlertTwoButton } from "../components/Alert";

const customMarkerIcon = new L.Icon({
  iconUrl: marker,
  iconRetinaUrl: marker,
  iconSize: [32, 45],
  iconAnchor: [16, 45],
});

const customMarkerIconGPS = new L.Icon({
  iconUrl: markerGPS,
  iconRetinaUrl: markerGPS,
  iconSize: [32, 32],
  iconAnchor: [16, 45],
});
const ValidateAssetPage = () => {
  // const { id } = useParams();
  const history = useHistory();
  const location = useLocation();
  const [assetScreen, setAssetScreen] = useState(location?.state?.asset);
  const [asset, setAsset] = useState({ latitude: 0, longitude: 0 });
  const [position, setPosition] = useState({ lat: 0, lng: 0 });
  const [gpsActive, setGpsActive] = useState(true);
  const [inRange, setInRange] = useState(false);
  const [mapReady, setMapReady] = useState(false);
  const [toAtm, setToAtm] = useState(true);
  const [changedCoords, setChangedCoords] = useState({
    lat: 0,
    lng: 0,
  });
  const [geolocationAccess, setGeolocationAccess] = useState(false);
  const MAPBOX_URL = `https://api.mapbox.com/styles/v1/mapbox/streets-v12/tiles/{z}/{x}/{y}?access_token=${process.env.REACT_APP_MAPBOX_TOKEN}`;
  const fetchAsset = async () => {
    try {
      const response = await assetSvc.getAssetById(assetScreen.id_asset);
      setAsset(response?.data?.data);
      setChangedCoords({
        lat: response?.data?.data?.latitude,
        lng: response?.data?.data?.longitude,
      });
      setGpsActive(response?.data?.data?.gps_active);
      setMapReady(true);
    } catch (error) {
      console.error("Error fetching asset information", error);
    }
  };

  const checkTotalReport = async () => {
    try {
      const resp = await reportSvc.checkValidateAsset(assetScreen.id_asset);

      if (resp.status !== 200) {
        console.log({ resp });
        const confirmation = await showConfirmButton(
          "Gagal",
          resp?.response?.data?.message,
          "error"
        );

        if (confirmation.isConfirmed) history.goBack();
      }
    } catch (error) {
      console.error("Error fetching total report", error);
    }
  };

  const fetchGPSPosition = async () => {
    try {
      if (!navigator.geolocation) {
        window.alert("Geolocation is not supported by this browser");
        return;
      }

      navigator.geolocation.getCurrentPosition(
        (position) => {
          // console.log({ position });
          setPosition({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          });
          setGeolocationAccess(true);
        },
        (error) => {
          console.error("Error fetching GPS position", error);
          setGeolocationAccess(false);
        }
      );
    } catch (error) {
      console.error("Error fetching GPS position", error);
    }
  };

  useEffect(() => {
    fetchAsset();
    fetchGPSPosition();
    checkTotalReport();
  }, [location]);

  useEffect(() => {
    const intervalId = setInterval(async () => {
      try {
        await fetchGPSPosition();
        isWithinRadius(
          asset?.latitude,
          asset?.longitude,
          position?.lat,
          position?.lng,
          0.2
        );
        // console.log({ asset, position });

        // Do something with the updated position if needed
      } catch (error) {
        console.error("Error during interval", error);
      }
    }, 1000); // Refetch every second

    // Cleanup the interval on component unmount
    return () => clearInterval(intervalId);
  }, [asset, position]);

  function calculateDistance(lat1, lon1, lat2, lon2) {
    const R = 6371; // Radius of the Earth in kilometers
    const dLat = ((lat2 - lat1) * Math.PI) / 180;
    const dLon = ((lon2 - lon1) * Math.PI) / 180;
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos((lat1 * Math.PI) / 180) *
        Math.cos((lat2 * Math.PI) / 180) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const distance = R * c; // Distance in kilometers
    return distance;
  }

  function isWithinRadius(lat1, lon1, lat2, lon2, radius) {
    const distance = calculateDistance(lat1, lon1, lat2, lon2);
    const hasil = distance <= radius;

    setInRange(hasil);
  }

  const checkCanUpload = () => {
    isWithinRadius(
      asset?.latitude,
      asset?.longitude,
      position?.lat,
      position.lng,
      0.2
    );
    if (gpsActive === false || inRange) {
      history.push("/operator/laporan/add", {
        asset: asset,
        screen: "GpsValidation",
      });
    } else {
      showWarningAlert("Gagal", "Anda tidak berada dalam range");
    }
  };

  const checkYourLocation = () => {
    // console.log({ toAtm });
    if (toAtm) {
      setChangedCoords({ lat: position.lat, lng: position.lng });
    } else {
      setChangedCoords({ lat: asset.latitude, lng: asset.longitude });
    }
    setToAtm(!toAtm);
    // UpdateMapCentre({ mapCentre});
    fetchGPSPosition();
  };

  function UpdateMapCentre(props) {
    // console.log({ props });
    const map = useMap();
    map.panTo(props.mapCentre);
    return null;
  }

  return (
    <div className="mt-3">
      {!geolocationAccess && (
        <div>
          <p>GPS Geolokasi tidak aktif</p>
          <p>Silahkan nyalakan dan izinkan akses untuk browser</p>
        </div>
      )}
      {mapReady && (
        <>
          <MapContainer
            center={changedCoords}
            zoom={16}
            scrollWheelZoom={true}
            style={{
              height: "90vh",
              width: "100%",
              position: "relative",
              zIndex: 1,
            }}
          >
            \
            <TileLayer url={MAPBOX_URL} />
            {changedCoords?.lat && changedCoords?.lng && (
              <UpdateMapCentre mapCentre={changedCoords} />
            )}
            <Marker
              position={[asset?.latitude, asset?.longitude]}
              icon={customMarkerIcon}
            >
              <Popup>
                <>
                  {`${asset?.name} - ${asset?.kode}`} <br />
                  {asset?.pelanggan?.name}
                </>
              </Popup>
              {asset && (
                <Circle
                  center={[asset?.latitude, asset?.longitude]}
                  radius={200}
                  pathOptions={{
                    color: "blue",
                    fillColor: "blue",
                    fillOpacity: 0.2,
                  }}
                />
              )}
            </Marker>
            <Marker
              position={[position?.lat, position?.lng]}
              icon={customMarkerIconGPS}
            >
              <Popup>
                <strong>Lokasi Anda!</strong>
              </Popup>
            </Marker>
          </MapContainer>
          <div
            style={{
              position: "absolute",
              bottom: "10%",
              width: "100%",
              textAlign: "center",
              gap: "5px",
              // margin: "auto",
              zIndex: 10,
            }}
          >
            <button
              mode="outlined"
              style={{
                color: "black",
                width: "30%",
                height: "50px",
                marginRight: "5px",
                backgroundColor:
                  gpsActive === false || inRange ? "orange" : "#ffffff",
                fontSize: 15,
              }}
              labelStyle={{
                fontWeight: "bold",
                color: gpsActive === false || inRange ? "#000000" : "#000000",
              }}
              onClick={() => {
                checkCanUpload();
              }}
            >
              {gpsActive === false || inRange ? "ABSEN" : "ABSEN"}
            </button>

            <button
              mode="outlined"
              style={{
                color: "black",
                width: "20%",
                height: "50px",
                backgroundColor: "#ffffff",
                fontSize: 15,
              }}
              labelStyle={{
                fontWeight: "bold",
                color: "#000000",
              }}
              onClick={() => {
                checkYourLocation();
              }}
            >
              {toAtm ? (
                <FontAwesomeIcon icon={faUser} />
              ) : (
                <FontAwesomeIcon icon={faLandmark} />
              )}
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default ValidateAssetPage;
