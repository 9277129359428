import React, { useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPenToSquare,
  faPlus,
  faWrench,
} from "@fortawesome/free-solid-svg-icons";
import {
  Button,
  Card,
  Table,
  Form,
  InputGroup,
  Image,
  Spinner,
  Pagination,
  Nav,
  FormControl,
} from "@themesberg/react-bootstrap";

import { useEffect } from "react";
import customerSvc from "../services/customerSvc";
import Swal from "sweetalert2";
import { useHistory } from "react-router-dom";
import {
  showErrorAlert,
  showLoadingAlert,
  showSuccessAlert,
} from "../utils/SwalUtil";
import { getLoginData } from "../services/authSvc";

export default () => {
  const searchInputRef = useRef(null);
  const [customers, setCustomers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [activePage, setActivePage] = useState(1);
  const [startIndex, setStartIndex] = useState(null);
  const [endIndex, setEndIndex] = useState(null);
  const [totalPages, setTotalPages] = useState(null);

  const [searchTerm, setSearchTerm] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [totalRows, setTotalRows] = useState(null);
  const history = useHistory();
  const { user } = getLoginData();
  const role = user?.role;
  const dev = process.env.REACT_APP_UI_DEV;

  const renderPaginationItems = () => {
    const paginationItems = [];

    paginationItems.push(
      <Pagination.First
        key="first"
        onClick={() => handlePageChange(1)}
        disabled={activePage === 1}
      />
    );

    paginationItems.push(
      <Pagination.Prev
        key="prev"
        onClick={() => activePage > 1 && handlePageChange(activePage - 1)}
        disabled={activePage === 1}
      />
    );

    for (let i = 1; i <= totalPages; i++) {
      if (
        i === 1 ||
        i === totalPages ||
        (i >= activePage - 2 && i <= activePage + 2)
      ) {
        paginationItems.push(
          <Pagination.Item
            key={i}
            onClick={() => handlePageChange(i)}
            active={i === activePage}
          >
            {i}
          </Pagination.Item>
        );
      } else if (
        (i === activePage - 3 && i > 2) ||
        (i === activePage + 3 && i < totalPages - 1)
      ) {
        paginationItems.push(<Pagination.Ellipsis key={i} />);
      }
    }

    paginationItems.push(
      <Pagination.Next
        key="next"
        onClick={() =>
          activePage < totalPages && handlePageChange(activePage + 1)
        }
        disabled={activePage === totalPages}
      />
    );

    paginationItems.push(
      <Pagination.Last
        key="last"
        onClick={() => handlePageChange(totalPages)}
        disabled={activePage === totalPages}
      />
    );

    return paginationItems;
  };

  const handleItemsPerPageChange = (event) => {
    const newItemsPerPage = parseInt(event.target.value);
    setItemsPerPage(newItemsPerPage);
    setActivePage(1); // Reset halaman aktif ke halaman pertama
  };

  const fetchCustomers = async (page, limit) => {
    setIsLoading(true);
    const resp = await customerSvc.getCustomer({
      params: {
        query: searchTerm,
        active:
          selectedStatus === "active"
            ? true
            : selectedStatus === "inactive"
            ? false
            : "",
        page: page - 1,
        limit: itemsPerPage,
      },
    });
    if (resp.status === 200) {
      setCustomers(resp.data.data);
      setStartIndex((activePage - 1) * itemsPerPage);
      setEndIndex(startIndex + itemsPerPage);
      setTotalPages(resp.data.totalPage);
      setTotalRows(resp.data.totalRows);
    }
    setIsLoading(false);
  };

  const isFirstRenderCustomers = useRef(true);
  useEffect(() => {
    if (!isFirstRenderCustomers.current) {
      fetchCustomers(activePage, itemsPerPage);
    } else {
      isFirstRenderCustomers.current = false;
    }
  }, [activePage, itemsPerPage]);

  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
  };

  useEffect(() => {
    fetchCustomers();
  }, [selectedStatus]);

  const isFirstRender = useRef(true);
  useEffect(() => {
    if (!isFirstRender.current) {
      let timerId;
      const fetchData = () => {
        fetchCustomers();
      };
      const delayedFetch = () => {
        clearTimeout(timerId);
        timerId = setTimeout(fetchData, 500); // Adjust the delay time as per your needs
      };
      delayedFetch(); // Call the delayedFetch initially to trigger the initial API call
      return () => {
        // Clean up by clearing the timer when the component unmounts
        clearTimeout(timerId);
      };
    } else {
      isFirstRender.current = false;
    }
  }, [searchTerm]);

  const handleActive = async (id) => {
    const willUpdate = await Swal.fire({
      title: "Apakah anda yakin?",
      text: "Anda akan mengubah status kantor cabang ini!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes!",
      cancelButtonText: "Cancel",
    });

    if (willUpdate.isConfirmed) {
      try {
        showLoadingAlert("Tunggu", "Mengubah status kantor cabang...");
        const resp = await customerSvc.updateActivateCustomer(id);
        if (resp.status === 200) {
          fetchCustomers(activePage, itemsPerPage);
          showSuccessAlert("Behasil", "Berhasil merubah status");
        }
      } catch (error) {
        let message =
          error.response?.data?.message ||
          "Gagal mengubah status kantor cabang";
        showErrorAlert("Gagal", message);
      }
    }
  };

  const handleAddCustomer = () => {
    history.push("/customer/add");
  };

  const handleClearFilters = () => {
    setSearchTerm("");
    setSelectedStatus("");
    setActivePage(1);
    if (searchInputRef.current) {
      searchInputRef.current.value = "";
    }
    fetchCustomers(1, itemsPerPage);
  };

  const handleEditCustomer = async (customerId) => {
    history.push(`/customer/update/${customerId}`);
  };

  const truncateAddress = (address) => {
    const maxLength = 40; // Set the maximum length for the address
    if (address.length <= maxLength) {
      return address;
    }
    return address.substring(0, maxLength) + "...";
  };

  const customPaddingStyle = {
    padding: "0.75rem 0.5rem",
  };

  return (
    <>
      <div className="align-items-center py-4 w-100">
        <Card border="light" className="shadow-sm">
          <Card.Body className="pb-0">
            <Card.Title className="">Pelanggan</Card.Title>
            <hr />
            {role === "admin" && dev === "developer" && (
              <div className="d-flex mb-3">
                <Button
                  variant="success"
                  size="sm"
                  className=""
                  style={{ width: "100px" }}
                  onClick={handleAddCustomer}
                >
                  <FontAwesomeIcon
                    icon={faPlus}
                    className="me-2"
                    style={{ fontSize: "14px" }}
                  />
                  Tambah
                </Button>
              </div>
            )}

            <div className="d-flex justify-content-between mb-3">
              <FormControl
                type="text"
                placeholder="Cari nama, no telp, alamat"
                className="me-2"
                // value={searchTerm}
                onChange={(e) => {
                  setSearchTerm(e.target.value);
                }}
              />
              <InputGroup>
                <Form.Select
                  id="state"
                  className="me-1"
                  defaultValue=""
                  // value={role}
                  style={{ width: "30px" }}
                  onChange={(e) => {
                    setSelectedStatus(e.target.value);
                  }}
                >
                  <option value="">Semua Status</option>
                  <option value="active">Status Aktif</option>
                  <option value="inactive">Status Nonaktif</option>
                </Form.Select>
              </InputGroup>
              <Button
                variant="secondary"
                size="sm"
                className="ms-2"
                onClick={handleClearFilters}
                style={{ marginRight: "10px" }}
              >
                Reset Filter
              </Button>
            </div>
            <Table
              responsive
              className="table-centered table-nowrap rounded mb-0"
            >
              <thead className="thead-light">
                <tr>
                  <th className="border-0" style={customPaddingStyle}>
                    Nama Pelanggan
                  </th>
                  <th className="border-0" style={customPaddingStyle}>
                    Lokasi
                  </th>
                  <th className="border-0" style={customPaddingStyle}>
                    Status
                  </th>
                  <th className="border-0" style={customPaddingStyle}>
                    Pengguna
                  </th>
                  <th className="border-0" style={customPaddingStyle}>
                    ATM
                  </th>
                  {role === "admin" && dev === "developer" && (
                    <th className="border-0">Aksi</th>
                  )}
                  <th className="border-0" style={customPaddingStyle}>
                    Detail
                  </th>{" "}
                </tr>
              </thead>
              {isLoading ? (
                <tr>
                  <td
                    colSpan={6}
                    className="text-center"
                    style={{ height: "300px" }}
                  >
                    <Spinner animation="border" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </Spinner>
                  </td>
                </tr>
              ) : totalRows > 0 ? (
                <tbody>
                  {customers.map((customer) => (
                    <tr key={customer.id_pelanggan}>
                      <td className="border-0" style={customPaddingStyle}>
                        <div
                          style={{ display: "flex", alignItems: "center" }}
                          className="mb-2"
                        >
                          {customer.photo_url ? (
                            <Image
                              // src={customer.photo_url}

                              src={customer.photo_url}
                              alt="profile-pict"
                              className="user-avatar md-avatar rounded-circle me-2"
                            />
                          ) : (
                            <Image
                              src={"/bni.webp"}
                              // className="me-2 image image-small"

                              alt="profile-pict"
                              className="user-avatar md-avatar rounded-circle me-2"
                            />
                          )}
                          <div>
                            <span className="fw-bold">{customer.name}</span>
                            <br />
                            {customer.email}
                          </div>
                        </div>
                      </td>
                      <td className="border-0" style={customPaddingStyle}>
                        <span className="fw-bolder" style={customPaddingStyle}>
                          {truncateAddress(customer.alamat)}
                        </span>
                        <br />
                        {customer.phone}
                      </td>
                      <td
                        className="fw-bold border-0"
                        style={customPaddingStyle}
                      >
                        {customer.active ? (
                          <div className="bg-success text-white rounded-pill p-2 text-center">
                            Aktif
                          </div>
                        ) : (
                          <div className="bg-danger text-white rounded-pill p-2 text-center">
                            Nonaktif
                          </div>
                        )}
                      </td>

                      <td className="border-0" style={customPaddingStyle}>
                        <div>
                          <span className="fw-bolder">Total Pengguna:</span>{" "}
                          {customer.totalUser}
                        </div>
                        <div>
                          <span className="fw-bolder">Aktif:</span>{" "}
                          {customer.totalUserActive} |
                          <span className="fw-bolder"> Nonaktif:</span>{" "}
                          {customer.totalUserInactive}
                        </div>
                      </td>

                      <td className="border-0" style={customPaddingStyle}>
                        <div>
                          <span className="fw-bolder">Total ATM:</span>{" "}
                          {customer.totalAsset}
                        </div>
                        <div>
                          <span className="fw-bolder">Aktif:</span>{" "}
                          {customer.totalAssetActive} |
                          <span className="fw-bolder"> Nonaktif:</span>{" "}
                          {customer.totalAssetInactive}
                        </div>
                      </td>
                      {role === "admin" && dev === "developer" && (
                        <td className="border-0" style={customPaddingStyle}>
                          <div className="d-flex align-items-center">
                            <div
                              className="inline-block"
                              style={{
                                border: "12px solid transparent",
                                margin: "-12px",
                                "--c81fc0a4": "9999",
                                marginRight: "3px",
                              }}
                            >
                              <button className="bg-white hover:bg-bs-bg block border border-bs-line rounded p-1.5">
                                <div
                                  className="logo-button"
                                  onClick={(e) => {
                                    handleEditCustomer(customer.id_pelanggan);
                                  }}
                                  title="Ubah Data Pelanggan"
                                  style={{ cursor: "pointer" }}
                                >
                                  <FontAwesomeIcon icon={faPenToSquare} />
                                </div>
                              </button>
                            </div>

                            <div
                              className="inline-block"
                              style={{
                                border: "12px solid transparent",
                                margin: "-12px",
                                "--c81fc0a4": "9999",
                              }}
                            >
                              <button className="bg-white hover:bg-bs-bg block border border-bs-line rounded p-1.5">
                                <div
                                  className="logo-button"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    handleActive(customer.id_pelanggan);
                                  }}
                                  title={
                                    customer.active ? "Nonaktifkan" : "Aktifkan"
                                  }
                                  style={{ cursor: "pointer" }}
                                >
                                  <FontAwesomeIcon icon={faWrench} />
                                </div>
                              </button>
                            </div>
                          </div>
                        </td>
                      )}
                      <td
                        className="fw-bold border-0"
                        style={customPaddingStyle}
                      >
                        <a
                          href={`/customer/detail/${customer.id_pelanggan}`}
                          rel="noopener noreferrer"
                          className="fw-bolder"
                        >
                          Lihat
                        </a>
                      </td>
                    </tr>
                  ))}
                </tbody>
              ) : (
                <tr>
                  <td
                    colSpan={5}
                    className="text-center"
                    style={{ height: "200px" }}
                  >
                    <span className="text-muted">Tidak ada kantor cabang</span>
                  </td>
                </tr>
              )}
            </Table>
          </Card.Body>
          <Card.Footer className="px-3 border-0 d-lg-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center">
              <small className="me-2">Limit:</small>
              <Form.Select
                className="me-3"
                style={{ width: "70px" }}
                value={itemsPerPage}
                onChange={handleItemsPerPageChange}
              >
                <option value="5">5</option>
                <option value="10">10</option>
                <option value="15">15</option>
              </Form.Select>
              <small className="fw-bold">
                Menampilkan {startIndex + 1} -{" "}
                {Math.min(startIndex + itemsPerPage, totalRows)} dari{" "}
                {totalRows} Pelanggan
              </small>
            </div>

            <div className="d-flex align-items-center">
              <Nav className="mx-2">
                <Pagination className="mb-2 mb-lg-0">
                  {renderPaginationItems()}
                </Pagination>
              </Nav>
            </div>
          </Card.Footer>
        </Card>
      </div>
    </>
  );
};
