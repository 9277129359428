import React, { useEffect, useState } from "react";
import moment from "moment";
import reportSvc from "../services/reportSvc";
import { useLocation } from "react-router-dom";
import HistoryReportExportByAsset from "../components/HistoryReportExportByAsset";
import HistoryReportExportByPelanggan from "../components/HistoryReportExportByPelanggan";
import { Spinner } from "@themesberg/react-bootstrap";
import Preloader from "../components/Preloader";
import HistoryReportExportByPelangganPrint from "../components/HistoryReportExportByPelangganPrint";

export default () => {
  const [reportExports, setReportExport] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();
  const url = window.location.href;
  const typeReport = url.includes("cabang") ? "cabang" : "asset";
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get("id");
  const startdate = queryParams.get("startdate");
  const enddate = queryParams.get("enddate");

  useEffect(() => {
    const fetchReportsExport = async () => {
      try {
        setIsLoading(true);
        let respData;
        console.log({ typeReport });
        if (typeReport === "asset") {
          respData = await reportSvc.getReportExportPDFAsset(id, {
            params: {
              start_date: startdate,
              end_date: enddate,
            },
          });
        }
        if (typeReport === "cabang") {
          respData = await reportSvc.getReportExportPDFPelanggan(id, {
            params: {
              start_date: startdate,
              end_date: enddate,
            },
          });
        }

        if (respData.status === 200) {
          setReportExport(respData.data.data);
        }
        setIsLoading(false);
      } catch (err) {
        // Handle error
      }
    };

    if (startdate && id && enddate) {
      fetchReportsExport();
    }
  }, []);

  return (
    <div className="bg-white">
      {isLoading === 0 ? (
        <h1>loading...</h1>
      ) : (
        <>
          {reportExports.endDate ? (
            <div>
              <div className="container">
                {typeReport === "asset" && (
                  <HistoryReportExportByAsset reportExports={reportExports} />
                )}
              </div>
              {typeReport === "cabang" && (
                <HistoryReportExportByPelangganPrint
                  reportExports={reportExports}
                />
              )}
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "40vh", // Adjust as needed
              }}
            >
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </div>

            // <Preloader show={true}></Preloader>
          )}
        </>
      )}
    </div>
  );
};
