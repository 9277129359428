import React, { useState, useEffect } from "react";
import {
  Card,
  Button,
  Form,
  InputGroup,
  Spinner,
} from "@themesberg/react-bootstrap";
import Swal from "sweetalert2";
import docTypeSvc from "../services/docTypeSvc";
import { useHistory, useParams } from "react-router-dom";
import { showErrorAlert, showSuccessAlert } from "../utils/SwalUtil";

export default ({ docTypeEdit }) => {
  const [docTypeName, setDocTypeName] = useState("");
  const [docType, setDocType] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();

  const { id } = useParams();
  useEffect(() => {
    if (id) {
      fetchDocTypeId();
    }
  }, [docTypeEdit]);

  const fetchDocTypeId = async () => {
    setIsLoading(true);
    const resp = await docTypeSvc.getJenisDokumentasiById(id);
    if (resp.status === 200) {
      setDocType(resp.data.data);
      setDocTypeName(resp.data.data.nama_jenis_dokumentasi);
    }
    setIsLoading(false);
  };

  const handleSave = async () => {
    if (!docTypeName) {
      showErrorAlert("Gagal", "Kolom harus diisi lengkap");
      return;
    }
    if (id) {
      try {
        const data = {
          nama_jenis_dokumentasi: docTypeName,
        };

        const resp = await docTypeSvc.updateJenisDokumentasi(id, data);

        if (resp.status === 200) {
          showSuccessAlert(
            "Berhasil",
            "Data jenis dokumentasi berhasil diubah"
          );

          history.goBack();
        }else {
          let message = resp?.response?.data?.message || "Gagal mengubah data jenis dokumentasi";
          showErrorAlert("Gagal", message);
        }
      } catch (error) {
        let message = error.response.data.message ||"Gagal menambahkan data jenis dokumentasi"
        showErrorAlert("Gagal", message);
      }
    } else {
      try {
        const data = {
          nama_jenis_dokumentasi: docTypeName,
        };

        const resp = await docTypeSvc.addJenisDokumentasi(data);
        if (resp.status === 200) {
          showSuccessAlert(
            "Berhasil",
            "Data jenis dokumentasi berhasil ditambah"
          );
          history.push("/doctype");
        }else {
          let message = resp?.response?.data?.message || "Gagal menambahkan data jenis dokumentasi";
          showErrorAlert("Gagal", message);
        }
      } catch (error) {
        let message = error.response.data.message ||"Gagal menambahkan data jenis dokumentasi"
        showErrorAlert("Gagal", message);
      }
    }
  };

  return (
    <Card>
      <Card.Header>
        <Card.Title className="h6">
          {id ? "Ubah Jenis Dokumentasi" : "Tambah Jenis Dokumentasi"}
        </Card.Title>
      </Card.Header>
      {isLoading ? (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: "300px" }}
        >
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      ) : (
        <Card.Body>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label>Nama Dokumentasi</Form.Label>
              <InputGroup>
                <Form.Control
                  required
                  type="text"
                  placeholder="Enter documentation type name"
                  value={docTypeName}
                  onChange={(e) => setDocTypeName(e.target.value)}
                />
              </InputGroup>
            </Form.Group>
          </Form>
        </Card.Body>
      )}

      <Card.Footer>
        <Button variant="primary" onClick={handleSave}>
          Simpan Perubahan
        </Button>
      </Card.Footer>
    </Card>
  );
};
