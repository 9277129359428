import React, { useState, useEffect } from "react";
import { Modal, Button, Form } from "@themesberg/react-bootstrap";
import { getLoginData } from "../services/authSvc";
import userSvc from "../services/userSvc";
import { showErrorAlert, showSuccessAlert } from "../utils/SwalUtil";

export default ({ show, handleClose, setIdUserAsset }) => {
  const [id_user, setIdUser] = useState(null);
  const [operators, setOperators] = useState([]);
  const { user } = getLoginData();

  const fetchOperator = async () => {
    const resp = await userSvc.getUser({
      params: {
        page: 0,
        limit: 10000,
        role: "operator",
      },
    });
    if (resp.status === 200) {
      const filteredOperators = resp.data.data.filter(
        (operator) => operator.id_user_asset === null
      );
      setOperators(filteredOperators);
    }
  };

  useEffect(() => {
    fetchOperator();
  }, []);

  const handleSave = async () => {
    if (!setIdUserAsset) {
      showErrorAlert("Gagal", "Operator harus dipilih");
      return;
    }
    try {
      const data = {
        id_user_asset: setIdUserAsset,
      };
      const resp = await userSvc.updateUserAll(id_user, data);
      if (resp.status === 200) {
        showSuccessAlert("Berhasil", "Data user aset berhasil diubah");
        fetchOperator();
        handleClose();
      }else {
        let message = resp?.response?.data?.message || "Gagal mengubah user asset";
        showErrorAlert("Gagal", message);
      }
      
    } catch (error) {
      let message =error.response?.data?.message || "Gagal mengubah data user aset";
      showErrorAlert("Gagal", message);
    }
  };

  return (
    <Modal as={Modal.Dialog} centered show={show} onHide={handleClose}>
      <Modal.Header>
        <Modal.Title className="h6">Tambah Pengguna</Modal.Title>
        <Button variant="close" aria-label="Close" onClick={handleClose} />
      </Modal.Header>
      <Modal.Body>
        <Form>
          {user && user.role === "admin" && (
            <Form.Group className="mb-3" controlId="customer">
              <Form.Label>Pilih Pelanggan</Form.Label>
              <Form.Select
                value={id_user}
                onChange={(e) => setIdUser(e.target.value)}
              >
                {id_user === null && (
                  <option value={null}>Pilih Operator</option>
                )}
                {operators &&
                  operators.map((operator) => (
                    <option key={operator.id_user} value={operator.id_user}>
                      {operator.name}
                    </option>
                  ))}
              </Form.Select>
            </Form.Group>
          )}
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Tutup
        </Button>
        <Button variant="primary" onClick={handleSave}>
          Tambah
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
